import React from 'react';
import {
  FinalChartDiv,
  FinalInsightDiv,
} from 'lib/reports-analytics/components/reportArea/reportChartItem/ReportChartItem';
import {
  FinalLineDiv,
  FinalPageBreakTools,
  FinalReportText,
} from 'lib/reports-analytics/components/reportArea/ReportTools/ReportTools';
import { defaultReportTools, defaultReportType } from 'lib/reports-analytics/data/defaultSchema';

const ReportElems = ({ reportElem }: { reportElem: any }) => {
  if (reportElem.reportSchema.type === defaultReportType.tools) {
    return <ReportTools reportElem={reportElem} />;
  }
  if (
    reportElem.reportSchema.type === defaultReportType.charts ||
    reportElem.reportSchema.type === defaultReportType.insights
  ) {
    return <ReportCharts reportElem={reportElem} />;
  }
  return <></>;
};

export default ReportElems;

const ReportTools = ({ reportElem }: { reportElem: any }) => {
  if (reportElem?.reportSchema?.reportToolType === defaultReportTools.inputText.reportToolType) {
    return <FinalReportText targetElem={reportElem?.reportSchema} />;
  }
  if (reportElem?.reportSchema?.reportToolType === defaultReportTools.line.reportToolType) {
    return <FinalLineDiv targetElem={reportElem?.reportSchema} />;
  }
  if (reportElem?.reportSchema?.reportToolType === defaultReportTools.pageBreak.reportToolType) {
    return <FinalPageBreakTools targetElem={reportElem?.reportSchema} />;
  }
  return <></>;
};
const ReportCharts = ({ reportElem }: { reportElem: any }) => {
  if (reportElem.reportSchema.type === defaultReportType.charts) {
    return (
      <FinalChartDiv
        targetElem={reportElem?.reportSchema}
        chartWidth={reportElem?.reportSchema?.style?.chartWidth}
        chartSchema={reportElem.chartSchema}
        plotData={reportElem?.plotData}
      />
    );
  }
  if (reportElem.reportSchema.type === defaultReportType.insights) {
    return (
      <FinalInsightDiv targetElem={reportElem?.reportSchema} plotData={reportElem?.plotData} />
    );
  }
  return <></>;
};
