import useDivDimensions from '../../helpers/divDimensionHelper';
import React, { useCallback, useMemo, useRef } from 'react';
import { Group } from '@visx/group';
import { LineRadial } from '@visx/shape';
import { scaleTime, scaleLog, scaleLinear } from '@visx/scale';
import { curveBasisOpen, curveBasis, curveBasisClosed } from '@visx/curve';
// import { LinearGradient } from '@visx/gradient';
import { AxisLeft } from '@visx/axis';
import { GridRadial, GridAngle } from '@visx/grid';
import { getStyling, hexWithOpacity } from 'lib/visx-lib/helpers/colorHelper';
import { scaleFunction } from './scaleFunction';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { RadialLineTypeToolTip } from '../ChartToolTip/ChartToolTip';
import { defaultToolTipStyling } from 'lib/visx-lib/constants/constants';
import {
  allDropableID,
  defaultDataType,
  defaultOpacity,
} from 'lib/reusable-components/reusableData/defaultChartSchema';
import { mls } from 'lib/multilanguagesupport';
import useOrdinalLegend from 'lib/visx-lib/helpers/hooks/userOrdinalLegend';

const green = '#e5fd3d';
export const blue = '#aeeef8';
// export const background = '#744cca';
const darkgreen = '#dff84d';

// export const background = '#878787';
export const background = 'transparent';
export const transparent = 'transparent';
const strokeColor = '#000';

// utils
function extent(data, value) {
  const values = data?.map(value);
  return [Math.min(...values), Math.max(...values)];
}

// accessors
const date = (d) => new Date(d.x).valueOf();
const close = (d, i) => {
  // return Math.round(Math.random() * 100, 2)
  return Object.values(d.y)[i ?? 0] ?? 0;
};
const formatTicks = (val) => String(val);

// scales

const RadialLineType = ({
  data,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  levels = 5,
  theme,
  labels,
  // chartHeight: height,
  chartHeight,
  chartData,
  toggle,
  desc,
  isReport,
}) => {
  const { xAxisId } = allDropableID;
  const { width } = useDivDimensions(cardRef);

  const height = width >= chartHeight ? chartHeight : width;
  const xScale = scaleTime({
    range: [0, Math.PI * 2],
    domain: extent(data, date),
  });

  const getLegendObject = () => {
    return {
      legendGlyphSize: 20,
      colorScale: theme.colors,
      labels: labels,
      isReport,
    };
  };
  const { Legend, colorScale } = useOrdinalLegend(getLegendObject());
  // Creating a tooltip for the chart
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();
  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  let interval;

  const circularScale = useMemo(
    () =>
      scaleFunction({
        range: [0, 360],
        domain: data,
      }),
    [data]
  );

  const linePosition = ({ event }) => {
    if (groupRef || event) {
      let boxBoundingRect = groupRef.current.getBoundingClientRect();
      let boxCenter = {
        x: boxBoundingRect.left + boxBoundingRect.width / 2,
        y: boxBoundingRect.top + boxBoundingRect.height / 2,
      };
      let angle =
        (Math.atan2(event.pageY - boxCenter.y, event.pageX - boxCenter.x) * (180 / Math.PI) + 90) %
        360;
      if (angle < 0) {
        angle += 360;
      }

      return { angle, x: event.pageX, y: event.pageY, boxCenter };
    }
    return { skip: true };
  };
  // Function to handle mouse over change
  const handleMouse = useCallback(
    ({ event, key }) => {
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;
      const positionData = linePosition({ event });
      if (positionData.skip) {
        return;
      }
      const nearestData = circularScale(positionData?.angle);
      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: {
          x: nearestData.x,
          y: nearestData.y,
          nearestDatum: key,
          positionData: positionData,
        },
      });
    },
    [containerBounds, showTooltip]
  );

  const padding = 20;
  const yScale = scaleLog({
    domain: extent(data, (d) => {
      return Math.max(...Object.keys(d?.y ?? {}).map((elem, i) => close(d, i)));
    }),
    range: [0, height / 2 - padding],
  });
  const yScale2 = scaleLinear({
    domain: extent(data, (d) => Math.max(close(d, 0), close(d, 1))),
    range: [0, height / 2 - padding],
  });

  const angle = (d) => {
    return xScale(date(d)) ?? 0;
  };

  const firstPoint = data[0];
  const lastPoint = data[data.length - 1];

  // Update scale output to match component dimensions
  const reverseYScale = yScale.copy().range(yScale.range().reverse());
  const reverseYScale2 = yScale2.copy().range(yScale.range().reverse());
  // const background = theme.tooltip.style[1].color;
  const yStyling = theme.yAxisStyle;
  const groupRef = useRef(null);

  const xAxisValue = theme?.tooltip?.style.find((styleElem) => {
    if (styleElem?.axis === xAxisId) {
      return true;
    }
  });
  const circleColor = hexWithOpacity(xAxisValue?.color, xAxisValue?.opacity);
  if (chartData?.xAxis[0]?.dataType !== defaultDataType.DATETIME) {
    return (
      <div
        className='fw-bolder my-1 fs-4 d-flex justify-content-center'
        style={{ color: '#7e8299' }}
      >
        {mls('Please use columns of Datetime datatype in X-Axis')}
      </div>
    );
  }

  return (
    <>
      {data.length !== 0 ? (
        <>
          {tooltipOpen && (
            <RadialLineTypeToolTip
              defaultToolTipStyling={defaultToolTipStyling}
              tooltipLeft={tooltipLeft}
              tooltipTop={tooltipTop}
              TooltipInPortal={TooltipInPortal}
              tooltipData={tooltipData}
              theme={theme}
              labels={labels}
              backgroundColor={defaultToolTipStyling.backgroundColor}
              toggle={toggle}
            />
          )}
          <svg width={width} height={chartHeight} ref={containerRef}>
            {/* <LinearGradient from={green} to={blue} id='line-gradient' /> */}
            <rect
              width={height}
              x={width / 2 - height / 2}
              height={height}
              fill={transparent}
              rx={600000000}
              opacity={0.5}
              ref={groupRef}
            />
            <Group top={height / 2} left={width / 2}>
              <GridAngle
                scale={xScale}
                outerRadius={height / 2 - padding}
                stroke={circleColor}
                strokeWidth={1}
                strokeOpacity={0.5}
                strokeDasharray='5,2'
                numTicks={20}
              />
              <GridRadial
                scale={yScale2}
                numTicks={5}
                // arcThickness={2}
                data={7}
                cornerRadius={5}
                stroke={circleColor}
                strokeWidth={1}
                fill={circleColor}
                fillOpacity={(xAxisValue?.opacity ?? defaultOpacity) / 1000}
                strokeOpacity={0.1}
              />
              {yStyling?.show ? (
                <AxisLeft
                  top={-height / 2 + padding}
                  scale={reverseYScale2}
                  // numTicks={2}
                  numTicks={5}
                  tickStroke='none'
                  tickLabelProps={(val) => ({
                    dx: '0em',
                    dy: '-0.5em',
                    textAnchor: 'middle',
                    // textAnchor: 'right',
                    ...getStyling(yStyling?.valueStyle),

                    // fontSize: 8,
                    // // fill: 'black',
                    // fillOpacity: 1,

                    // stroke: strokeColor,
                    // strokeWidth: 0.5,
                    // paintOrder: 'stroke',
                  })}
                  tickFormat={formatTicks}
                  hideAxisLine
                />
              ) : null}

              {Object.keys(labels).map((key, index) => {
                const radius = (d) => {
                  return yScale(Object.values(d.y)[index]) ?? 0;
                };
                return (
                  <LineRadial key={key} angle={angle} radius={radius} curve={curveBasis}>
                    {({ path }) => {
                      const d = path(data) || '';
                      return (
                        <>
                          <path
                            d={d}
                            strokeWidth={2}
                            strokeOpacity={0.8}
                            strokeLinecap='round'
                            fill='none'
                            stroke={theme.colors[key]}
                          />
                          {[firstPoint, lastPoint].map((d, i) => {
                            const cx = ((xScale(date(d)) ?? 0) * Math.PI) / 180;
                            const cy = -(yScale(close(d)) ?? 0);
                            return (
                              <circle
                                key={`line-cap-${i}`}
                                cx={cx}
                                cy={cy}
                                fill={theme.colors[key]}
                                r={3}
                                onMouseEnter={(event) => {
                                  if (interval) {
                                    clearInterval(interval);
                                  }
                                  handleMouse({ event, key: {} });
                                }}
                                onMouseMove={(event) => {
                                  if (interval) {
                                    clearInterval(interval);
                                  }
                                  handleMouse({ event, key: {} });
                                }}
                                onMouseLeave={() => {
                                  interval = setTimeout(() => {
                                    hideTooltip();
                                  }, 1000);
                                }}
                                onTouchStart={(event) => {
                                  if (interval) {
                                    clearInterval(interval);
                                  }
                                  handleMouse({ event, key: {} });
                                }}
                                onTouchEnd={() => {
                                  interval = setTimeout(() => {
                                    hideTooltip();
                                  }, 1000);
                                }}
                              />
                            );
                          })}
                        </>
                      );
                    }}
                  </LineRadial>
                );
              })}
              {Object.keys(labels).map((key, index) => {
                const radius = (d) => {
                  return yScale(Object.values(d.y)[index]) ?? 0;
                };
                return (
                  <LineRadial key={key} angle={angle} radius={radius} curve={curveBasis}>
                    {({ path }) => {
                      const d = path(data) || '';
                      return (
                        <>
                          <path
                            d={d}
                            strokeWidth={6}
                            strokeOpacity={0}
                            // strokeOpacity={0.3}
                            strokeLinecap='round'
                            fill='none'
                            stroke={theme.colors[key]}
                            onMouseEnter={(event) => {
                              if (interval) {
                                clearInterval(interval);
                              }
                              handleMouse({ event, key });
                            }}
                            onMouseMove={(event) => {
                              if (interval) {
                                clearInterval(interval);
                              }
                              handleMouse({ event, key });
                            }}
                            onMouseLeave={() => {
                              interval = setTimeout(() => {
                                hideTooltip();
                              }, 1000);
                            }}
                            onTouchStart={(event) => {
                              if (interval) {
                                clearInterval(interval);
                              }
                              handleMouse({ event, key });
                            }}
                            onTouchEnd={() => {
                              interval = setTimeout(() => {
                                hideTooltip();
                              }, 1000);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </>
                      );
                    }}
                  </LineRadial>
                );
              })}

              {tooltipOpen &&
                [tooltipData].map((d, i) => {
                  const { x, y, boxCenter } = tooltipData?.positionData;
                  const cy = y - boxCenter.y;
                  const cx = x - boxCenter.x;
                  return (
                    <circle
                      key={`line-cap-${i}`}
                      cx={cx}
                      cy={cy}
                      fill={transparent}
                      stroke={circleColor}
                      r={5}
                      strokeWidth={3}
                    />
                  );
                })}
            </Group>
          </svg>
          <Legend />
        </>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

export default RadialLineType;
