import sendToSlack from 'lib/reports-analytics/utils/sendToSlack';

export const fetchDataFromAnalytics = async ({
  appID,
  reportID,
  setReportData,
  setisReportDataFetched,
}: any) => {
  try {
    let baseUrl = `https://analytics-api.supista.com`;
    const newData = {
      appID: appID,
      reportID: reportID,
    };
    const res = await fetch(`${baseUrl}/${appID}/reports`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(newData),
    });
    // waits until the request completes...
    const data = await res.json();
    if (data.status === 200) {
      setReportData(data.response);
      setisReportDataFetched(true);
    }
  } catch (error) {
    console.log(error);
  }
};
export const fetchDataFromNodeServer = async ({
  appID,
  reportID,
  setReportData,
  setIsTempMail,
  setDateData,
  setisReportDataFetched,
}: any) => {
  try {
    let baseUrl = ``;
    const newData = {
      appID: appID,
      reportID: reportID,
    };
    const res = await fetch(`${baseUrl}/api/getreports`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(newData),
    });
    // waits until the request completes...
    const data = await res.json();
    if (data.status === 200) {
      setReportData(data.response.reportData);
      setDateData(data.response.dateData);
      setIsTempMail(data.response.isTempMail);
      setisReportDataFetched(true);
    } else {
      sendToSlack({
        text: 'An error occurred while getting reports from cache',
        name: `Reports Not Found`,
        appID,
        reportID,
      });
    }
  } catch (error: any) {
    sendToSlack({
      appID,
      name: 'An error occurred while getting reports from cache',
      text: `Reports Not Found : ${error?.toString()} `,
      reportID,
    });
    console.log(error);
  }
};
