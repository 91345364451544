import { useState, useEffect, isValidElement } from 'react';

function getDivDimensions(cardRef) {
  const currentNode = cardRef.current;
  if (currentNode == null) return {};
  const { clientWidth: width, clientHeight: height } = currentNode;
  const computedStyle = getComputedStyle(currentNode);
  const paddingX = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
  const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
  return {
    width: width - paddingX,
    height: height - paddingY,
  };
}

export default function useDivDimensions(cardRef) {
  const [divDimensions, setDivDimensions] = useState(getDivDimensions(cardRef));
  useEffect(() => {
    const { current } = cardRef;
    if (current != null) {
      const resizeObserver = new ResizeObserver((entries) => {
        setDivDimensions(getDivDimensions(cardRef));
      });
      resizeObserver.observe(current);
      return () => resizeObserver.unobserve(current);
    }
    
  }, [cardRef.current]);

  // if (windowDimensions.width > 1200) {
  //   svgShape.width = 1200;
  //   svgShape.height = 300;
  // } else if (windowDimensions.width <= 1200 && windowDimensions.width > 900) {
  //   svgShape.width = 900;
  //   svgShape.height = 300;
  // } else if (windowDimensions.width <= 900 && windowDimensions.width > 600) {
  //   svgShape.width = 600;
  //   svgShape.height = 300;
  // } else if (windowDimensions.width <= 600) {
  //   svgShape.width = windowDimensions.width;
  //   svgShape.height = 300;
  // }

  return divDimensions;
}
