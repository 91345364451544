import en from './EN';
import jp from './JP';
import reduxConstants from '../reduxConstants';
import getDeviceLanguage from '../getDeviceLanguage';

localStorage.setItem('__d3__languageUpdate', 'true');
const Japanese = { ...jp };
const English = { ...en };
const languages = {
  English,
  Japanese,
};

const defaultLang = localStorage.getItem('__d3__defaultLanguage');
const lang = localStorage.getItem('__d3__language') || getDeviceLanguage() || defaultLang;

let appMLS;
function mls(text) {
  if (lang === 'English') {
    appMLS = {};
  } else if ((lang && !appMLS) || localStorage.getItem('__d3__languageUpdate') === 'true') {
    // console.log('hiieappMLS');
    appMLS = JSON.parse(localStorage.getItem('Supista_APP:config_store'))?.[
      reduxConstants.config.APP_MLS
    ]?.dict;
    if (appMLS) {
      localStorage.setItem('__d3__languageUpdate', 'false');
    }
  }
  // console.log(appMLS, lang, 'appMLS', localStorage.getItem('__d3__languageUpdate'));
  return appMLS?.[text] || languages[lang]?.[text] || languages[defaultLang]?.[text] || English[text] || text;
}

export { mls };
