// import { mls } from '../../multilanguagesupport/analytics';
import React, { useMemo } from 'react';
// import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers';
// import styles from '../../constants/styles.module.css';
import Card2 from './Card2';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';

function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
        : Math.abs(Number(labelValue)).toFixed(2);
}

const CardChart = ({
  chartData,
  dataToPlot: plotData,
  cardWidth,
  useChartSettingsButton,
  isProd,
  updateFilter,
  cardRef,
  isReport
}) => {
  const dataToPlot = useMemo(() => plotData?.plotData || [], [plotData]);
  const dataToPlotObject = Object.values(dataToPlot[0]?.data[0].y[0] || {})[0] || {};
  const { percentage, change } = dataToPlotObject;
  const data = dataToPlotObject?.data;
  const { dataPrefix, textSuffix, dataSuffix, isCompare, formatter } = chartData.yAxis[0];
  const formattedData = numberFormatter({ type: formatter, value: data ?? "NAN" });
  const title = (dataPrefix || '') + formattedData + (dataSuffix || '');
  return (
    <>
      <Card2
        title={title}
        text={textSuffix || ''}
        percentage={percentage}
        targetPoints={chartData.targetPoints}
        data={data}
        isCompare={isCompare}
        isReport={isReport}
        headerImage={chartData?.headerImage ?? ''}
      />
    </>
  );

  // return (
  //   <>
  //     <div>
  //       <div className='card-header flex-nowrap border-0 p-0 pb-3'>
  //         <div
  //           className={`${styles.titleDiv} w-100 d-flex justify-content-between align-items-center`}
  //         >
  //           <div>
  //             {image && (
  //               <div className='symbol symbol-45px w-45px bg-light me-5'>
  //                 <img src={toAbsoluteUrl(image)} alt='Metronic' className='p-3' />
  //               </div>
  //             )}
  //             {/* Append Title of the chart */}
  //             <div className='fs-4 fw-bold text-hover-primary text-gray-600 mb-0'>
  //               {mls(chartData.name)}
  //             </div>
  //           </div>
  //           <div>
  //             {/* {xCategory !== 'DATETIME' ? (
  //               <></>
  //             ) : (
  //               <DateRangeHelper chartId={chartID} defaultValue='DAY' cardWidth={cardWidth} />
  //             )} */}
  //             {/* <ColorPicker color={color} updateColor={updateColor} /> */}
  //             {isProd && (
  //               <div className='card-toolbar'>
  //                 {/* begin::Menu */}
  //                 <button
  //                   type='button'
  //                   className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
  //                   onClick={() => chartSettingsButton(chartData.chartID)}
  //                 >
  //                   <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
  //                 </button>
  //                 {/* end::Menu */}
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>

  //       <div className='d-flex flex-column'>
  //         {(chartData.yAxis || []).map((columnSchema, index) => {
  //           const columnName = columnSchema.uniqueColumnName || columnSchema.column;
  //           const value = dataToPlot[0]?.data[0]?.y?.[index]?.[columnName];
  //           const displayValue = `${columnSchema.dataPrefix || ''}${value}${
  //             columnSchema.dataSuffix || ''
  //           }`;
  //           let status;
  //           switch (columnSchema.displayType) {
  //             case 'data':
  //               return (
  //                 <div key={index} className='d-flex align-items-center flex-wrap mt-auto fs-6'>
  //                   <div className='fs-2tx fw-bolder mb-3 me-2'>{displayValue}</div>
  //                   <div className='fw-bold text-gray-400 pl-5'>
  //                     {mls(columnSchema.textSuffix || '')}
  //                   </div>
  //                 </div>
  //               );
  //             case 'change':
  //               status = typeof value === 'number' && value < 0 ? 'down' : 'up';
  //               return (
  //                 <div
  //                   key={index}
  //                   className='d-flex align-items-center flex-wrap mb-5 mt-auto fs-6'
  //                 >
  //                   {typeof value === 'number' && status === 'up' && (
  //                     <KTSVG
  //                       path='/media/icons/duotune/arrows/arr007.svg'
  //                       className='svg-icon-3 me-1 svg-icon-success'
  //                     />
  //                   )}

  //                   {status === 'down' && (
  //                     <KTSVG
  //                       path='/media/icons/duotune/arrows/arr006.svg'
  //                       className='svg-icon-3 me-1 svg-icon-danger'
  //                     />
  //                   )}

  //                   <div
  //                     className={
  //                       `fw-bolder me-2 ` + (status !== 'down' ? 'text-success' : 'text-danger')
  //                     }
  //                   >
  //                     {status !== 'down' ? '+' : ''}
  //                     {displayValue}
  //                   </div>

  //                   <div className='fw-bold text-gray-400'>{mls(columnSchema.textSuffix)}</div>
  //                 </div>
  //               );
  //             case 'percentage':
  //               return (
  //                 <div key={index} className='d-flex align-items-center fw-bold'>
  //                   <span className='badge bg-light text-gray-700 px-3 py-2 me-2'>
  //                     {displayValue}
  //                   </span>
  //                   <span className='text-gray-400 fs-7'>{mls(columnSchema.textSuffix)}</span>
  //                 </div>
  //               );

  //             default:
  //               break;
  //           }
  //           return '';
  //         })}
  //       </div>
  //     </div>
  //   </>
  // );
};

export default CardChart;
