import React, { useState, useMemo, useEffect, useCallback } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import { defaultToolTipStyling, toggleObj } from '../../constants/constants';
import styles from '../../constants/styles.module.css';
import { mls } from '../../multilanguagesupport/analytics';
import { XYChart, Tooltip, Grid, lightTheme } from '@visx/xychart';
import { scaleBand } from '@visx/scale';
import GetXYChart from './GetXYChart';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import { Box, CircularProgress } from '@mui/material';
import { getStyling } from '../../helpers/colorHelper';
import XYChartAxis from '../common/XYChartAxis';
import useD3Brush from '../../helpers/hooks/useD3Brush';

export const widthHelper = (x, type, chartWidth, margin) => {
  // Effective screen widthXYChartAxis
  const lineAreaWidth = 40;
  const effectiveWidth = chartWidth;

  // In case of a horizontal bar chart, width is always fine

  // Number of items to plot
  const plotNum = x.length;
  // Padding between each plot
  const padding = 0.1;

  // Find the width of each node based on chart type
  const nodeWidth = lineAreaWidth;

  // Calculate value of padding
  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * plotNum + margin.left + margin.right;

  // Return maximum of screenWidth or required Width
  return Math.max(reqWidth, effectiveWidth);
};

const accessors = {
  xAccessor: (d) => d?.x,
  yAccessor: (d) => d?.y,
};

const brushMargin = {
  top: 5,
  bottom: 0,
  left: 70,
  right: 20,
};
const chartSeparation = 40;

const margin = { left: 60, top: 15, bottom: 60, right: 20 };
function getRequiredFormat(xValues, yValues) {
  const reqData = [];

  for (let i = 0; i < xValues.length; i++) {
    reqData.push({ x: xValues[i], y: yValues[i] });
  }
  return reqData;
}

const XYType = ({
  data,
  desc,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin: marginProp,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  labels,
  toggle,
  handleToggle,
  isToggleClicked,
  chartHeight,
  theme,
  isInsight,
  chartData,
  isReport,
}) => {
  const x = useMemo(() => data.x, [data]);
  const y = useMemo(() => data.y, [data]);
  const { width } = useDivDimensions(cardRef);
  const [filteredXData, setFilteredXData] = useState(x);
  const [filteredYData, setFilteredYData] = useState(y);
  const height = chartHeight + margin.top + margin.bottom;

  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = chartSeparation + 10;
  const topChartHeight = 0.92 * innerHeight - topChartBottomMargin;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;
  const xBrushMax = Math.max(width - margin.left - margin.right, 0);
  const brushXScale = useMemo(() => {
    return scaleBand({
      domain: x,
      range: [0, xBrushMax],
      padding: 0,
    });
  }, [x, xBrushMax]);
  const actualWidth = widthHelper(x, type, width, margin);
  const dataNum = Math.floor((width / actualWidth) * x.length);

  useEffect(() => {
    setFilteredXData(x);
    setFilteredYData(y);
  }, [x, y]);

  const { Legend, colorScale } = useOrdinalLegend({
    legendGlyphSize: 20,
    colorScale: theme.colors,
    labels: labels,
    isReport,
  });

  const brushed = useCallback(
    function ({ selection }) {
      if (selection) {
        const [x0, x1] = selection;
        let start = -1,
          end = y.length - 1;
        setFilteredXData(
          x.filter((d, i) => {
            if (x0 <= brushXScale(d) && brushXScale(d) <= x1) {
              if (start === -1) start = i;
              end = i;
              return true;
            }
            return false;
          })
        );
        const filteredY = {};
        Object.keys(y).forEach((key) => {
          filteredY[key] = {
            data: y[key].data.slice(start, end + 1),
          };
        });
        setFilteredYData(filteredY);
      }
    },
    [x, y, brushXScale]
  );

  const { ref: miniRef } = useD3Brush({
    brushed,
    height: bottomChartHeight,
    data: x,
    brushScale: brushXScale,
    dataNum,
    width: xBrushMax,
  });

  return (
    <>
      <>
        {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
          <>
            {xCategory === 'DATETIME' && (
              <div
                style={{
                  marginLeft: width < 500 ? 0 : margin.left,
                }}
                className='d-flex mb-4 ml-sm-0'
              >
                <div
                  style={{ zIndex: 0 }}
                  className={styles.toogleBtnContianer}
                  data-toggle='buttons'
                >
                  {Object.keys(toggleObj).map((item, index) => {
                    return (
                      <label
                        key={index}
                        className={` ${styles.toogleBtn} ${toggle === item ? styles.active : ''} `}
                      >
                        <input
                          type='radio'
                          name='options'
                          id={item}
                          autoComplete='off'
                          defaultChecked={index === 0}
                          hidden
                          onClick={() => handleToggle(item)}
                        />{' '}
                        {toggleObj[item]}
                      </label>
                    );
                  })}
                </div>
                {isToggleClicked && (
                  <Box
                    sx={{
                      display: 'flex',
                      marginLeft: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
              </div>
            )}

            <XYChart
              theme={{
                ...lightTheme,
                colors: Object.keys(y).map((k) => colorScale(k)),
              }}
              height={topChartHeight}
              width={width}
              xScale={{
                type: 'band',
                paddingInner:
                  type === 'BARSTACK' || type === 'BAR' || type === 'BARGROUP' ? 0.2 : 1,
                paddingOuter: 0.1,
              }}
              yScale={{ type: 'linear' }}
              margin={margin}
            >
              <XYChartAxis
                xStyling={theme.xAxisStyle}
                yStyling={theme.yAxisStyle}
                dataNum={dataNum}
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
              />
              {xGrid && <Grid columns={false} numTicks={5} />}
              {yGrid && <Grid rows={false} numTicks={5} />}

              <GetXYChart
                yData={filteredYData}
                xData={filteredXData}
                getRequiredFormat={getRequiredFormat}
                accessors={accessors}
                type={type}
                handleClick={handleClick}
                colorScale={colorScale}
              />

              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                showSeriesGlyphs
                style={defaultToolTipStyling}
                renderTooltip={({ tooltipData }) => {
                  if (tooltipData.formattedValue) {
                    return (
                      <div>
                        <p>{tooltipData.formattedValue}</p>
                      </div>
                    );
                  }

                  return (
                    <div
                      style={{
                        fontWeight: 'normal',
                      }}
                    >
                      <div style={{ fontSize: '14px', color: theme.colors[xAxisLabel] }}>
                        {/* {mls(xAxisLabel)}:{'\t'} */}
                        <strong>{` ${accessors.xAccessor(tooltipData.nearestDatum.datum)}`}</strong>
                      </div>
                      <br />

                      {Object.keys(tooltipData?.datumByKey ?? {}).map((key, i) => {
                        const tStyling = theme.tooltip.style.find(
                          (item) => (item.uniqueColumnName || item.column) === key
                        );
                        if (tStyling !== undefined && !tStyling.show) return null;
                        return (
                          <div
                            key={i}
                            style={{
                              paddingBottom: '3px',
                              color: colorScale(key),
                              ...getStyling({
                                color: theme.colors[key],
                                textStyle: theme.tooltip.textStyle,
                                fontHeight: theme.tooltip.fontHeight,
                                opacity: tStyling?.opacity,
                                fontFamily: theme.tooltip.fontFamily,
                              }),
                            }}
                          >
                            {mls(labels[key])}:{' '}
                            <strong>
                              {accessors.yAccessor(tooltipData.datumByKey[key].datum)}
                            </strong>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              />
            </XYChart>
            <svg
              height={bottomChartHeight}
              width={width - margin.left - margin.right}
              style={{
                marginLeft: margin.left,
                marginRight: margin.right,
                // marginTop: topChartHeight + topChartBottomMargin,
              }}
              ref={miniRef}
            >
              <MiniXYChart
                bottomChartHeight={bottomChartHeight}
                width={width}
                type={type}
                x={data.x}
                y={data.y}
                colorScale={colorScale}
              />
            </svg>

            <div style={{ marginTop: 20 }}>
              <Legend />
            </div>
          </>
        ) : (
          <div
            className='fw-bolder my-1 fs-4 d-flex justify-content-center'
            style={{ color: '#7e8299' }}
          >
            {mls('No data to show with the current settings. Please use another settings!')}
          </div>
        )}
      </>
    </>
  );
};

const MiniXYChart = React.memo(({ bottomChartHeight, width, type, x, y, colorScale }) => {
  return (
    <XYChart
      theme={{ ...lightTheme }}
      margin={{ ...brushMargin, left: 0, right: 0 }}
      width={width - margin.left - margin.right}
      height={bottomChartHeight}
      xScale={{
        type: 'band',
        paddingInner: type === 'BARSTACK' || type === 'BAR' || type === 'BARGROUP' ? 0.1 : 1,
      }}
      yScale={{ type: 'linear' }}
    >
      <GetXYChart
        getRequiredFormat={getRequiredFormat}
        xData={x}
        yData={y}
        accessors={accessors}
        type={type}
        colorScale={colorScale}
      />
    </XYChart>
  );
});

export default XYType;
