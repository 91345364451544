const jp = {
  // Header
  Projects: 'プロジェクト',
  'Add New Project': '新しいプロジェクトを追加',
  'Add New Sheet': '新しいシートを追加',
  'Add New Chart': '新しいグラフを追加',
  'Please add sheet to go to charts!': 'グラフに移動するにはシートを追加してください!',
  // Aside nav
  Charts: 'グラフ',
  'Supista AI': 'スピスタAI',
  Reports: 'レポート',
  'Data Lake': 'データレイク',
  // My profile
  Language: '言語',
  'Sign Out': 'サインアウト',

  // project page
  'Total Sheets': '合計シート',
  'Total Charts': '合計グラフ',
  'Total Sections': 'セクションの合計',
  'Go To Sheet': 'シートへ行く',
  'Go To Report': 'レポートへ行く',

  'Go To Charts': 'グラフへ',
  // project settings
  Settings: '設定',
  'Edit project and sheet settings here.': 'ここにプロジェクトとシートの設定を編集します。',
  'Project Details': 'プロジェクト詳細',
  Sheets: 'シート',
  'Project Name': 'プロジェクト名',
  'Project Icon': 'プロジェクトアイコン',
  'Enter Icon of project that will be displayed in Aside Nav Bar':
    'メニューに表示されるプロジェクトのアイコンを入力してください',
  'Project Description': 'プロジェクト説明',
  'Go To Sheets Settings': 'シート設定へ',
  'Created project successfully!': 'プロジェクトが正常に作成されました!',
  // sheet settings
  'Select Sheet': 'シートを選択',
  'Select or Add New Sheet': '新しいシートを選択または追加する',
  'Sheet Details': 'シート詳細',
  'Name this Sheet': 'シート名を入力ください',
  'Database Settings': 'データベース設定',
  'Provide your Database Details': 'データベース詳細を入力ください',
  'No Sheeets Available!': 'シートがありません!',
  'Please add new sheet using the above button.':
    '上のボタンを使用して新しいシートを追加してください',
  'Please add new sheet using the above button.':
    '上のボタンを使用して新しいシートを追加してください',
  'Sheet Name': 'シート名',
  'Sheet Description': 'シートの説明',
  'Sheet added successfully': 'シートが正常に追加されました',
  'Delete Sheet': 'シートを削除',
  'Sheet deleted successfully': 'シートが正常に削除されました',

  Back: '戻る',
  Continue: '続く',
  Next: '次へ',
  'Database Details': 'データベース詳細',
  'Enter Database Details': 'データベース詳細を入力ください',
  'Table Name': 'テブル名',
  Submit: '送信する',

  // No Assets Screen page
  'No Dashboard added or Database connected to this Account.':
    'このアカウントにダッシュボードが追加されていないか、データベースが接続されていません。',
  'Please add Database in': '以下にデータベースを追加してください',
  'If you have already connected Database': '既にデータベースに接続している場合',
  'Add New Dashboard': '新しいダッシュボードを追加',
  'No Sheet added to this Account!': 'このアカウントにシートが追加されていません！',
  'No Reports added to this Account!': 'このアカウントにレポートが追加されていません！',
  'Add New Report': '新しいレポートを追加',
  'No data has been connected in this Account. Please connect to start using Supista AI!':
    'このアカウントにデータが接続されていません。Supista AIを使用するには接続してください！',
  'Can not connect with the server. Please come back later!':
    'サーバーに接続できません。後ほどお試しください！',
  'Refresh Page': 'ページを更新',
  'Give us a moment to load all your data. It will just take a moment!':
    'データをすべて読み込むまで少々お待ちください。すぐに完了します！',

  // Chart page
  'There was a problem while fetching chart, please try again later!':
    'チャートの取得中に問題が発生しました。後ほど再試行してください！',
  'Please add a chart!': 'チャートを追加してください！',
  // Filter strip
  Timeframe: 'タイムフレーム',
  'Download Data': 'データをダウンロード',
  'Please Wait': 'お待ちください',
  '1 Week': '1週間',
  '1 Month': '1ヶ月',
  '3 Months': '3ヶ月',
  '6 Months': '6ヶ月',
  '1 Year': '1年',
  '3 Years': '3年',
  'All Data': 'すべてのデータ',
  'Custom Range': 'カスタム範囲',

  //Data Lake
  Connections: '接続',
  'No Data Source Connections. Please Connect with your Spreadsheets, Databases or Apps to start importing tables.':
    'データソース接続がありません。テーブルのインポートを開始するには、スプレッドシート、データベース、またはアプリに接続してください。',
  'Import Table From External Database': '外部データベースからテーブルをインポート',
  'Table List': 'テーブルリスト',
  'Table import settings': 'テーブルのインポート設定',
  'Some error occured while fetching the connection details.':
    '接続の詳細を取得中にエラーが発生しました。',
  'Please select a table from the left bar to import it to Supista.':
    '左のバーからテーブルを選択して、それをSupistaにインポートしてください。',
  'Could not fetch table list from the server! Kindly check your credentials or try again later':
    'サーバーからテーブルリストを取得できませんでした！資格情報を確認するか、後で再試行してください',
  'Connect To A New Data Source': '新しいデータソースに接続',
  'Edit Data Source Connection': 'データソース接続を編集',
  'Connection Type:': '接続タイプ：',
  'Select connection type': '接続タイプを選択',
  'Connection name': '接続名',
  'Enter the connection name': '接続名を入力',
  'Select the connection type': '接続タイプを選択',
  'Select refresh interval': '更新間隔を選択',
  'Connection Type': '接続タイプ',
  Description: '説明',
  'Import Tables': 'テーブルをインポート',
  'Connection deleted successfully': '接続が正常に削除されました',
  'Delete Connection': '接続を削除',
  'Are you sure want to delete connection': '接続を削除してもよろしいですか',
  'Confirm Connection Name': '接続名を確認',
  'This Connection is currently in use, so deleting it will delete the following':
    'この接続は現在使用中です。削除すると以下が削除されます',
  // Table settings
  'No data tables imported. Please import them in the Data Source Section to start analysing them.':
    'データテーブルがインポートされていません。分析を開始するには、データソースセクションでインポートしてください。',
  'Table Settings': 'テーブル設定',
  'Edit Table settings here': 'ここでテーブル設定を編集',
  'Table name': 'テーブル名',
  'Enter table name': 'テーブル名を入力',
  'Table description': 'テーブルの説明',
  'Default Datetime Column': 'デフォルトの日時列',
  'Select columns to import': 'インポートする列を選択',
  Import: 'インポート',
  'Column Name': '列名',
  'Column Type': '列の種類',
  'Delete Table': 'テーブルを削除',
  'Save Table': 'テーブルを保存',
  'Saved successfully': '正常に保存されました',
  'Unable to save': '保存できません',
  'Are you sure you want to delete this table': 'このテーブルを削除してもよろしいですか',
  'This table is currently in use, so deleting it will delete the following':
    'このテーブルは現在使用中なので、削除すると以下が削除されます',
  Cancel: 'キャンセル',
  'Sort by': '並べ替え',
  Alphabetically: 'アルファベット順',
  'Created At': '作成日',
  'Updated At': '更新日',
  'No of Columns': '列数',
  "Some issues encountered. Couldn't create Join Table. Please try again later.":
    '問題が発生しました。結合テーブルを作成できませんでした。後ほどもう一度お試しください。',
  'Select the table to join': '結合するテーブルを選択',
  'Select primary key': 'プライマリキーを選択',
  'Select secondary key': 'セカンダリキーを選択',
  'Enter the name of the new joined table': '新しい結合テーブルの名前を入力',
  'Select the primary table': 'プライマリテーブルを選択',
  'Tables joined successfully': 'テーブルが正常に結合されました',
  'Charts Auto generated by Supista AI and added to the Sheet successfully!':
    'Supista AIによって自動生成されたチャートがシートに正常に追加されました！',
  'Table name must be unique': 'テーブル名は一意でなければなりません',
  "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)":
    "テーブル名には、スペース (' ')、ハイフン (-)、アンダースコア (_) の特殊文字のみを含めることができます",
  'Joined Table Deleted Successfully': '結合テーブルが正常に削除されました',
  "Couldn't Delete Joined Table.": '結合テーブルを削除できませんでした。',
  'Join New Tables': '新しいテーブルを結合',
  'Join-Table Settings': '結合テーブル設定',
  'Edit Join-Table settings here.': 'ここで結合テーブルの設定を編集する。',
  'Enter Join table name': '結合テーブル名を入力',
  'Select Columns of this table': 'このテーブルの列を選択',
  'No column is selected from this table!': 'このテーブルから列が選択されていません！',
  'Delete Join-Table': '結合テーブルを削除',
  'Sheet and its charts': 'シートとそのチャート：',
  'Sheets and its charts': 'シートとそのチャート：',
  'Save Join-Table': '結合テーブルを保存',
  'Primary Table': 'プライマリテーブル',
  'Secondary Table': 'セカンダリテーブル',
  'No. of Columns': '列数',
  'Join Table': '結合テーブル',
  'Primary Key': 'プライマリキー',
  'Select the primary key': 'プライマリキーを選択',
  'Secondary Key': 'セカンダリキー',
  'Select the secondary key': 'セカンダリキーを選択',
  'Remove Join Table': '結合テーブルを削除',

  // chart settings
  'Charts Settings': 'グラフ設定',
  'Edit charts settings here.': 'ここでグラフの設定を編集します',
  Name: '名前',
  'Chart Type': 'グラフ',
  None: '無し',
  'Chart Width': 'グラフ幅',
  Color: '色',
  Gradient: 'グラデーション',
  Variant: '変異体',
  Monochromatic: '単色',
  'Please select a color for monochromatic type': 'モノクロタイプのを選択してください',
  'Please select a gradient color': 'グラデーション色を選択してください',
  'Selected gradient': 'グラデーションを選択',
  Save: '保存',
  'X-Axis': 'X-軸',
  'Y-Axis': 'Y-軸',
  'Change order of charts': 'グラフのを変更する',
  'Type of chart:': 'グラフタイプ:',
  // chart edit parts
  'Edit charts': 'チャートを編集',
  'Chart deleted successfully': 'チャートが正常に削除されました',

  // filters
  'Apply Filters': 'フィルターを適用する',
  Filters: 'フィルター',
  Clear: 'クリア',
  Apply: '申し込み',
  // graphs
  'Try another chart configuration or another filter!':
    '別のグラフ構成または別のフィルタを試してください!',

  // version 2
  'Sign In to Supista Analytics': 'Supistaアナリティクスにサインインする',
  'Sorry, looks like there are some errors detected, please try again.':
    '申し訳ありませんが、いくつかのエラーが検出されたようです。もう一度やり直しください。',
  'The login detail is incorrect': 'ログイン情報が正しくありません',
  'Email is required': 'メールアドレスは必須です',
  'Password is required': 'パスワードは必須です',
  'Enter your email to reset your password.':
    'メールアドレスを入力してパスワードをリセットしてください',
  'Enter your new password.': '新しいパスワードを入力ください',
  'Sent password reset. Please check your email':
    'パスワードリセットのメールを送信しました。メールを確認ください',
  'Less than 10 Employees': '従業員数10人未満',
  '10 - 50 Employees': '10～50名の従業員',
  '50 - 500 Employees': '50～500名の従業員',
  '500+ Employees': '500人以上の従業員',
  'New Here': '初めての方?',
  'Wrong email format': '間違ったメール形式',
  'Enter the OTP to reset your password':
    'OTP「ワンタイムパスワード」を入力してパスワードをリセットしてください',
  'Enter OTP': 'OTPを入力ください',
  'Enter new password': '新しいパスワードを入力ください',
  'Confirm password': 'パスワードを確認',
  'Password too short': 'パスワードは短すぎます',
  'Confirm password is required': 'パスワードの確認が必要です',
  'Password is weak (must include atleast upper case, lower case, symbol and number)':
    'パスワードが脆弱です (少なくとも大小数字を含める必要があります)',
  Card: 'カードグラフ',
  Bar: '棒グラフ',
  Line: '線グラフ',
  Area: '面グラフ',
  'Bar Horizontal': '横棒',
  Pie: '円グラフ',
  Donut: 'ドーナツグラフ',
  'Bar Group': '棒グループ',
  'BAR Multiple Axes': '棒多軸',
  Funnel: '漏斗',
  'There was a problem while adding chart, please try again later!':
    'グラフの追加中に問題が発生しました。後程もう一度やり直しください!',
  'Chart added successfully': 'グラフが正常に追加されました',
  // Chart Settings
  'Chart Settings': 'チャート設定',
  'Edit Chart Settings here': 'ここでチャート設定を編集',
  Delete: '削除',
  'Mobile and Tablet browsers are not supported':
    'モバイルおよびタブレットのブラウザはサポートされていません',
  'Data Section': 'データセクション',
  'Build Charts': 'チャートを作成',
  Search: '検索',
  'Select Chart Type': 'チャートの種類を選択',
  yes: 'はい',
  no: 'いいえ',
  'Are you sure! you want to reset chart.': '本当にチャートをリセットしてもよろしいですか。',
  'Change Chart': 'チャートを変更',
  Build: 'ビルド',
  Style: 'スタイル',
  'Drop columns here': 'ここに列をドロップ',
  'Color Axis': 'カラーアクシス',
  'Double Y-Axis': 'ダブルY軸',
  'Target Points': 'ターゲットポイント',
  Tooltips: 'ツールチップ',
  'Default Filters': 'デフォルトフィルター',
  '+ Add Line': '+ ラインを追加',
  Label: 'ラベル',
  Done: '完了',
  Remove: '削除',
  Visibility: '可視性',
  'Reset to defaults': 'デフォルトにリセット',
  'Number Format': '数字のフォーマット',
  Compact: 'コンパクト',
  Currency: '通貨',
  'Trailing Places': '小数点以下の桁数',
  'Date Format': '日付の形式',
  'Open Filters': 'フィルターを開く',
  'Insight Summary': 'インサイト要約',
  'Y-Axis (Left)': 'Y軸（左）',
  'Y-Axis (Right)': 'Y軸（右）',
  Font: 'フォント',
  'Colour Palette': 'カラーパレット',
  Opacity: '不透明度',
  Values: '値',
  Title: 'タイトル',
  'Plot Color': 'プロットの色',
  'Chart Line Colors': 'チャート線の色',
  'Chart Display': 'チャート表示',
  'Edit Colors': '色を編集',
  Colors: '色',
  'Drop Any Column': '任意の列をドロップ',
  'Customize Legends': '凡例をカスタマイズ',
  Columns: '列',
  'Select color': '色を選択',
  Gridlines: 'グリッドライン',
  Horizontal: '水平',
  Vertical: '垂直',
  'Zoom Brush': 'ズームブラシ',
  'Card Image': 'カード画像',
  Axis: '軸',
  'Card Image Url': 'カード画像URL',
  'Invalid Image': '無効な画像',
  'Advance Options': '詳細オプション',
  'No DateTime Column Present': '日時列が存在しない',
  Compare: '比較',
  'Default Date Time Column': 'デフォルトの日時列',
  'Data Prefix': 'データ接頭辞',
  'Data Suffix': 'データ接尾辞',
  'Text Suffix': 'テキスト接尾辞',
  'KPI Target Points': 'KPIターゲットポイント',
  Formatter: 'フォーマッタ',
  'Time Frame': 'タイムフレーム',
  'Title Prefix': 'タイトル接頭辞',
  'Add Target Point': 'ターゲットポイントを追加',
  'Group Input': 'グループ入力',
  'Target Point': 'ターゲットポイント',
  'Arc Point': 'アークポイント',
  'Advance Option': '高度なオプション',
  'Title Postfix': 'タイトル接尾辞',
  Group: 'グループ',
  'BackGround Color': '背景色',
  'Select Max Color': '最大色を選択',
  'Select Min Color': '最小色を選択',
  Magnitude: '大きさ',
  'Select Manually': '手動で選択',
  'Select Color': '色を選択',
  'Chart Name': 'チャート名',
  'Chart Description': 'チャートの説明',
  'Description of Chart': 'チャートの説明',
  TimeFrame: 'タイムフレーム',
  All: '全て',
  Numerical: '数値',
  Categorical: 'カテゴリカル',
  'Date Time': '日時',
  Geographical: '地理的',
  'Text Identifier': 'テキスト識別子',
  'Num Identifier': '数値識別子',

  //Dashboard Settings
  'Open Dashboard Settings': 'ダッシュボード設定を開く',
  'Dashboard Settings': 'ダッシュボード設定',
  'Edit Dashboard Settings here': 'ここでダッシュボード設定を編集',
  SECTIONS: 'セクション',
  'SELECT CHARTS': 'チャートを選択',
  Add: '追加',
  'Change Name': '名前を変更',
  'Name of Dashboard': 'ダッシュボードの名前',
  'Description of Dashboard': 'ダッシュボードの説明',
  Dashboard: 'ダッシュボード',
  'Dashboard Name': 'ダッシュボード名',
  'Dashboard Description': 'ダッシュボードの説明',
  Section: 'セクション',
  Header: 'ヘッダー',
  'Drag And Drop': 'ドラッグアンドドロップ',
  'Charts From Sheet': 'シートからのチャート',
  Lists: 'リスト',
  'No charts found': 'チャートが見つかりません',
  'View More': 'もっと見る',
  Hide: '隠す',

  //Filters
  'Clear Filters': 'フィルターをクリア',
  'Select Any Filter': '任意のフィルターを選択',
  Min: '最小',
  Max: '最大',
  Select: '選択',
  'No More Data Found': 'これ以上のデータは見つかりません',

  //Report Settings
  'Report Settings': 'レポート設定',
  'Edit Report Settings here': 'ここでレポート設定を編集',
  'Edit Report': 'レポートを編集',
  'Report Configuration': 'レポート構成',
  'Report Name': 'レポート名',
  'Report Description': 'レポートの説明',
  'Report Schedule Frequency': 'レポートスケジュールの頻度',
  'Emails List': 'メールリスト',
  'Report TimeFrame': 'レポートのタイムフレーム',
  'Send Now': '今すぐ送信',
  'Send Email Now': '今すぐメールを送信',
  'Please fill out all required fields.': 'すべての必須フィールドを記入してください。',
  'Report Send Successfully': 'レポートが正常に送信されました',
  "It's not a valid Email": '有効なメールアドレスではありません',
  TOOLS: 'ツール',
  Text: 'テキスト',
  'Page Break': 'ページ区切り',
  'Report Wide Filter': 'レポート全体のフィルター',
  'Open Filter': 'フィルターを開く',
  'Input Text': 'テキストを入力',
  Chart: 'チャート',
  Insights: 'インサイト',
  'Chart Filter': 'チャートフィルター',
  'Chart/Insights': 'チャート/インサイト',
  'Font-Align': 'フォント揃え',

  //Table Head
  'Table Head': 'テーブルヘッド',
  'Select Table': 'テーブルを選択',
  'Type your query here...': 'ここにクエリを入力...',
  'Surprise Me': 'サプライズ',
  Send: '送信',
  'Please select a table from the dropdown.': 'ドロップダウンからテーブルを選択してください。',
  'Please write a query to get started.': '開始するためにクエリを記入してください。',
  'No chart found for the given query. Please try again with a different query.':
    '指定されたクエリに対するチャートが見つかりませんでした。別のクエリで再度お試しください。',
  'No Charts can be displayed! Please try again.':
    '表示できるチャートがありません！もう一度お試しください。',
  "Hello there! I'm Supista's chat AI bot.": 'こんにちは！私はSupistaのチャットAIボットです。',
  'Ready to create a chart based on your query. Please select a table from the dropdown and write your query to get started.':
    'クエリに基づいてチャートを作成する準備ができています。ドロップダウンからテーブルを選択し、クエリを記入して開始してください。',
  ' If you want you can': 'もしあなたが望むなら、次のことができます',
  'Create new chart': '新しいチャートを作成',
  '. After selecting a table from dropdown.': '。ドロップダウンからテーブルを選択した後。',
  'Apply filter on': 'フィルターを適用する',
  'Create New DashBoard': '新しいダッシュボードを作成',
  'values are': '値は',
  'range are': '範囲は',
  'selected last': '最後に選択',
  days: '日間',
  selected: '選択された',
  'selected date is': '選択された日付は',

  // visx lib
  'Select Column': '列を選択',
  'start from': 'から始める',
  'duration of': 'の期間',
  'Growth Rate Analysis': '成長率分析',
  'Maximum Minimum Analysis': '最大最小分析',
  'Pareto Rule Analysis': 'パレート則分析',
  Period: '期間',
  'Growth Rate': '成長率',
  'Maxmimum Analysis': '最大分析',
  'Time Period': '時間期間',
  'Performance Rate': 'パフォーマンス率',
  'Percentage Difference': 'パーセンテージの差',
  'Minimum Analysis': '最小分析',
  'Top Months': 'トップの月',
  Impact: '影響',
  Month: '月',
  Percentage: 'パーセンテージ',
  'Bottom Months': '最下位の月',
  'Most And Least Words Count': '最多および最少単語数',
  'Most Used Words': '最も使用された単語',
  Words: '単語',
  Count: 'カウント',
  'Top And Bottom Performers List': 'トップとボトムのパフォーマー一覧',
  'Top Performers': 'トップパフォーマー',
  'Bottom Performers': 'ボトムパフォーマー',
  'Statistical Analysis': '統計分析',
  'Top 20% Performers': 'トップ20%のパフォーマー',
  'Bottom 20% Performers': 'ボトム20%のパフォーマー',
  'Types of Analysis': '分析のタイプ',
  'Average Value': '平均値',
  'Median Value': '中央値',
  Statistical: '統計',
  'Show All': '全て表示',
  'Select Y-Axis': 'Y軸を選択',
  'Choose Column': '列を選択',
  'Target Achieved': '目標達成',
  'Target Achieving Probability': '目標達成確率',
  Days: '日数',
  'to go': '残り',
};

export default jp;
