import React, { useState } from 'react';
import PopUpDiv from './containers/PopUpDiv/PopUpDiv';
import TimeSeriesType from './containers/TimeSeriesType/TimeSeriesType';
import CategoricalType from './containers/CategoricalType/CategoricalType';
import './popupCardGlobal.scss';
import './scroll.scss';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import AnalysisIndex from './AnalysisIndex';

interface mainProps {
  mainData: any;
  mainPopUp: boolean;
  handleMainPopUp: Function;
  platform: string;
  handlePlatform: Function;
}
const AnalysisPopUp = (props: mainProps) => {
  const { mainData, mainPopUp, handleMainPopUp } = props;
  return (
    <div style={divStyle} className='AnalysisPopUp'>
      <div style={divStyle} className='AnalysisPopUp'>
        <PopUpDivChild
          initialValue={mainPopUp}
          setPopUp={handleMainPopUp}
          popupTitle={'AI Insights'}
          className='AnalysisPopUpDivContainer'
        >
          <AnalysisIndex mainData={mainData} />
        </PopUpDivChild>
      </div>
    </div>
  );
};

export default AnalysisPopUp;
const divStyle = {
  margin: '0rem',
};
