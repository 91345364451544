import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import ExportReport from 'lib/reports-analytics/ExportReport/ExportReport';

const Test = () => {
  return <>sdsd</>;
};
const Routes = () => {
  return (
    <Switch>
      {/* <Route path='/' component={Test} /> */}
      <Route path='/exportreport' component={ExportReport} />
    </Switch>
  );
};

export default withRouter(Routes);
