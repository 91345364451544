import { allChartKeys } from "./chartTypesSchema"

export const chartCapableOfHavingInsights = [
    allChartKeys.BAR,
    allChartKeys.BARSTACK, // IMPLEMENTED

    allChartKeys.LINE, 
    allChartKeys.MULTILINE, // IMPLEMENTED

    allChartKeys.AREA, 
    allChartKeys.AREASTACK, // IMPLEMENTED
    allChartKeys.BARGROUP, // IMPLEMENTED

    allChartKeys.BARHORIZONTAL, // IMPLEMENTED 
    // allChartKeys.BARSTACKHORIZONTAL,  // NOT IMPLEMENTED

    // allChartKeys.LINEHORIZONTAL,                 //NIC
    // allChartKeys.MULTILINEHORIZONTAL,            //NIC

    // allChartKeys.AREAHORIZONTAL,                 //NIC
    // allChartKeys.AREASTACKHORIZONTAL,            //NIC

    // allChartKeys.MULTIAXISMULTIAREAHORIZONTAL,   //NIC

    // allChartKeys.CARD, // NOT IMPLEMENTED
    allChartKeys.FUNNEL,

    allChartKeys.PIE, 
    allChartKeys.DONUT,
    
    allChartKeys.GEOINDIA, // IMPLEMENTED
    allChartKeys.GEOUSA, // IMPLEMENTED
    allChartKeys.GEOCANADA,// IMPLEMENTED
    allChartKeys.GEOWORLD,  // IMPLEMENTED

    allChartKeys.WORDCLOUD, // Data is incorrect   ??
    // allChartKeys.BUBBLEPLOT,               // NOT IMPLEMENTED
    // allChartKeys.GROUPBUBBLEPLOT,          // NOT IMPLEMENTED
    // allChartKeys.SCATTERPLOT,              // NOT IMPLEMENTED
    // allChartKeys.GROUPSCATTERPLOT,         // NOT IMPLEMENTED

    allChartKeys.TREEMAP,
    // allChartKeys.RADAR,
    // allChartKeys.RADIALLINE, // NOT IMPLEMENTED 
    // allChartKeys.BARSTACKLINE,
    // allChartKeys.KPIMETER,
    allChartKeys.PIVOTTABLE, // IMPLEMENTED
    // allChartKeys.HEATMAP,

    // allChartKeys.BARCOLOR, // NOT IMPLEMENTED
    // allChartKeys.LINECOLOR, // NOT IMPLEMENTED
    // allChartKeys.AREACOLOR, // NOT IMPLEMENTED
    // allChartKeys.BARHORIZONTALCOLOR, // NOT IMPLEMENTED
  ]

export  const defaultOthers = {
    label: "Others",
    originalLabel: "_d3_others",
    origin: "_d3_other",
    show: true,
    color: "#dff7ff",
    opacity: 70,
    column: "b27aafa4-5318-4895-b714-d94d3f9bd587.Business Unit",
    uniqueColumnName: "b27aafa4-5318-4895-b714-d94d3f9bd587.Business Unit-2",
    axis: "others"
  }