import useDivDimensions from '../../helpers/divDimensionHelper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Point } from '@visx/point';
import { Line, LineRadial } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { defaultToolTipStyling } from '../../constants/constants';
import { Text } from '@visx/text';
import { mls } from 'lib/multilanguagesupport';
import { RadarTypeToolTip } from '../ChartToolTip/ChartToolTip';
import RadarMultiselect from './RadarMultiselect/RadarMultiselect';
import { getDefaultColorByIndex, getStyling, hexWithOpacity } from '../../helpers/colorHelper';

const silver = '#d9d9d9';

const degrees = 360;

const y = (d) => d.y;

const genAngles = (length) =>
  [...new Array(length + 1)].map((_, i) => ({
    angle: i * (degrees / length),
  }));

const genPoints = (length, radius) => {
  const step = (Math.PI * 2) / length;
  return [...new Array(length)].map((_, i) => ({
    x: radius * Math.sin(i * step),
    y: radius * Math.cos(i * step),
  }));
};

function genPolygonPoints(dataArray, scale, getValue) {
  const step = (Math.PI * 2) / dataArray.length;
  const points = new Array(dataArray.length).fill({ x: 0, y: 0 });
  const pointString = new Array(dataArray.length + 1).fill('').reduce((res, _, i) => {
    if (i > dataArray.length) return res;
    const percentage = (100 * getValue(dataArray[i - 1])) / dataArray[i - 1].max;
    const xVal = scale(Math.abs(percentage)) * Math.sin(i * step);
    const yVal = scale(Math.abs(percentage)) * Math.cos(i * step);
    // const xVal = scale(percentage) * Math.sin(i * step);
    // const yVal = scale(percentage) * Math.cos(i * step);
    points[i - 1] = { x: xVal, y: yVal };
    res += `${xVal},${yVal} `;
    return res;
  });

  return { points, pointString };
}

function getData(dataProp, n) {
  const newData = JSON.parse(JSON.stringify(dataProp));

  newData?.forEach((d) => {
    Object.keys(d.y).forEach((k, i) => {
      if (i >= n) {
        delete d.y[k];
      }
    });
  });
  return newData ?? [];
}

let interval;
const zeroPoint = new Point({ x: 0, y: 0 });
const RadarType = ({
  data: radarData,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  levels = 5,
  theme,
  labels,
  desc,
  chartData,
  chartHeight: height = 400,
}) => {
  const automaticOpacity = (chartData?.tooltip?.automaticOpacity ?? 100) / 100;

  const dataProp = useMemo(() => {
    const { x: xData, y: yData } = radarData;
    const x = yData.map((yObj) => Object.keys(yObj)[0]);
    const convertedData = [];
    for (let i = 0; i < x.length; i++) {
      const yObj = {};
      xData.forEach((xVal, j) => {
        yObj[xVal] = Number(Object.values(yData[i])[0].data[j]);
      });
      convertedData.push({ x: x[i], y: yObj });
    }
    if (x.length === 0) convertedData.push({ x: [], y: [] });
    return convertedData;
  }, [radarData]);
  const [data, setData] = useState(getData(dataProp, 3));
  const [multiSelectedValues, setMultiSelectedValues] = useState(
    Object.keys(dataProp[0].y).slice(0, 3)
  );

  const { width } = useDivDimensions(cardRef);
  const xMax = width - margin.left - margin.right - 30;
  const yMax = height - margin.top - margin.bottom - 30;
  const radius = Math.min(xMax, yMax) / 2;
  const radialScale = useMemo(
    () =>
      scaleLinear({
        range: [0, Math.PI * 2],
        domain: [degrees, 0],
      }),
    []
  );

  const yScale2 = useMemo(
    () =>
      scaleLinear({
        range: [0, radius],
        domain: [0, 100],
      }),
    [radius]
  );
  const webs = useMemo(() => genAngles(data.length), [data.length]);
  const points = useMemo(() => genPoints(data.length, radius), [data.length, radius]);
  const polygonPoints = useMemo(
    () =>
      Object.keys(data[0].y).map((key) => {
        const polyData = data.map((d) => {
          const max = Math.max(...Object.values(d.y).map((o) => o));
          return { x: d.x, y: d.y[key], max };
        });
        console.log(polyData);
        return genPolygonPoints(polyData, (d) => yScale2(d) ?? 0, y);
      }),
    [data, yScale2]
  );

  useEffect(() => {
    setData(getData(dataProp, 3));
  }, [dataProp]);

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip();
  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const handleMouse = useCallback(
    (event, d, j) => {
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;

      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: {
          // x: Object.keys(d.y)[j],
          // [d.x]: Object.values(d.y)[j],
          nearestDatum: {
            key: j,
            datum: { x: d.x, y: d.y },
          },
          allValues: d.y,
        },
      });
    },
    [containerBounds, showTooltip]
  );

  const onSelect = (newValue) => {
    const newData = [];
    dataProp.forEach((d) => {
      const newD = { x: d.x, y: {} };
      newValue.forEach((v) => {
        newD.y[v] = d.y[v];
      });
      newData.push(newD);
    });
    setData(newData);
    setMultiSelectedValues(newValue);
  };
  const onRemove = (newValue) => {
    const newData = [];
    dataProp.forEach((d) => {
      const newD = { x: d.x, y: {} };
      newValue.forEach((v) => {
        newD.y[v] = d.y[v];
      });
      newData.push(newD);
    });
    setData(newData);
    setMultiSelectedValues(newValue);
  };
  const atFunction = ({ array = [], i }) => {
    if (i >= 0) {
      return i;
    } else {
      return array.length - i - 2;
    }
  };
  if (Object.keys(data[0].y).length <= 0) {
    return (
      <div
        className='fw-bolder my-1 fs-4 d-flex justify-content-center'
        style={{ color: '#7e8299' }}
      >
        {mls('No data to show with the current settings. Please use another settings!')}
      </div>
    );
  }
  return (
    <>
      <>
        <svg width={width} height={height} ref={containerRef}>
          <Group top={height / 2 - margin.top} left={width / 2}>
            {[...new Array(levels)].map((_, i) => (
              <LineRadial
                key={`web-${i}`}
                data={webs}
                angle={(d) => radialScale(d.angle) ?? 0}
                radius={((i + 1) * radius) / levels}
                fill='none'
                stroke={silver}
                strokeWidth={2}
                strokeOpacity={0.8}
                strokeLinecap='round'
              />
            ))}
            {[...new Array(data.length)].map((_, i) => {
              // angle between zeroPoint and point[i]
              const angle = Math.atan2(points[i].y, points[i].x) * (180 / Math.PI);
              return (
                <React.Fragment key={i}>
                  <Line key={`radar-line-${i}`} from={zeroPoint} to={points[i]} stroke={silver} />
                  {theme?.yAxisStyle?.show ? (
                    <Text
                      key={`radar-text-${i}`}
                      x={points[i].x + 15 * Math.cos((angle * Math.PI) / 180)}
                      y={points[i].y + 15 * Math.sin((angle * Math.PI) / 180)}
                      width={2000}
                      verticalAnchor='end'
                      style={{
                        ...getStyling(theme?.yAxisStyle?.style),
                      }}
                      textAnchor='middle'
                      angle={angle === 90 ? 0 : angle + 90}
                    >
                      {labels[(data[atFunction({ array: data, i: i - 1 })] ?? {})?.x]}
                    </Text>
                  ) : null}
                </React.Fragment>
              );
            })}

            {polygonPoints.map((pps, i) => {
              return (
                <polygon
                  key={`radar-polygon-${i}`}
                  points={pps.pointString}
                  // fill={theme.colors[Object.keys(data[0].y)[i]] ?? 'blue'}
                  fill={
                    theme.colors[Object.keys(data[0].y)[i]] ??
                    hexWithOpacity(getDefaultColorByIndex(i), automaticOpacity)
                  }
                  fillOpacity={0.2}
                  stroke={theme.colors[Object.keys(data[0].y)[i]]}
                  strokeWidth={1}
                />
              );
            })}

            {polygonPoints.map((pps, j) =>
              pps.points.map((point, i) => (
                <circle
                  key={`radar-point-${i}-${j}`}
                  cx={point.x}
                  cy={point.y}
                  r={4}
                  fill={
                    theme.colors[Object.keys(data[0].y)[j]] ??
                    hexWithOpacity(getDefaultColorByIndex(j), automaticOpacity)
                  }
                  onMouseEnter={(event) => {
                    if (interval) {
                      clearInterval(interval);
                    }
                    handleMouse(event, data[atFunction({ array: data, i: i })], j);
                  }}
                  onMouseLeave={() => {
                    interval = setTimeout(() => {
                      hideTooltip();
                    }, 1000);
                  }}
                  style={{ cursor: 'pointer' }}
                  onTouchStart={(event) => {
                    if (interval) {
                      clearInterval(interval);
                    }
                    handleMouse(event, data[atFunction({ array: data, i: i - 1 })], j);
                  }}
                  onTouchEnd={() => {
                    interval = setTimeout(() => {
                      hideTooltip();
                    }, 1000);
                  }}
                />
              ))
            )}
          </Group>
        </svg>
        {tooltipOpen && (
          <RadarTypeToolTip
            defaultToolTipStyling={defaultToolTipStyling}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            TooltipInPortal={TooltipInPortal}
            tooltipData={tooltipData}
            theme={theme}
            automaticOpacity={automaticOpacity}
            labels={labels}
            backgroundColor={defaultToolTipStyling.backgroundColor}
          />
        )}
        {/* {tooltipOpen && (
            <TooltipInPortal
              // className={styles.tooltip}
              style={defaultToolTipStyling}
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}
            >
              {tooltipData && (
                <div
                  style={{
                    fontFamily: theme.tooltip.font,
                    fontSize: theme.tooltip.fontHeight,
                  }}
                >
                  <div
                    style={{
                      color: theme.colors[tooltipData?.x],
                    }}
                  >
                    {tooltipData?.x}
                  </div>
                  <div style={{ color: theme.colors[Object.keys(tooltipData)[1]] }}>
                    <strong> {labels[Object.keys(tooltipData)[1]]}:</strong>{' '}
                    {tooltipData?.[Object.keys(tooltipData)[1]]}
                  </div>
                </div>
              )}
            </TooltipInPortal>
          )} */}
        {/* <Multiselect
            options={Object.keys(dataProp[0].y ?? {})}
            onSelect={onSelect}
            onRemove={onRemove}
            selectedValues={multiSelectedValues}
          /> */}
      </>
      <div
        className='titleDiv'
        style={{
          fontWeight: '500',
          fontSize: '1.4rem',
          height: '2rem',
          margin: ' 0.5rem',
          marginBottom: '.75rem',
        }}
      >
        {xAxisLabel ?? 'filterSelectedOptions'}
      </div>
      <RadarMultiselect
        options={Object.keys(dataProp[0].y ?? {})}
        onSelect={onSelect}
        onRemove={onRemove}
        selectedValues={multiSelectedValues}
        theme={theme}
      />
    </>
  );
};

export default RadarType;
function hexToRgbA(hex, opacity) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  } else return 'rgba(0,0,0,' + opacity + ')';
}
