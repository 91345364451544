import { useState, useMemo, useEffect, memo, useCallback } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import {
  // STARTING_DATA_LENGTH,
  defaultToolTipStyling,
  toggleObj,
  variantColors,
} from '../../constants/constants';
import styles from '../../constants/styles.module.css';
import { mls } from '../../multilanguagesupport/analytics';
import { Axis, Grid, lightTheme } from '@visx/xychart';

import { XYChart, Tooltip } from '@visx/xychart';
import { scaleBand } from '@visx/scale';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import GetYXChart from './GetYXChart';
import AxisLabelComponent from '../common/AxisLabelComponent';
import { getStyling, hexWithOpacity } from '../../helpers/colorHelper';
import useD3Brush from '../../helpers/hooks/useD3Brush';
import XYToolTip, { defaultToolTipBelongTo } from '../ChartToolTip/ChartToolTip';
import ObjectDropDown from '../../helpers/components/DropDown/ObjectDropDown';
import {
  dateTimeFormatter,
  numberFormatter,
} from 'lib/reusable-components/reusableFunction/formatter';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';

export const widthHelper = (x, type, chartWidth, margin) => {
  // Effective screen width
  const lineAreaWidth = 30;
  const effectiveWidth = chartWidth;

  // In case of a horizontal bar chart, width is always fine

  // Number of items to plot
  const plotNum = x.length;
  // Padding between each plot
  const padding = 0.1;

  // Find the width of each node based on chart type
  const nodeWidth = lineAreaWidth;

  // Calculate value of padding
  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * plotNum + margin.top + margin.bottom;

  // Return maximum of screenWidth or required Width
  return Math.max(reqWidth, effectiveWidth);
};

const accessors = {
  xAccessor: (d) => d.x,
  yAccessor: (d) => d.y,
};
//<-------------------------------------->

const brushMargin = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};
const chartSeparation = 10;

//<-------------------------------------->
function getRequiredFormat(xValues, yValues) {
  const reqData = [];

  for (let i = 0; i < yValues.length; i++) {
    reqData.push({ x: xValues[i], y: yValues[i] });
  }
  return reqData;
}

// const sliceXData = (xData, n) => {
//   const slicedData = {};
//   Object.keys(xData).forEach((key) => {
//     slicedData[key] = {};
//     slicedData[key].data = xData[key].data.slice(n);
//   });
//   return slicedData;
// };

const YXType = ({
  data,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin: marginProp,
  cardRef,

  labels,
  toggle,
  handleToggle,
  isToggleClicked,
  chartHeight,
  theme,
  chartData,
  isReport,
}) => {
  // const { x, y } = data;

  const x = useMemo(() => data.x, [data]);
  const y = useMemo(() => data.y, [data]);
  const isBrush = chartData.isZoomBrush === undefined ? true : chartData.isZoomBrush;
  const isDateTime = yCategory === 'DATETIME';
  const automaticOpacity = (chartData?.tooltip?.automaticOpacity ?? 70) / 100;

  const yAxisSpace = useMemo(() => {
    const maxWordForSpace = 4;
    const gettingAverageOf = y?.length <= 5 ? y?.length : 5;
    let avgValue = 0;

    for (let index = 0; index < gettingAverageOf; index++) {
      if (isDateTime) {
        const elementLength =
          dateTimeFormatter({
            type: { ...theme?.yAxisStyle?.formatter, format: toggle },
            value: y[index],
          })?.length ?? 0;
        avgValue = avgValue + elementLength;
      }
      const elementLength = y[index]?.length ?? 0;
      avgValue = avgValue + elementLength;
    }
    const avarageLetter = avgValue / gettingAverageOf;
    if (avarageLetter >= maxWordForSpace) {
      return -maxWordForSpace * 7;
    }
    return avarageLetter * 5;
  }, [y]);

  const isMobile = useMediaQuery('(max-width:600px)');
  const chartSeparation = useMemo(() => (isMobile ? 0 : 0), [isMobile]);
  const margin = { left: 80 - yAxisSpace, top: 15, bottom: 60, right: 15 };
  const height = chartHeight;
  const { width } = useDivDimensions(cardRef);

  const mainWidth = isBrush ? width * 0.9 : width;
  const miniWidth = width - mainWidth - chartSeparation;

  const miniMargin = { left: chartSeparation, top: 15, bottom: 60, right: 15 };

  const [filteredXData, setFilteredXData] = useState(x);
  const [filteredYData, setFilteredYData] = useState(y);
  //<-------------------------------------->

  const yBrushMax = Math.max(height - margin.top - margin.bottom, 0);

  const brushYScale = useMemo(() => {
    return scaleBand({
      domain: y,
      range: [yBrushMax, 0],
      padding: 0,
    });
  }, [y, yBrushMax]);

  const actualHeight = widthHelper(y, type, height, margin);
  const xFlatData = (Object.values(filteredXData)?.map((elem) => elem?.data ?? []) ?? []).flat(1);
  const actualWidth = widthHelper(xFlatData, type, mainWidth, margin);
  const dataNum = Math.floor((height / actualHeight) * y.length);
  const dataNumBottom = Math.min(
    Math.max(Math.floor((mainWidth / actualWidth) * xFlatData.length) - 2, 2),
    10
  );
  // const [brushPosition, setBrushPosition] = useState([y[y.length - 1], y[y.length - dataNum]]);
  useEffect(() => {
    setFilteredXData(x);
    setFilteredYData(y);
  }, [data, x, y]);
  const isColor =
    chartData.chartType === allChartKeys.BARCOLOR ||
    chartData.chartType === allChartKeys.LINECOLOR ||
    chartData.chartType === allChartKeys.AREACOLOR ||
    chartData.chartType === allChartKeys.BARHORIZONTALCOLOR;

  const getLegendObject = () => {
    if (isColor) {
      const legendColorScale = {};
      (theme?.tooltip?.valueStyle || []).forEach((vs) => {
        if (Object.keys(data?.x)?.includes(vs?.label)) {
          const color = vs?.color;
          const opacity = (vs?.opacity ?? 100) / 100;
          legendColorScale[vs.label || 'Null'] = hexWithOpacity(color, opacity);
        }
      });
      return {
        legendGlyphSize: 20,
        // colorScale: theme.colors,
        // labels: labels,
        colorScale: legendColorScale,
        isReport,
      };
    }

    let newLable = {};
    Object.keys(labels).forEach((labelKey) => {
      const findLabel = theme?.tooltip?.style.find((elm) => {
        return elm?.uniqueColumnName === labelKey;
      });
      newLable = { ...newLable, [labelKey]: findLabel.label ?? labels[labelKey] };
    });
    return {
      legendGlyphSize: 20,
      colorScale: theme.colors,
      // labels: labels,
      labels: newLable,

      isReport,
    };
  };
  // const { Legend, colorScale } = useOrdinalLegend({
  //   legendGlyphSize: 20,
  //   colorScale: theme.colors,
  //   labels: labels,
  // });
  const { Legend, colorScale } = useOrdinalLegend(getLegendObject());

  // const miniRef = useRef(null);
  const horizontalGridLines = chartData?.gridLines?.horizontal;
  const verticalGridLines = chartData?.gridLines?.vertical;
  const getStrokDashArray = (type) => {
    if (type === 'dashed') {
      return '10,10';
    }
    if (type === 'dotted') {
      return '2,4';
    }
    return '';
  };
  const brushed = useCallback(
    function ({ selection }) {
      if (selection) {
        const [y0, y1] = selection;
        let start = -1,
          end = y.length - 1;
        const yCopy = y.filter((d, i) => {
          if (y0 <= brushYScale(d) && brushYScale(d) <= y1) {
            if (start === -1) start = i;
            end = i;
            return true;
          }
          return false;
        });
        const filteredX = {};
        Object.keys(x).forEach((key) => {
          filteredX[key] = {
            data: x[key].data.slice(start, end + 1),
          };
        });
        // if (y1 - brushMargin.bottom >= height * 0.8 && y.length !== allY.length) {
        //   const n = Math.min(STARTING_DATA_LENGTH, allY.length - y.length);
        //   const newY = allY.slice(-y.length - n);
        //   setY(newY);
        //   setX(sliceXData(allX, -y.length - n));
        //   setBrushPosition([y[end], y[start]]);
        // }

        setFilteredXData(filteredX);
        setFilteredYData(yCopy);
      }
    },
    [brushYScale, x, y]
  );
  const { ref: miniRef } = useD3Brush({
    brushed,
    height: yBrushMax,
    width: Math.min(miniWidth - chartSeparation, 50),
    data: y,
    brushScale: brushYScale,
    dataNum,
    isVertical: true,
    isBrush,
    // brushPosition,
  });

  /// TooolTip
  const capitalizeOnlyFirst = (s) => {
    s = s.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const xAxis = useMemo(() => chartData.xAxis, [chartData.xAxis]);
  const yAxis = useMemo(() => chartData.yAxis, [chartData.yAxis]);

  const xAxisLabels = useMemo(() => {
    const axisLabels = xAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, xAxis]);

  const yAxisLabels = useMemo(() => {
    const axisLabels = yAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, yAxis]);

  const yAxisTickWidth = (height ?? 0) / filteredYData?.length;
  const AxisleftLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        valueStyle={theme.yAxisStyle.valueStyle}
        yAxisTickWidth={yAxisTickWidth}
        wordLength={8}
      />
    );
  };
  // Wrapper component for bottom axis using AxisLabelComponent
  const AxisBottomLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        bottomAxis
        valueStyle={theme.xAxisStyle.valueStyle}
      />
    );
  };
  const xAxisFormatter = theme?.xAxisStyle?.formatter;
  useEffect(() => {
    if (!isBrush) {
      setFilteredXData(x);
      setFilteredYData(y);
    }
  }, [isBrush]);
  return (
    <>
      <>
        {data.x &&
        data.x.length !== 0 &&
        data.y &&
        data.y.length !== 0 &&
        (chartData?.yAxis ?? [])?.length <= 1 ? (
          <>
            {yCategory === 'DATETIME' && (
              <>
                {width > 250 ? (
                  <div
                    style={{
                      marginLeft: width < 500 ? 0 : margin.left,
                    }}
                    className='d-flex mb-4 ml-sm-0'
                  >
                    <div
                      style={{ zIndex: 0 }}
                      className={styles.toogleBtnContianer}
                      data-toggle='buttons'
                    >
                      {Object.keys(toggleObj).map((item, index) => {
                        return (
                          <label
                            key={index}
                            className={` ${styles.toogleBtn} ${
                              toggle === item ? styles.active : ''
                            } `}
                          >
                            <input
                              type='radio'
                              name='options'
                              id={item}
                              autoComplete='off'
                              defaultChecked={index === 0}
                              hidden
                              onClick={() => handleToggle(item)}
                            />{' '}
                            {toggleObj[item]}
                          </label>
                        );
                      })}
                    </div>
                    {isToggleClicked && (
                      <Box
                        sx={{
                          display: 'flex',
                          marginLeft: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    )}
                  </div>
                ) : (
                  <div>
                    <ObjectDropDown
                      object={toggleObj}
                      dropDownValue={toggle}
                      setdropDownValue={handleToggle}
                    />
                  </div>
                )}
              </>
            )}
            <XYChart
              theme={{ ...lightTheme, colors: colorScale.range() }}
              height={height}
              width={mainWidth - 1}
              yScale={{
                type: 'band',
                paddingInner: type.toLowerCase().includes('bar') ? 0.2 : 1,
                paddingOuter: 0.1,
              }}
              xScale={{
                // type: chartData?.xAxis[1] ? "linear" : "band",
                type: 'linear',
                // paddingOuter: 0.1,
                // paddingInner: 100
                // type: 'linear', paddingInner: -10.3
                // type: chartData?.xAxis[1] || (chartData?.colorAxis && chartData?.colorAxis[0]) ? "linear" : "band"
              }}
              margin={margin}
            >
              {horizontalGridLines?.show && (
                <Grid
                  columns={false}
                  numTicks={horizontalGridLines?.count ?? 5}
                  strokeWidth={horizontalGridLines?.width ?? 1}
                  stroke={hexWithOpacity(
                    horizontalGridLines?.color,
                    horizontalGridLines?.opacity / 100
                  )}
                  strokeDasharray={getStrokDashArray(horizontalGridLines?.type)}
                  lineStyle={{}}
                />
              )}
              {verticalGridLines?.show && (
                <Grid
                  rows={false}
                  numTicks={verticalGridLines?.count ?? 5}
                  strokeWidth={verticalGridLines?.width ?? 1}
                  stroke={hexWithOpacity(
                    verticalGridLines?.color,
                    verticalGridLines?.opacity / 100
                  )}
                  strokeDasharray={getStrokDashArray(verticalGridLines?.type)}
                  lineStyle={{}}
                />
              )}
              {/* Axis Start */}
              {theme.xAxisStyle?.show ? (
                <Axis
                  labelClassName={styles.chartLabel}
                  labelProps={{
                    ...getStyling(theme.xAxisStyle?.style),
                    textAnchor: 'middle',
                  }}
                  tickLabelProps={() => ({
                    ...getStyling(theme.yAxisStyle?.valueStyle),
                    textAnchor: 'middle',
                  })}
                  label={xAxisLabel}
                  orientation='bottom'
                  tickClassName={styles.bottomAxisTicks}
                  tickFormat={(e) =>
                    numberFormatter({ type: { ...xAxisFormatter, compact: true }, value: e })
                  }
                  labelOffset={25}
                  tickComponent={AxisBottomLabelWrapper}
                  // stroke={theme?.xAxisStyle?.style.color}
                  numTicks={dataNumBottom}
                  strokeWidth='.5px'
                />
              ) : null}

              {theme.yAxisStyle?.show ? (
                <Axis
                  tickClassName={`${styles.leftAxisTicks} `}
                  labelClassName={styles.chartLabel}
                  label={yAxisLabel}
                  labelProps={{
                    ...getStyling(theme.yAxisStyle?.style),
                    textAnchor: 'middle',
                  }}
                  strokeWidth='1px'
                  orientation='left'
                  labelOffset={50 - yAxisSpace}
                  tickComponent={AxisleftLabelWrapper}
                  tickFormat={(e) => {
                    if (isDateTime) {
                      return dateTimeFormatter({
                        type: { ...theme?.yAxisStyle?.formatter, format: toggle },
                        value: e,
                      });
                    }
                    return e;
                  }}
                />
              ) : null}
              {/* Axis End */}

              <>
                <GetYXChart
                  yData={filteredYData}
                  xData={filteredXData}
                  getRequiredFormat={getRequiredFormat}
                  accessors={accessors}
                  colorScale={colorScale}
                  type={type}
                  automaticOpacity={automaticOpacity}
                />
              </>
              <XYToolTip
                defaultToolTipStyling={defaultToolTipStyling}
                accessors={accessors}
                theme={theme}
                colorScale={colorScale}
                xAxisLabels={xAxisLabels}
                yAxisLabels={yAxisLabels}
                labels={labels}
                belongsTo={defaultToolTipBelongTo.YX}
                backgroundColor={defaultToolTipStyling.backgroundColor}
                isColor={isColor}
                toggle={toggle}
                isDateTime={isDateTime}
                showVerticalCrosshair={false}
              />
            </XYChart>
            {isBrush && !isReport && (
              <svg
                height={height - miniMargin.top - miniMargin.bottom}
                width={Math.min(miniWidth - chartSeparation, 50)}
                style={{
                  marginTop: miniMargin.top,
                  marginBottom: miniMargin.bottom,
                  marginLeft: chartSeparation,
                }}
                ref={miniRef}
              >
                <MiniYXChart
                  miniWidth={miniWidth}
                  height={height}
                  miniMargin={miniMargin}
                  type={type}
                  colorScale={colorScale}
                  x={x}
                  y={y}
                />
              </svg>
            )}
            <div style={{ marginTop: 20 }}>
              <Legend />
            </div>
          </>
        ) : (
          <div
            className='fw-bolder my-1 fs-4 d-flex justify-content-center'
            style={{ color: '#7e8299' }}
          >
            {mls('No data to show with the current settings. Please use another settings!')}
          </div>
        )}
      </>
    </>
  );
};

const MiniYXChart = memo(({ miniWidth, height, miniMargin, type, x, y, colorScale }) => {
  return (
    <XYChart
      theme={{ ...lightTheme, colors: colorScale.range() }}
      margin={{ ...brushMargin, left: 0, right: 0 }}
      width={Math.min(miniWidth - chartSeparation, 50)}
      height={height - miniMargin.top - miniMargin.bottom}
      yScale={{
        type: 'band',
        paddingInner: type.toLowerCase().includes('bar') ? 0.2 : 1,
        paddingOuter: 0.1,
      }}
      xScale={{ type: 'linear' }}
    >
      <GetYXChart
        yData={y}
        xData={x}
        getRequiredFormat={getRequiredFormat}
        accessors={accessors}
        colorScale={colorScale}
        type={type}
      />
    </XYChart>
  );
});

export default YXType;
