// import { defaultDateObject } from '../../../reusable-components/reusableData/formatterData';

export const getReportDatesByPeriod = (getReportDatesByPeriodProps) => {
  const { dateData, isTempMail } = getReportDatesByPeriodProps;
  const { period = '', xDay = 0, startDate, endDate } = dateData;
  if (period === defaultDateObject.TODAY.period) {
    const Today = {
      startDate: getDays({}),
    };
    return Today;
  }
  if (period === defaultDateObject.YESTERDAY.period) {
    const Yesterday = {
      startDate: getDays({ minus: 1 }),
    };
    return Yesterday;
  }
  if (period === defaultDateObject.THISWEEK.period) {
    if (isTempMail) {
      const Week = {
        startDate: getWeek({ dateType: 'first' }),
        endDate: getWeek({}),
      };
      return Week;
    }
    const Week = {
      startDate: getWeek({ minus: 7, dateType: 'first' }),
      endDate: getWeek({ minus: 7, dateType: 'last' }),
    };
    return Week;
  }
  if (period === defaultDateObject.THISMONTH.period) {
    if (isTempMail) {
      const Month = {
        startDate: getMonth({ minus: 1, dateType: 'first' }),
        endDate: getMonth({}),
      };
      return Month;
    }
    const Month = {
      startDate: getMonth({ minus: 1 + 1, dateType: 'first' }),
      endDate: getMonth({ minus: 1, dateType: 'last' }),
    };
    return Month;
  }
  if (period === defaultDateObject.THISQUARTER.period) {
    if (isTempMail) {
      const Quater = {
        startDate: getQuater({ minus: 1, dateType: 'first' }),
        endDate: getQuater({}),
      };
      return Quater;
    }
    const Quater = {
      startDate: getQuater({ minus: 1 + 1, dateType: 'first' }),
      endDate: getQuater({ minus: 1, dateType: 'last' }),
    };
    return Quater;
  }
  if (period === defaultDateObject.THISYEAR.period) {
    if (isTempMail) {
      const Year = {
        startDate: getYear({ minus: 1, dateType: 'first' }),
        endDate: getYear({}),
      };
      return Year;
    }
    const Year = {
      startDate: getYear({ minus: 1 + 1, dateType: 'first' }),
      endDate: getYear({ minus: 1, dateType: 'last' }),
    };
    return Year;
  }
  if (period === defaultDateObject.LAST7DAYS.period) {
    const Last7Days = {
      startDate: getDays({ minus: 7 }),
      endDate: getDays({}),
    };
    return Last7Days;
  }
  if (period === defaultDateObject.LAST30DAYS.period) {
    const Last30Days = {
      startDate: getDays({ minus: 30 }),
      endDate: getDays({}),
    };
    return Last30Days;
  }
  if (period === defaultDateObject.LASTXDAYS.period) {
    const LastXDayDays = {
      startDate: getDays({ minus: xDay }),
      endDate: getDays({}),
    };
    return LastXDayDays;
  }
  if (period === defaultDateObject.CUSTOM.period) {
    return CustomDate({ startDate, endDate });
  }
};

const getDays = ({ minus = 0, plus = 0 }) => {
  const now = new Date();
  const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - minus + plus);
  return finalDateFormat(date);
};
const getMonday = (d, plus = 0) => {
  d = new Date(d);
  let day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff + plus));
};
const getWeek = ({ minus = 0, plus = 0, dateType = 'current' }) => {
  if (dateType === 'first') {
    const now = getMonday(new Date());
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - minus + plus);
    return finalDateFormat(date);
  }
  if (dateType === 'last') {
    const now = getMonday(new Date(), 6);
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - minus + plus);
    return finalDateFormat(date);
  }
  const now = new Date();
  const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - minus + plus);
  return finalDateFormat(date);
};
const getMonth = ({ minus = 0, plus = 0, dateType = 'current' }) => {
  const now = new Date();
  const dateByDateType = dateType === 'last' ? 0 : dateType === 'first' ? 1 : now.getDate();
  const monthByDateType = now.getMonth() - minus + plus + (dateType === 'last' ? 1 : 0);
  const date = new Date(now.getFullYear(), monthByDateType, dateByDateType);
  return finalDateFormat(date);
};
const getQuater = ({ minus = 0, plus = 0, dateType = 'current' }) => {
  const now = new Date();
  const dateByDateType = dateType === 'last' ? 0 : dateType === 'first' ? 1 : now.getDate();
  const monthByDateType =
    now.getMonth() - minus * 4 + plus * 4 + (dateType === 'first' || dateType === 'last' ? 1 : 0);
  const date = new Date(now.getFullYear(), monthByDateType, dateByDateType);
  return finalDateFormat(date);
};
const getYear = ({ minus = 0, plus = 0, dateType = 'current' }) => {
  const now = new Date();
  const dateByDateType = dateType === 'last' ? 0 : dateType === 'first' ? 1 : now.getDate();
  const monthByDateType = now.getMonth() + (dateType === 'first' || dateType === 'last' ? 1 : 0);
  const date = new Date(now.getFullYear() - minus + plus, monthByDateType, dateByDateType);
  return finalDateFormat(date);
};

const CustomDate = (dateData) => {
  if (!dateData?.startDate) {
    return { startDate: '' };
  }
  const startDateFull = new Date(dateData?.startDate || 0);
  const endDateFull = new Date(dateData?.endDate || 0);
  const startDate = finalDateFormat(startDateFull);
  const endDate = finalDateFormat(endDateFull);
  const CustomDays = {
    startDate: startDate,
    endDate: endDate,
  };
  return CustomDays;
};

const monthWithZerosFunction = (monthNumber) => {
  return monthNumber < 10 ? '0' + monthNumber : monthNumber;
};
const dayNumberWithZeroFunction = (dayNumber) => {
  return dayNumber < 10 ? '0' + dayNumber : dayNumber;
};
const finalDateFormat = (date) => {
  const finalDate = `${dayNumberWithZeroFunction(date.getDate())}-${monthWithZerosFunction(
    date.getMonth() + 1
  )}-${date.getFullYear()}`;
  return finalDate;
};
const defaultDateObject = {
  TODAY: {
    period: 'TODAY',
    name: 'Today',
  },
  YESTERDAY: {
    name: 'Yesterday',
    period: 'YESTERDAY',
  },
  THISWEEK: {
    name: 'This week',
    period: 'THISWEEK',
  },
  THISMONTH: {
    name: 'This month',
    period: 'THISMONTH',
  },
  THISYEAR: {
    name: 'This year',
    period: 'THISYEAR',
  },
  LAST7DAYS: {
    name: 'Last 7 days',
    period: 'LAST7DAYS',
  },
  LAST30DAYS: {
    name: 'Last 30 days',
    period: 'LAST30Days',
  },
  THISQUARTER: {
    name: 'This quarter',
    period: 'THISQUARTER',
  },
  CUSTOM: {
    name: 'Custom Date',
    period: 'CUSTOM',
  },
  LASTXDAYS: {
    name: 'Last X day',
    period: 'LASTXDAYS',
  },
};
