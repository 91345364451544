import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import React, { useState } from 'react';
import TimeSeriesType from './containers/TimeSeriesType/TimeSeriesType';
import CategoricalType from './containers/CategoricalType/CategoricalType';
import { mls } from 'lib/multilanguagesupport';

interface AnalysisIndexProps {
  mainData: any;
  isReport?: boolean;
}
const AnalysisIndex = ({ mainData, isReport = false }: AnalysisIndexProps) => {
  const horizontalCharts = [
    allChartKeys.BARHORIZONTAL,
    allChartKeys.BARHORIZONTALCOLOR,
    allChartKeys.BARSTACKHORIZONTAL,
    allChartKeys.LINEHORIZONTAL,
    allChartKeys.MULTILINEHORIZONTAL,
    allChartKeys.AREAHORIZONTAL,
    allChartKeys.AREASTACKHORIZONTAL,
  ];

  const isHorizotal = horizontalCharts.some((chartKey) => chartKey === mainData?.chartType);
  const numChartAxis = (isHorizotal ? mainData?.xcolumnName : mainData?.ycolumnName) ?? {};
  const catChartAxis = (isHorizotal ? mainData?.ycolumnName : mainData?.xcolumnName) ?? {};
  const [axisIndex, setAxisIndex] = useState(0);
  const [axisData, setAxisData] = useState(Object.keys(numChartAxis)[0] ?? '');
  const selectColumn = (e: any) => {
    setAxisData(e.data);
    setAxisIndex(e.index);
  };
  return (
    <div className='AnalysisIndex'>
      {mainData?.dataSections?.length >= 2 ? (
        <div className='columnSelectionDiv'>
          <div className='selectionDivTitle'>{mls('Select Column')}:</div>
          <div className='selectDiv'>
            <ObjectDropDown
              object={numChartAxis}
              dropDownValue={axisData}
              setdropDownValue={selectColumn}
              withIndex
            />
          </div>
        </div>
      ) : null}

      {mainData.type === 'TIMESERIES' && (
        <TimeSeriesType
          mainData={mainData}
          axisIndex={axisIndex}
          catChartAxis={catChartAxis}
          isReport={isReport}
          chartType={mainData?.chartType}
        />
      )}
      {mainData.type === 'CATEGORY' && (
        <CategoricalType
          mainData={mainData}
          axisIndex={axisIndex}
          catChartAxis={catChartAxis}
          isReport={isReport}
          chartType={mainData?.chartType}
        />
      )}
    </div>
  );
};

export default AnalysisIndex;
