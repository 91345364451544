const sendToSlack = async ({ text, appID, isError = true, name, reportID }) => {
  try {
    const slack_url =
      'https://hooks.slack.com/services/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';
    const errorText = `There is an error in appID : ${appID} \n  
    name: ${name} \n  
    reportID: ${reportID} \n  
    msg:${text}
    `;
    const finalText = isError ? errorText : `${text}`;
    const dataToSend = {
      channel: 'analytics-reports-errors',
      text: finalText,
    };
    const res = await fetch(`${slack_url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(dataToSend),
    });

    // waits until the request completes...
    const data = await res.json();
    if (data.status === 200) {
      console.log(data);
    }
  } catch (error) {
    console.log(error);
  }
};
export default sendToSlack;
