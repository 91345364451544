import React, { useEffect } from 'react';
import './popUpDiv.scss';
import { FaTimes } from 'react-icons/fa';

const PopUpDivChild = ({
  className = '',
  children,
  initialValue = false,
  setPopUp,
  popupTitle = '',
  header = true,
  scrollOff = true,
}) => {
  const handlePopUp = () => {
    setPopUp(!initialValue);
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  useEffect(() => {
    if (scrollOff) {
      document.body.classList.add('PopUpDivChildScrollLock');
      return () => {
        document.body.classList.remove('PopUpDivChildScrollLock');
      };
    }
  }, []);

  return (
    <div className={`PopUpDivChild ${className}`}>
      {initialValue && (
        <div className='popUpOuterContaier' onClick={() => handlePopUp()}>
          <div className='PopUpInnerContaier' onClick={(e) => stopPropagation(e)}>
            {header ? (
              <div className='popupTitleDiv'>
                <div className='popupTitle'>{popupTitle}</div>
                <div className='closeButton' onClick={() => handlePopUp()}>
                  <FaTimes />
                </div>
              </div>
            ) : null}
            <div className='ChildernContainer'>{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopUpDivChild;
