const checkDataToPlotLength = (dataToPlot) => {
  return Array.isArray(dataToPlot) && dataToPlot.length !== 0;
};

const bar = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }

  const data = {
    x: dataToPlot[0].data[0].x,
    y: dataToPlot[0].data[0].y[0][yAxis[0]?.uniqueColumnName || yAxis[0].column].data,
  };

  return data;
};

const pie = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }

  const data = {
    x: dataToPlot[0].data[0].x,
    y: dataToPlot[0].data[0].y[0][yAxis[0]?.uniqueColumnName || yAxis[0].column].data,
  };
  return data;
};

const multiline = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }
  const y = {};
  const yData = dataToPlot[0].data[0].y;
  yData.forEach((obj, ix) => {
    const key = Object.keys(obj)[0];
    y[key] = obj[key];
  });

  const data = {
    x: dataToPlot[0].data[0].x,
    y: y,
  };
  return data;
};
const geo = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }
  const y = {};
  const yData = dataToPlot[0].data[0].y;
  yData.forEach((obj, ix) => {
    const key = Object.keys(obj)[0];
    y[key] = obj[key].data;
  });

  const data = {
    x: dataToPlot[0].data[0].x,
    y: y,
  };
  return data;
};
const yxHorizontal = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }
  const x = {};
  const xData = dataToPlot[0].data[0].x;
  xData.forEach((obj, ix) => {
    const key = Object.keys(obj)[0];
    x[key] = obj[key];
  });

  const data = {
    y: dataToPlot[0].data[0].y,
    x: x,
  };
  return data;
};

const groupSP = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }

  const data = {
    // group: dataToPlot[0].data[0].x,
    x: dataToPlot[0].data[0].x,
    y: dataToPlot[0].data[0].y[0][yAxis[0]?.uniqueColumnName || yAxis[0].column].data,
  };
  return data;
};

const bubble = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }
  const data = {
    // group: dataToPlot[0].data[0].x,
    // x: dataToPlot[0].data[0].y[0][yAxis[0].column].data,
    x: dataToPlot[0].data[0].x,
    y: dataToPlot[0].data[0].y[0][yAxis[0]?.uniqueColumnName || yAxis[0].column].data,
    radius: dataToPlot[0].data[0].y[1]?.[yAxis[1]?.uniqueColumnName || yAxis[1]?.column]?.data,
  };
  return data;
};

const wordCloud = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { text: [], value: [] };
  }
  const freq = dataToPlot[0].data[0].y[0].data;
  const words = dataToPlot[0].data[0].x;
  const data = {
    text: words,
    value: freq,
  };
  return data;
};

const treeMap = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }
  const data = {
    // group: dataToPlot[0].data[0].x,
    x: dataToPlot[0].data[0].x,
    y: dataToPlot[0].data[0].y[0][yAxis[0]?.uniqueColumnName || yAxis[0].column]?.data,
  };
  return data;
};

const radial = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return [];
  }

  const x = dataToPlot[0].data[0].x;
  const y = yAxis.map((yAxisObj, i) => ({
    [yAxisObj?.uniqueColumnName || yAxisObj?.column]:
      dataToPlot[0].data[0].y[i][yAxisObj?.uniqueColumnName || yAxisObj?.column]?.data,
  }));
  const data = [];
  for (let i = 0; i < x.length; i++) {
    const yData = {};
    y.forEach((yObj) => {
      yData[Object.keys(yObj)[0]] = Object.values(yObj)[0][i];
    });
    data.push({ x: x[i], y: yData });
  }
  return data;
};

const radar = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }
  const data = {
    // group: dataToPlot[0].data[0].x,
    x: dataToPlot[0].data[0].x,
    y: dataToPlot[0].data[0].y,
  };
  return data;

  // const x = dataToPlot[0].data[0].y.map((yObj) => Object.keys(yObj)[0]);
  // const data = [];
  // for (let i = 0; i < x.length; i++) {
  //   const yObj = {};
  //   dataToPlot[0].data[0].x.forEach((xVal, j) => {
  //     yObj[xVal] = Number(Object.values(dataToPlot[0].data[0].y[i])[0].data[j]);
  //   });
  //   data.push({ x: x[i], y: yObj });
  // }
  // return data;
};
const lineFormat = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }
  const y = {};
  dataToPlot[0].data[0].y.forEach((yobj) => {
    const key = Object.keys(yobj)[0];
    y[key] = [...yobj[key].data];
  });
  const data = {
    x: dataToPlot[0].data[0].x,
    y: y,
  };
  return data;
};

const funnel = (dataToPlot, xAxis, yAxis) => {
  if (!checkDataToPlotLength(dataToPlot)) {
    return { x: [], y: [] };
  }
  const data = {
    x: dataToPlot[0].data[0].x,
    y: dataToPlot[0].data[0].y[0][yAxis[0]?.uniqueColumnName || yAxis[0].column].data,
  };

  return data;
};

const colorStackCharts = (dataToPlot, plotOptions) => {
  const uniqueItems = plotOptions?.uniqueItems || [];
  const values = dataToPlot[0]?.data[0]?.values || [];
  const dataToReturn = {
    x: [],
    y: {},
  };

  uniqueItems.forEach((item) => {
    dataToReturn.y[item] = { data: [] };
  });

  const stateSet = new Set(); // To keep track of unique states

  // Loop through preData values and organize data in one pass
  values.forEach((items) => {
    const [state, value, data] = items;

    // Add the state to the set if it's not already there
    if (!stateSet.has(state)) {
      stateSet.add(state);
      dataToReturn.x.push(state);
    }

    // Update y data using the value
    uniqueItems.forEach((ele) => {
      if (!dataToReturn.y[ele].data[stateSet.size - 1]) {
        dataToReturn.y[ele].data[stateSet.size - 1] = 0;
      }
      if (value === ele) {
        dataToReturn.y[ele].data[stateSet.size - 1] = data;
      }
    });
  });
  return dataToReturn;
};

const colorStackHorizontalCharts = (dataToPlot, plotOptions) => {
  const uniqueItems = plotOptions?.uniqueItems || [];
  const values = dataToPlot[0]?.data[0]?.values || [];
  const dataToReturn = {
    // x: [],
    // y: {},
    x: {},
    y: [],
  };

  uniqueItems.forEach((item) => {
    dataToReturn.x[item] = { data: [] };
  });

  const stateSet = new Set(); // To keep track of unique states

  // Loop through preData values and organize data in one pass
  values.forEach((items) => {
    const [state, value, data] = items;

    // Add the state to the set if it's not already there
    if (!stateSet.has(state)) {
      stateSet.add(state);
      dataToReturn.y.push(state);
    }

    // Update y data using the value
    uniqueItems.forEach((ele) => {
      if (!dataToReturn.x[ele].data[stateSet.size - 1]) {
        dataToReturn.x[ele].data[stateSet.size - 1] = 0;
      }
      if (value === ele) {
        dataToReturn.x[ele].data[stateSet.size - 1] = data;
      }
    });
  });
  return dataToReturn;
};

const convertToGraphFormat = (type, dataToPlot, xAxis, yAxis, chartData, plotOptions) => {
  const isForecast = chartData && chartData.isForecast;
  let data;
  switch (type) {
    case 'SCATTERPLOT':
      data = bar(dataToPlot, xAxis, yAxis);
      break;
    case 'PIE':
    case 'DONUT':
      data = pie(dataToPlot, xAxis, yAxis);
      break;

    case 'FUNNEL':
      data = funnel(dataToPlot, xAxis, yAxis);
      break;

    case 'BARSTACK':
    case 'AREASTACK':
    case 'BAR':
    case 'BARGROUP':
    case 'AREA':
    case 'MULTILINE':
    case 'LINE':
    case 'BARSTACKLINE':
      if (!isForecast) data = multiline(dataToPlot, xAxis, yAxis);
      else data = lineFormat(dataToPlot, xAxis, yAxis);
      break;

    case 'GROUPSCATTERPLOT':
      data = groupSP(dataToPlot, xAxis, yAxis);
      break;

    case 'BUBBLEPLOT':
      data = bubble(dataToPlot, xAxis, yAxis);
      break;
    case 'GROUPBUBBLEPLOT':
      data = bubble(dataToPlot, xAxis, yAxis);
      break;

    case 'GEOINDIA':
    case 'GEOCANADA':
    case 'GEOUSA':
    case 'GEOWORLD':
      data = geo(dataToPlot, xAxis, yAxis);
      break;

    // case 'GEOUSA':
    // case 'GEOWORLD':
    //   data = bar(dataToPlot, xAxis, yAxis);
    //   break;

    case 'WORDCLOUD':
      data = wordCloud(dataToPlot, xAxis, yAxis);
      break;

    case 'TREEMAP':
      data = treeMap(dataToPlot, xAxis, yAxis);
      break;

    case 'BARSTACKHORIZONTAL':
    case 'MULTILINEHORIZONTAL':
    case 'AREASTACKHORIZONTAL':
    case 'BARHORIZONTAL':
    case 'BARGROUPHORIZONTAL':
      data = yxHorizontal(dataToPlot, xAxis, yAxis);
      break;
    case 'RADIALLINE':
      data = radial(dataToPlot, xAxis, yAxis);
      break;

    case 'RADAR':
      data = radar(dataToPlot, xAxis, yAxis);
      break;
    case 'BARCOLOR':
    case 'LINECOLOR':
    case 'AREACOLOR':
      data = colorStackCharts(dataToPlot, plotOptions);
      break;
    case 'BARHORIZONTALCOLOR':
      data = colorStackHorizontalCharts(dataToPlot, plotOptions);
      break;
    default:
      return {};
  }
  return data;
};

export default convertToGraphFormat;
