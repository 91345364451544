import { dateTimeFormatter } from 'lib/reusable-components/reusableFunction/formatter';

/**
 * This methods date Array and converts it into the required format
 * The required format is Unix Time epoch
 * @param {Array} data Unformatted date Array
 * @returns {Array} The formatted data
 */
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const dateFormatHelper = (data) => {
  const tempData = [];
  data.forEach((e) => {
    const x = new Date(e).getTime();
    tempData.push(x);
  });
  return tempData;
};

export const axisDateFormatter = ({ data, toggle, type }) => {
  const tempData = [];
  data?.forEach((e) => {
    // tempData.push(singleDateFormatter(e, toggle));
    const formatter = dateTimeFormatter({ type: { ...type, format: toggle }, value: e });
    tempData.push(formatter);
  });
  return tempData;
};
