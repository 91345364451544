const reduxConstants = {
  STORE_NAME: 'config_store',
  actions: {
    CREATE: 'create',
    UPDATE: 'update',
  },
  config: {
    LOGIN: 'login',
    DATE_TIME_TOGGLE: 'dateTimeToggle',
    APP_SCHEMA: 'appSchema',
    USER_SCHEMA: 'userSchema',
    APP_STATUS: {
      TOAST: 'appStatus.toast',
      ERROR_TOAST: 'appStatus.errorToast',
      LOADING: 'appStatus.loading',
    },
  },
};

export default reduxConstants;
