import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { AxisLeft, AxisBottom } from '@visx/axis';
import { Text } from '@visx/text';
import { GridColumns, GridRows } from '@visx/grid';
import { format } from 'd3-format';

import { Brush } from '@visx/brush';

import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
// import { localPoint } from '@visx/event';

import ChartBuilder from './ChartBuilder';
import AxisLabelComponent from '../common/AxisLabelComponent';

import styles from '../../constants/styles.module.css';

import scaleHelper from './helpers/scaleHelper';
import {
  defaultAxisColor,
  defaultAxisLabelFontSize,
  defaultBrushHeight,
  // defaultColors,
} from '../../constants/constants';
import { defaultDateTickFormat, categoryDateTickFormat } from '../../helpers/tickFormatHelpers';
import { widthHelper } from './helpers/dimensionHelper';
import { sortValues } from './helpers/sortValues';
// import ColorPicker from '../ColorPicker';
import useDivDimensions from '../../helpers/divDimensionHelper';
import { KTSVG } from '../../_metronic/helpers';
// import DateRangeHelper from '../../helpers/components/DateRangeHelper';
import { mls } from '../../multilanguagesupport/analytics';
import * as d3 from 'd3';
import { scaleBand, scaleLinear } from '@visx/scale';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: 'rgba(53,71,125,0.8)',
  color: 'white',
  // width: 152,
  // height: 72,
  padding: 12,
};

const BarType = ({
  data,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  gradientColor,
}) => {
  const { x, y } = data;
  const chartSettingsButton = useChartSettingsButton();
  // Color for chart
  // eslint-disable-next-line no-unused-vars
  // Ref for Brush element
  const brushRef = useRef(null);

  const height = 300;
  const { width } = useDivDimensions(cardRef);

  // Adjusted width for expected width of graphs
  const actualWidth = widthHelper(x, type, width, margin);

  // Check if chart requires brush
  const noBrush = false;

  // Adding height of brush to effective Height
  const effHeight = height + (!noBrush ? defaultBrushHeight + 10 : 0);

  // Calculating the number of data items to be plot in starting
  const dataNum = Math.min(Math.floor((width / actualWidth) * x.length), 20);
  const initialStartPos = noBrush ? 0 : x.length - dataNum;
  const initialEndPos = noBrush ? dataNum : x.length - 1;
  // Filtered data for initial rendering
  const [filteredX, setFilteredX] = useState(x.slice(initialStartPos, initialEndPos + 1));
  const [filteredY, setFilteredY] = useState(y.slice(initialStartPos, initialEndPos + 1));
  // Is the chart funnel chart
  const isFunnel = type === 'FUNNEL';
  // Is the chart Horizontal
  const isHorizontal = type === 'BARHORIZONTAL' || isFunnel;

  // Extra margin for Horizontal Charts
  const extraLeftMargin = isHorizontal ? 90 : 0;

  // Maximum width and height available for x and y axis
  const xMax = width - (margin.left + extraLeftMargin) - margin.right;
  const yMax = height - margin.top - (isFunnel ? 0 : margin.bottom);

  // Sorting values for use in Funnel Chart
  const { sortedX, sortedY } = sortValues(x, y);

  // Scales for Chart
  const xScale = useMemo(
    () =>
      scaleHelper(
        isFunnel ? sortedX : filteredX,
        xCategory,
        type,
        xMax,
        yMax,
        true,
        actualWidth === width
      ),
    [xMax, xCategory, type, filteredX, actualWidth, width, yMax, sortedX, isFunnel]
  );
  const yScale = useMemo(
    () => scaleHelper(isFunnel ? sortedY : filteredY, yCategory, type, xMax, yMax, false, true),
    [filteredY, yMax, yCategory, type, xMax, sortedY, isFunnel]
  );

  // Scale for brush
  const brushXScale = useMemo(
    () => scaleHelper(x, xCategory, type, xMax, defaultBrushHeight, true, false),
    [x, xCategory, type, xMax]
  );

  // const brushXScale = useMemo(() => {
  //   return scaleBand({
  //     domain: x,
  //     range: [0, xMax],
  //     padding: 0,
  //   });
  // }, [x, xMax]);

  const brushYScale = useMemo(
    () => scaleHelper(y, yCategory, type, xMax, defaultBrushHeight, false, false),
    [y, yCategory, type, xMax]
  );

  // Event handle for change of brush
  const onBrushChange = (domain) => {
    if (!domain) return;

    const { x0, x1, xValues } = domain;
    const yCopy = [];
    const xCopy = x.filter((e, i) => {
      if (e > x0 && e < x1) {
        yCopy.push(y[i]);
        return true;
      }
      return false;
    });
    if (xCategory !== 'CAT') {
      setFilteredX(xCopy);
      setFilteredY(yCopy);
    } else {
      // filtering undefined values from xValues
      const filxValue = xValues.filter((e) => e !== undefined);

      const y0 = x.indexOf(filxValue[0]);
      const lastX = filxValue[filxValue.length - 1];
      const y1 = x.indexOf(lastX);
      setFilteredY(y.slice(y0, y1 + 1));
      setFilteredX(filxValue);
    }
  };

  // Initial Brush Position
  const initialBrushPosition = {
    start: { x: brushXScale(x[initialStartPos]) },
    end: { x: brushXScale(x[initialEndPos]) },
  };

  // Creating tooltip
  // Creating a tooltip for the chart
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // Function to handle mouse over change
  const handleMouseOver = useCallback(
    (event, datum) => {
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;
      // const coords = localPoint(event.target.ownerSVGElement, event);
      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: datum,
      });
    },
    [containerBounds.left, containerBounds.top, showTooltip]
  );

  // let patternColor = defaultColors[0];
  // if (colorType === 'Monochromatic') {
  //   patternColor = chartColor;
  // } else if (colorType === 'Gradient') {
  //   patternColor = gradientColor.from;
  // }

  useEffect(() => {
    setFilteredX(x.slice(initialStartPos, initialEndPos + 1));
    setFilteredY(y.slice(initialStartPos, initialEndPos + 1));
  }, [initialEndPos, initialStartPos, x, y]);

  // Wrapper component for left axis using AxisLabelComponent
  const AxisleftLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        isHorizontal={isHorizontal}
        handleMouseOver={handleMouseOver}
        hideTooltip={hideTooltip}
      />
    );
  };
  // Wrapper component for bottom axis using AxisLabelComponent
  const AxisBottomLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        bottomAxis
        handleMouseOver={handleMouseOver}
        hideTooltip={hideTooltip}
      />
    );
  };
  const refBrush = useRef(null);

  useEffect(() => {
    if (!refBrush.current) return;

    function brushed({ selection }) {
      if (!selection) return;

      const [x0, x1] = selection;
      const xValues = [];
      x.forEach((d, i) => {
        if (brushXScale(d) >= x0 && brushXScale(d) <= x1) {
          xValues.push(d);
        }
      });
      onBrushChange({ x0: xValues[0], x1: xValues[xValues.length - 1], xValues });
    }

    let svg = d3.select(refBrush.current);
    const brush = d3
      .brushX()
      .extent([
        [0, 0],
        [xMax, defaultBrushHeight],
      ])
      .on('brush end', brushed);

    const defaultSelection = [brushXScale(x[x.length - dataNum]), brushXScale(x[x.length - 1])];

    const gb = svg.append('g').call(brush).call(brush.move, defaultSelection);

    gb.selectAll('.selection')
      .attr('fill', 'steelblue')
      .attr('stroke', '#009ef7')
      .attr('fill-opacity', '20%')
      .attr('stroke-width', 1);

    return () => {
      svg.selectAll('*').remove();
    };
  }, [data, width]);

  return (
    <>
      <div>
        <div className='card-header border-0 p-0 pb-3'>
          <div
            className={`${styles.titleDiv} w-100 d-flex justify-content-between align-items-center`}
          >
            {/* Append Title of the chart */}
            <h2 className='mb-0'>{mls(title)}</h2>
            <div>
              {/* {xCategory !== 'DATETIME' ? (
                <></>
              ) : (
                <DateRangeHelper chartId={chartID} defaultValue='DAY' cardWidth={cardWidth} />
              )} */}
              {/* <ColorPicker color={color} updateColor={updateColor} /> */}
              {isProd && (
                <div className='card-toolbar'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    onClick={() => chartSettingsButton(chartID)}
                  >
                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                  </button>
                  {/* end::Menu */}
                </div>
              )}
            </div>
          </div>
        </div>
        {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
          <>
            <svg ref={containerRef} height={effHeight} width={width + 16}>
              {/* Append Label for x Axis */}
              {!isFunnel && (
                <Text
                  x={width / 2}
                  y={margin.top + yMax + margin.bottom - defaultAxisLabelFontSize}
                  className={styles.chartLabel}
                  verticalAnchor='middle'
                  textAnchor='middle'
                  fontSize={defaultAxisLabelFontSize}
                >
                  {mls(xAxisLabel)}
                </Text>
              )}
              {/* Append Label for y Axis */}
              <Text
                angle={-90}
                x={defaultAxisLabelFontSize}
                y={height / 2}
                className={styles.chartLabel}
                textAnchor='middle'
                verticalAnchor='middle'
                fontSize={defaultAxisLabelFontSize}
              >
                {mls(yAxisLabel)}
              </Text>
              {/* If yTicks is true append Grid Rows */}
              {yGrid && !isFunnel && (
                <GridRows
                  scale={yScale}
                  width={xMax}
                  top={margin.top}
                  left={margin.left + extraLeftMargin}
                />
              )}
              {/* If xTicks is true append Grid Columns */}
              {xGrid && !isFunnel && (
                <GridColumns
                  scale={xScale}
                  height={yMax}
                  top={margin.top}
                  left={margin.left + extraLeftMargin}
                />
              )}
              {/* Append chart */}
              <ChartBuilder
                chartID={chartID}
                x={filteredX}
                y={filteredY}
                type={type}
                yCategory={yCategory}
                xCategory={xCategory}
                height={height}
                width={width}
                xScale={xScale}
                yScale={yScale}
                yMax={yMax}
                xMax={xMax}
                handleMouseOver={handleMouseOver}
                hideTooltip={hideTooltip}
                top={margin.top}
                left={margin.left + extraLeftMargin}
                handleClick={handleClick}
                colorType={colorType}
                gradientColor={gradientColor}
                chartColor={chartColor}
              />
              {/* Append Axis Bottom */}
              {!isFunnel && (
                <AxisBottom
                  top={yMax + margin.top}
                  left={margin.left + extraLeftMargin}
                  scale={xScale}
                  stroke={defaultAxisColor}
                  numTicks={6}
                  tickStroke={defaultAxisColor}
                  tickClassName={styles.bottomAxisTicks}
                  tickFormat={
                    xCategory === 'DATETIME'
                      ? defaultDateTickFormat
                      : xCategory === 'CAT'
                      ? categoryDateTickFormat
                      : null
                  }
                  tickComponent={AxisBottomLabelWrapper}
                />
              )}
              {/* Append Axis Left */}
              <AxisLeft
                top={margin.top}
                left={margin.left + extraLeftMargin}
                scale={yScale}
                stroke={defaultAxisColor}
                tickStroke={defaultAxisColor}
                tickFormat={yCategory === 'NUM' ? format('~s') : null}
                tickComponent={AxisleftLabelWrapper}
                tickClassName={`${styles.leftAxisTicks} ${
                  isHorizontal ? styles.leftAxisHorizontal : ''
                }`}
              />
              {/* Append Brush */}
              {!noBrush && (
                <ChartBuilder
                  chartID={chartID + '-brush'}
                  x={x}
                  y={y}
                  type={type}
                  yCategory={yCategory}
                  xCategory={xCategory}
                  margin={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  height={defaultBrushHeight}
                  width={width - (margin.left + extraLeftMargin)}
                  xScale={brushXScale}
                  yScale={brushYScale}
                  yMax={defaultBrushHeight}
                  xMax={xMax}
                  handleMouseOver={() => {}}
                  hideTooltip={() => {}}
                  left={margin.left + extraLeftMargin}
                  top={height}
                  colorType={colorType}
                  gradientColor={gradientColor}
                  chartColor={chartColor}
                  handleClick={handleClick}
                >
                  {/* <Brush
                    xScale={brushXScale}
                    yScale={brushYScale}
                    width={width - (margin.left + extraLeftMargin)}
                    height={defaultBrushHeight + 5}
                    margin={{
                      top: 0,
                      left: margin.left,
                      // left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                    innerRef={brushRef}
                    brushDirection='horizontal'
                    initialBrushPosition={initialBrushPosition}
                    onChange={onBrushChange}
                    // selectedBoxStyle={{
                    //   fill: patternColor,
                    //   stroke: { patternColor },
                    //   strokeWidth: 1,
                    //   strokeOpacity: 1,
                    //   fillOpacity: 0.4,
                    // }}
                    useWindowMoveEvents
                  /> */}
                  <svg ref={refBrush} />
                </ChartBuilder>
              )}
            </svg>
            {tooltipOpen && (
              <>
                <div
                  className='barcrosshair vertical'
                  style={{ transform: `translateX(${tooltipLeft + 29}px)` }}
                />

                <TooltipInPortal
                  key={Math.random()}
                  top={tooltipTop}
                  left={tooltipLeft}
                  style={tooltipStyles}
                >
                  {tooltipData.axis ? (
                    <>
                      <div>{tooltipData.x}</div>
                    </>
                  ) : (
                    <>
                      <div>
                        {mls(xAxisLabel)}:{'\t'}
                        <strong>{tooltipData.x}</strong>
                      </div>
                      <div>
                        {mls(yAxisLabel)}:{'\t'}
                        <strong>{tooltipData.y}</strong>
                      </div>
                    </>
                  )}
                </TooltipInPortal>
              </>
            )}
            <style>
              {`
                .barcrosshair {
                  position: absolute;
                  top: 95px;
                  left: 0;
                }
                .barcrosshair.vertical {
                  height: ${height - 80}px;
                  width: 1px;
                  border-left: 2px dashed #35477d;
                }
              `}
            </style>
          </>
        ) : (
          <div
            className='fw-bolder my-1 fs-4 d-flex justify-content-center'
            style={{ color: '#7e8299' }}
          >
            {mls('No data to show with the current settings. Please use another settings!')}
          </div>
        )}
      </div>
    </>
  );
};

BarType.propTypes = {
  data: PropTypes.shape().isRequired,
  xAxisLabel: PropTypes.string.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  yCategory: PropTypes.string.isRequired,
  xCategory: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  xGrid: PropTypes.bool.isRequired,
  yGrid: PropTypes.bool.isRequired,
  margin: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }).isRequired,
  cardRef: PropTypes.shape().isRequired,
  chartID: PropTypes.string.isRequired,
  useChartSettingsButton: PropTypes.func.isRequired,
  isProd: PropTypes.string.isRequired,
  cardWidth: PropTypes.string.isRequired,
};

export default BarType;
