import { mls } from '../../multilanguagesupport/analytics';
import React, { useState } from 'react';
import ScatterPlotChart from './ScatterPlotChart';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import { hexWithOpacity } from '../../helpers/colorHelper';

const GroupScatterPlotType = ({
  data,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  theme,
  labels,
  group,
  chartHeight,
  desc,
  chartData,
  isReport,
}) => {
  const groupColorMap = {};
  const groupLabels = {};

  theme.tooltip.valueStyle.forEach((l) => {
    const color = l?.color;
    const opacity = (l?.opacity ?? 100) / 100;
    groupColorMap[l.label] = hexWithOpacity(color, opacity);
    groupLabels[l.label] = l.label;
  });
  const [isShowMore, setisShowMore] = useState(false);
  // theme.tooltip.valueStyle.forEach((l) => {
  //   const color = l?.color;
  //   const opacity = (l?.opacity ?? 100) / 100;
  //   groupLabels[l.label] = l.label;
  // });
  const handleShowMore = () => {
    setisShowMore((currenetState) => !currenetState);
  };
  const shape = 'circle';
  const legendGlyphSize = 20;
  const maxlableShow = 5;
  const { Legend, colorScale } = useOrdinalLegend({
    legendGlyphSize: legendGlyphSize,
    shape: shape,
    colorScale: groupColorMap,
    labels: groupLabels,
    maxlableShow: maxlableShow,
    handleShowMore,
    isReport,
  });

  return (
    <>
      {data.x && data.x.length !== 0 && data.y && data.y.length !== 0 ? (
        <>
          <div style={{ height: chartHeight }}>
            <ParentSize>
              {({ width, height }) => {
                const newHeight = height ? height : 400;
                return (
                  <>
                    <ScatterPlotChart
                      xAxisLabel={xAxisLabel}
                      yAxisLabel={yAxisLabel}
                      isGroupType={type === 'GROUPSCATTERPLOT'}
                      data={data}
                      width={width}
                      height={newHeight}
                      colorScale={colorScale}
                      xCategory={xCategory}
                      yCategory={yCategory}
                      theme={theme}
                      group={group}
                      chartData={chartData}
                    />
                  </>
                );
              }}
            </ParentSize>
          </div>
          <Legend />
        </>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

export default GroupScatterPlotType;
