const en = {
  // Header
  Projects: 'Projects',
  'Add New Project': 'Add New Project',
  'Add New Sheet': 'Add New Sheet',
  'Add New Chart': 'Add New Chart',
  'Please add sheet to go to charts!': 'Please add sheet to go to charts!',
  // Aside nav
  Charts: 'Charts',
  'Supista AI': 'Supista AI',
  Reports: 'Reports',
  'Data Lake': 'Data Lake',
  // My profile
  Language: 'Language',
  'Sign Out': 'Sign Out',

  'Total Sheets': 'Total Sheets',
  'Total Sections': 'Total Sections',
  'Total Charts': 'Total Charts',
  'Go To Charts': 'Go To Charts',
  'Go To Sheet': 'Go To Sheet',
  'Go To Report': 'Go To Report',
  // sheet settings
  Settings: 'Settings',
  Sheets: 'Sheets',
  'Select Sheet': 'Select Sheet',
  'Select or Add New Sheet': 'Select or Add New Sheet',
  'Sheet Details': 'Sheet Details',
  'Name this Sheet': 'Name this Sheet',
  'Database Settings': 'Database Settings',
  'Provide your Database Details': 'Provide your Database Details',
  'No Sheeets Available!': 'No Sheeets Available!',
  'Please add new sheet using the above button.': 'Please add new sheet using the above button.',
  'Sheet Name': 'Sheet Name',
  'Sheet Description': 'Sheet Description',
  'Sheet added successfully': 'Sheet added successfully',
  'Delete Sheet': 'Delete Sheet',
  'Sheet deleted successfully': 'Sheet deleted successfully',
  Back: 'Back',
  Continue: 'Continue',
  Next: 'Next',
  'Database Details': 'Database Details',
  'Enter Database Details': 'Enter Database Details',
  'Table Name': 'Table Name',
  Submit: 'Submit',
  // No Assets Screen page
  'No Dashboard added or Database connected to this Account.':
    'No Dashboard added or Database connected to this Account.',
  'Please add Database in': 'Please add Database in',
  'If you have already connected Database': 'If you have already connected Database',
  'Add New Dashboard': 'Add New Dashboard',
  'No Sheet added to this Account!': 'No Sheet added to this Account!',
  'No Reports added to this Account!': 'No Reports added to this Account!',
  'Add New Report': 'Add New Report',
  'No data has been connected in this Account. Please connect to start using Supista AI!':
    'No data has been connected in this Account. Please connect to start using Supista AI!',
  'Can not connect with the server. Please come back later!':
    'Can not connect with the server. Please come back later!',
  'Refresh Page': 'Refresh Page',
  'Give us a moment to load all your data. It will just take a moment!':
    'Give us a moment to load all your data. It will just take a moment!',

  // Chart page
  'There was a problem while fetching chart, please try again later!':
    'There was a problem while fetching chart, please try again later!',
  'Please add a chart!': 'Please add a chart!',
  // Filter strip
  Timeframe: 'Timeframe',
  'Download Data': 'Download Data',
  'Please Wait': 'Please Wait',
  '1 Week': '1 Week',
  '1 Month': '1 Month',
  '3 Months': '3 Months',
  '6 Months': '6 Months',
  '1 Year': '1 Year',
  '3 Years': '3 Years',
  'All Data': 'All Data',
  'Custom Range': 'Custom Range',

  //Data Lake
  Connections: 'Connections',
  'No Data Source Connections. Please Connect with your Spreadsheets, Databases or Apps to start importing tables.':
    'No Data Source Connections. Please Connect with your Spreadsheets, Databases or Apps to start importing tables.',
  'Import Table From External Database': 'Import Table From External Database',
  'Table List': 'Table List',
  'Table import settings': 'Table import settings',
  'Some error occured while fetching the connection details.':
    'Some error occured while fetching the connection details.',
  'Please select a table from the left bar to import it to Supista.':
    'Please select a table from the left bar to import it to Supista.',
  'Could not fetch table list from the server! Kindly check your credentials or try again later':
    'Could not fetch table list from the server! Kindly check your credentials or try again later',
  'Connect To A New Data Source': 'Connect To A New Data Source',
  'Edit Data Source Connection': 'Edit Data Source Connection',
  'Connection Type:': 'Connection Type:',
  'Select connection type': 'Select connection type',
  'Connection name': 'Connection name',
  'Enter the connection name': 'Enter the connection name',
  'Select the connection type': 'Select the connection type',
  'Select refresh interval': 'Select refresh interval',
  'Connection Type': 'Connection Type',
  Description: 'Description',
  'Import Tables': 'Import Tables',
  'Connection deleted successfully': 'Connection deleted successfully',
  'Delete Connection': 'Delete Connection',
  'Are you sure want to delete connection': 'Are you sure want to delete connection',
  'Confirm Connection Name': 'Confirm Connection Name',
  'This Connection is currently in use, so deleting it will delete the following':
    'This Connection is currently in use, so deleting it will delete the following',
  // Table settings
  'No data tables imported. Please import them in the Data Source Section to start analysing them.':
    'No data tables imported. Please import them in the Data Source Section to start analysing them.',
  'Table Settings': 'Table Settings',
  'Edit Table settings here': 'Edit Table settings here',
  'Table name': 'Table name',
  'Enter table name': 'Enter table name',
  'Table description': 'Table description',
  'Default Datetime Column': 'Default Datetime Column',
  'Select columns to import': 'Select columns to import',
  Import: 'Import',
  'Column Name': 'Column Name',
  'Column Type': 'Column Type',
  'Delete Table': 'Delete Table',
  'Save Table': 'Save Table',
  'Saved successfully': 'Saved successfully',
  'Unable to save': 'Unable to save',
  'Are you sure you want to delete this table': 'Are you sure you want to delete this table',
  'This table is currently in use, so deleting it will delete the following':
    'This table is currently in use, so deleting it will delete the following',
  Cancel: 'Cancel',
  'Sort by': 'Sort by',
  Alphabetically: 'Alphabetically',
  'Created At': 'Created At',
  'Updated At': 'Updated At',
  'No of Columns': 'No of Columns',
  "Some issues encountered. Couldn't create Join Table. Please try again later.":
    "Some issues encountered. Couldn't create Join Table. Please try again later.",
  'Select the table to join': 'Select the table to join',
  'Select primary key': 'Select primary key',
  'Select secondary key': 'Select secondary key',
  'Enter the name of the new joined table': 'Enter the name of the new joined table',
  'Select the primary table': 'Select the primary table',
  'Tables joined successfully': 'Tables joined successfully',
  'Charts Auto generated by Supista AI and added to the Sheet successfully!':
    'Charts Auto generated by Supista AI and added to the Sheet successfully!',
  'Table name must be unique': 'Table name must be unique',
  "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)":
    "Table name can only contain these special characters: space (' '), hyphen (-) and underscore (_)",
  'Joined Table Deleted Successfully': 'Joined Table Deleted Successfully',
  "Couldn't Delete Joined Table.": "Couldn't Delete Joined Table.",
  'Join New Tables': 'Join New Tables',
  'Join-Table Settings': 'Join-Table Settings',
  'Edit Join-Table settings here.': 'Edit Join-Table settings here.',
  'Enter Join table name': 'Enter Join table name',
  'Select Columns of this table': 'Select Columns of this table',
  'No column is selected from this table!': 'No column is selected from this table!',
  'Delete Join-Table': 'Delete Join-Table',
  'Sheet and its charts:': 'Sheet and its charts:',
  'Sheets and its charts:': 'Sheets and its charts:',
  'Save Join-Table': 'Save Join-Table',
  'Primary Table': 'Primary Table',
  'Secondary Table': 'Secondary Table',
  'No. of Columns': 'No. of Columns',
  'Join Table': 'Join Table',
  'Primary Key': 'Primary Key',
  'Select the primary key': 'Select the primary key',
  'Secondary Key': 'Secondary Key',
  'Select the secondary key': 'Select the secondary key',
  'Remove Join Table': 'Remove Join Table',
  // chart settings
  'Charts Settings': 'Charts Settings',
  'Edit charts settings here.': 'Edit charts settings here.',
  Name: 'Name',
  'Chart Type': 'Chart Type',
  None: 'None',
  'Chart Width': 'Chart Width',
  Color: 'Color',
  Gradient: 'Gradient',
  Variant: 'Variant',
  Monochromatic: 'Monochromatic',
  'Please select a color for monochromatic type': 'Please select a color for monochromatic type',
  'Please select a gradient color': 'Please select a gradient color',
  'Selected gradient': 'Selected gradient',
  Save: 'Save',
  'X-Axis': 'X-Axis',
  'Y-Axis': 'Y-Axis',
  'Change order of charts': 'Change order of charts',
  'Type of chart:': 'Type of chart:',
  // chart edit parts
  'Edit charts': 'Edit charts',
  'Chart deleted successfully': 'Chart deleted successfully',
  // filters
  'Apply Filters': 'Apply Filters',
  Filters: 'Filters',
  Clear: 'Clear',
  Apply: 'Apply',
  // graphs
  'No data to show with the current settings. Please use another settings!':
    'No data to show with the current settings. Please use another settings!',

  // version 2
  'Sign In to Supista Analytics': 'Sign In to Supista Analytics',
  'Sorry, looks like there are some errors detected, please try again.':
    'Sorry, looks like there are some errors detected, please try again.',
  'The login detail is incorrect': 'The login detail is incorrect',
  'Email is required': 'Email is required',
  'Password is required': 'Password is required',
  'Enter your email to reset your password.': 'Enter your email to reset your password.',
  'Enter your new password.': 'Enter your new password.',
  'Sent password reset. Please check your email': 'Sent password reset. Please check your email',
  'Less than 10 Employees': 'Less than 10 Employees',
  '10 - 50 Employees': '10 - 50 Employees',
  '50 - 500 Employees': '50 - 500 Employees',
  '500+ Employees': '500+ Employees',
  'New Here': 'New Here',
  'Wrong email format': 'Wrong email format',
  'Enter the OTP to reset your password': 'Enter the OTP to reset your password',
  'Enter OTP': 'Enter OTP',
  'Enter new password': 'Enter new password',
  'Confirm password': 'Confirm password',
  'Password too short': 'Password too short',
  'Confirm password is required': 'Confirm password is required',
  'Password is weak (must include atleast upper case, lower case, symbol and number)':
    'Password is weak (must include atleast upper case, lower case, symbol and number)',

  'There was a problem while adding chart, please try again later!':
    'There was a problem while adding chart, please try again later!',
  'Chart added successfully': 'Chart added successfully',

  // Chart Settings
  'Chart Settings': 'Chart Settings',
  'Edit Chart Settings here': 'Edit Chart Settings here',
  Delete: 'Delete',
  'Mobile and Tablet browsers are not supported': 'Mobile and Tablet browsers are not supported',
  'Data Section': 'Data Section',
  'Build Charts': 'Build Charts',
  Search: 'Search',
  'Select Chart Type': 'Select Chart Type',
  yes: 'yes',
  no: 'no',
  'Are you sure! you want to reset chart.': 'Are you sure! you want to reset chart.',
  'Change Chart': 'Change Chart',
  Build: 'Build',
  Style: 'Style',
  'Drop columns here': 'Drop columns here',
  'Color Axis': 'Color Axis',
  'Double Y-Axis': 'Double Y-Axis',
  'Target Points': 'Target Points',
  Tooltips: 'Tooltips',
  'Default Filters': 'Default Filters',
  '+ Add Line': '+ Add Line',
  Label: 'Label',
  Done: 'Done',
  Remove: 'Remove',
  Visibility: 'Visibility',
  'Reset to defaults': 'Reset to defaults',
  'Number Format': 'Number Format',
  Compact: 'Compact',
  Currency: 'Currency',
  'Trailing Places': 'Trailing Places',
  'Date Format': 'Date Format',
  'Open Filters': 'Open Filters',
  'Insight Summary': 'Insight Summary',
  'Y-Axis (Left)': 'Y-Axis (Left)',
  'Y-Axis (Right)': 'Y-Axis (Right)',
  Font: 'Font',
  'Colour Palette': 'Colour Palette',
  Opacity: 'Opacity',
  Values: 'Values',
  Title: 'Title',
  'Plot Color': 'Plot Color',
  'Chart Line Colors': 'Chart Line Colors',
  'Chart Display': 'Chart Display',
  'Edit Colors': 'Edit Colors',
  Colors: 'Colors',
  'Drop Any Column': 'Drop Any Column',
  'Customize Legends': 'Customize Legends',
  Columns: 'Columns',
  'Select color': 'Select color',
  Gridlines: 'Gridlines',
  Horizontal: 'Horizontal',
  Vertical: 'Vertical',
  'Zoom Brush': 'Zoom Brush',
  'Card Image': 'Card Image',
  Axis: 'Axis',
  'Card Image Url': 'Card Image Url',
  'Invalid Image': 'Invalid Image',
  'Advance Options': 'Advance Options',
  'No DateTime Column Present': 'No DateTime Column Present',
  Compare: 'Compare',
  'Default Date Time Column': 'Default Date Time Column',
  'Data Prefix': 'Data Prefix',
  'Data Suffix': 'Data Suffix',
  'Text Suffix': 'Text Suffix',
  'KPI Target Points': 'KPI Target Points',
  Formatter: 'Formatter',
  'Time Frame': 'Time Frame',
  'Title Prefix': 'Title Prefix',
  'Add Target Point': 'Add Target Point',
  'Group Input': 'Group Input',
  'Target Point': 'Target Point',
  'Arc Point': 'Arc Point',
  'Advance Option': 'Advance Option',
  'Title Postfix': 'Title Postfix',
  Group: 'Group',
  'BackGround Color': 'BackGround Color',
  'Select Max Color': 'Select Max Color',
  'Select Min Color': 'Select Min Color',
  Magnitude: 'Magnitude',
  'Select Manually': 'Select Manually',
  'Select Color': 'Select Color',
  'Chart Name': 'Chart Name',
  'Chart Description': 'Chart Description',
  'Description of Chart': 'Description of Chart',
  TimeFrame: 'TimeFrame',
  All: 'All',
  Numerical: 'Numerical',
  Categorical: 'Categorical',
  'Date Time': 'Date Time',
  Geographical: 'Geographical',
  'Text Identifier': 'Text Identifier',
  'Num Identifier': 'Num Identifier',
  'Your chart configuration may alter!': 'Your chart configuration may alter!',
  'This is a': 'This is a',
  chart: 'chart',
  'More Column is not placeable': 'More Column is not placeable',
  'Changed to': 'Changed to',
  "This field doesn't accept multiple columns": "This field doesn't accept multiple columns",

  // chart Name with discription
  Bar: 'Bar',
  'Compare categorical data with vertical bars for easy value analysis.':
    'Compare categorical data with vertical bars for easy value analysis.',
  Line: 'Line',
  'Identify trends or patterns over time with connected data points for visual analysis.':
    'Identify trends or patterns over time with connected data points for visual analysis.',
  Area: 'Area',
  'Visualize data patterns and trends from the area.':
    'Visualize data patterns and trends from the area.',
  'Bar Horizontal': 'Bar Horizontal',
  'Compare categorical data from a horizontal perspective with horizontal bars.':
    'Compare categorical data from a horizontal perspective with horizontal bars.',
  Pie: 'Pie',
  'Understand relative distribution of categories with slices of a circular chart.':
    'Understand relative distribution of categories with slices of a circular chart.',
  Donut: 'Donut',
  'Represent proportion of categories with a circular chart with a central hole.':
    'Represent proportion of categories with a circular chart with a central hole.',
  'Bar Group': 'Bar Group',
  'Compare categories within each group using grouped bars for effective comparison.':
    'Compare categories within each group using grouped bars for effective comparison.',
  Card: 'Card',
  'Display key metrics or data points concisely and in a compact format.':
    'Display key metrics or data points concisely and in a compact format.',
  Funnel: 'Funnel',
  'Visualize progression and conversion rates of a process or customer journey.':
    'Visualize progression and conversion rates of a process or customer journey.',
  'Geo india': 'Geo india',
  'Map data to Indian Map for state-wise geographical analysis.':
    'Map data to Indian Map for state-wise geographical analysis.',
  'Geo USA': 'Geo USA',
  'Map data to the USA map for state-wise geographical analysis.':
    'Map data to the USA map for state-wise geographical analysis.',
  'Geo Canada': 'Geo Canada',
  'Map data to the Canada map for state-wise geographical analysis.':
    'Map data to the Canada map for state-wise geographical analysis.',
  'Geo World': 'Geo World',
  'Map data to the Global map for Global geographical analysis.':
    'Map data to the Global map for Global geographical analysis.',
  'Word cloud': 'Word cloud',
  'Highlight significant terms by displaying words in varying sizes based on frequency and importance.':
    'Highlight significant terms by displaying words in varying sizes based on frequency and importance.',
  'Scatter Plot': 'Scatter Plot',
  'Plot data points to explore the relationship and correlation between two numerical columns.':
    'Plot data points to explore the relationship and correlation between two numerical columns.',
  'KPI Meters': 'KPI Meters',
  'Assess performance against targets with a dial of key performance indicators.':
    'Assess performance against targets with a dial of key performance indicators.',
  'BAR Stack line': 'BAR Stack line',
  'Radial Line': 'Radial Line',
  'A Radial line chart represents time-series data by displaying lines originating from the center, with each line corresponding to a specific date and radiating outward to convey variations in values over time.':
    'A Radial line chart represents time-series data by displaying lines originating from the center, with each line corresponding to a specific date and radiating outward to convey variations in values over time.',
  Radar: 'Radar',
  'Compare different factors or dimensions by plotting multiple variables on a radial chart.':
    'Compare different factors or dimensions by plotting multiple variables on a radial chart.',
  'Tree Map': 'Tree Map',
  'Highlight the significance of categories using rectangles of varying sizes.':
    'Highlight the significance of categories using rectangles of varying sizes.',
  'Pivot Table': 'Pivot Table',
  'Summarizes and analyzes data based on different columns providing insights into trends and patterns.':
    'Summarizes and analyzes data based on different columns providing insights into trends and patterns.',
  'Heat Map': 'Heat Map',
  'A heat map chart visually displays data using color gradients to represent variations in intensity or values across a two-dimensional space.':
    'A heat map chart visually displays data using color gradients to represent variations in intensity or values across a two-dimensional space.',

  //Dashboard Settings
  'Open Dashboard Settings': 'Open Dashboard Settings',
  'Dashboard Settings': 'Dashboard Settings',
  'Edit Dashboard Settings here': 'Edit Dashboard Settings here',
  SECTIONS: 'SECTIONS',
  'SELECT CHARTS': 'SELECT CHARTS',
  Add: 'Add',
  'Change Name': 'Change Name',
  'Name of Dashboard': 'Name of Dashboard',
  'Description of Dashboard': 'Description of Dashboard',
  Dashboard: 'Dashboard',
  'Dashboard Name': 'Dashboard Name',
  'Dashboard Description': 'Dashboard Description',
  Section: 'Section',
  Header: 'Header',
  'Drag And Drop': 'Drag And Drop',
  'Charts From Sheet': 'Charts From Sheet',
  Lists: 'Lists',
  'No charts found': 'No charts found',
  'View More': 'View More',
  Hide: 'Hide',

  //Filters
  'Clear Filters': 'Clear Filters',
  'Select Any Filter': 'Select Any Filter',
  Min: 'Min',
  Max: 'Max',
  Select: 'Select',
  'No More Data Found': 'No More Data Found',

  //Report Settings
  'Report Settings': 'Report Settings',
  'Edit Report Settings here': 'Edit Report Settings here',
  'Edit Report': 'Edit Report',
  'Report Configuration': 'Report Configuration',
  'Report Name': 'Report Name',
  'Report Description': 'Report Description',
  'Report Schedule Frequency': 'Report Schedule Frequency',
  'Emails List': 'Emails List',
  'Report TimeFrame': 'Report TimeFrame',
  'Send Now': 'Send Now',
  'Send Email Now': 'Send Email Now',
  'Please fill out all required fields.': 'Please fill out all required fields.',
  'Report Send Successfully': 'Report Send Successfully',
  "It's not a valid Email": "It's not a valid Email",
  TOOLS: 'TOOLS',
  Text: 'Text',
  'Page Break': 'Page Break',
  'Report Wide Filter': 'Report Wide Filter',
  'Open Filter': 'Open Filter',
  'Input Text': 'Input Text',
  Chart: 'Chart',
  Insights: 'Insights',
  'Chart Filter': 'Chart Filter',
  'Chart/Insights': 'Chart/Insights',
  'Font-Align': 'Font-Align',

  //Table Head
  "Table's Head Rows": "Table's Head Rows",
  'Select Table': 'Select Table',
  'Type your query here...': 'Type your query here...',
  'Surprise Me': 'Surprise Me',
  Send: 'Send',
  'Please select a table from the dropdown.': 'Please select a table from the dropdown.',
  'Please write a query to get started.': 'Please write a query to get started.',
  'No chart found for the given query. Please try again with a different query.':
    'No chart found for the given query. Please try again with a different query.',
  'No Charts can be displayed! Please try again.': 'No Charts can be displayed! Please try again.',
  "Hello there! I'm Supista's chat AI bot.": "Hello there! I'm Supista's chat AI bot.",
  'Ready to create a chart based on your query. Please select a table from the dropdown and write your query to get started.':
    'Ready to create a chart based on your query. Please select a table from the dropdown and write your query to get started.',
  ' If you want you can': ' If you want you can',
  'Create new chart': 'Create new chart',
  '. After selecting a table from dropdown.': '. After selecting a table from dropdown.',
  'Apply filter on': 'Apply filter on',
  'Create New DashBoard': 'Create New DashBoard',
  'values are': 'values are',
  'range are': 'range are',
  'selected last': 'selected last',
  days: 'days',
  selected: 'selected',
  'selected date is': 'selected date is',

  // visx lib
  'Select Column': 'Select Column',
  'start from': 'start from',
  'duration of': 'duration of',
  'Growth Rate Analysis': 'Growth Rate Analysis',
  'Maximum Minimum Analysis': 'Maximum Minimum Analysis',
  'Pareto Rule Analysis': 'Pareto Rule Analysis',
  Period: 'Period',
  'Growth Rate': 'Growth Rate',
  'Maxmimum Analysis': 'Maxmimum Analysis',
  'Time Period': 'Time Period',
  'Performance Rate': 'Performance Rate',
  'Percentage Difference': 'Percentage Difference',
  'Minimum Analysis': 'Minimum Analysis',
  'Top Months': 'Top Months',
  Impact: 'Impact',
  Month: 'Month',
  Percentage: 'Percentage',
  'Bottom Months': 'Bottom Months',
  'Most And Least Words Count': 'Most And Least Words Count',
  'Most Used Words': 'Most Used Words',
  Words: 'Words',
  Count: 'Count',
  'Top And Bottom Performers List': 'Top And Bottom Performers List',
  'Top Performers': 'Top Performers',
  'Bottom Performers': 'Bottom Performers',
  'Statistical Analysis': 'Statistical Analysis',
  'Top 20% Performers': 'Top 20% Performers',
  'Bottom 20% Performers': 'Bottom 20% Performers',
  'Types of Analysis': 'Types of Analysis',
  'Average Value': 'Average Value',
  'Median Value': 'Median Value',
  Statistical: 'Statistical',
  'Show All': 'Show All',
  'Select Y-Axis': 'Select Y-Axis',
  'Choose Column': 'Choose Column',
  'Target Achieved': 'Target Achieved',
  'Target Achieving Probability': 'Target Achieving Probability',
  Days: 'Days',
  'to go': 'to go',
};

export default en;
