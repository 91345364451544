import { Draggable } from 'react-beautiful-dnd';
import { allDropableID, defaultReportType } from '../../../data/defaultSchema';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getStyling, updateChartSchemaInServer } from 'lib/reports-analytics/utils/helperFunction';
import { CircularProgress } from '@mui/material';
import Chart from 'lib/visx-lib';
import useDebounce from 'lib/reusable-components/hooks/useDebounce';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import { ReportStyle } from '../../reportStyle/ReportElemStyle';
import FilterDiv from 'lib/filters-lib/FilterDiv';
import './reportChartItem.scss';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import AnalysisIndex from 'lib/visx-lib/insights/analysisPopUp/AnalysisIndex';
import { getPlotData } from 'lib/reports-analytics/utils/dragableFunctions';
import { finalFilterStringFunction } from 'lib/reusable-components/reusableFunction/chartHealperReusableFunction';
const { reportId } = allDropableID;

export interface reportChartPorps {
  targetElem: any;
  stateProps: any;
  setTargetElem: Function;
  removeItem: Function;
  index: number;
  isReportPDF: boolean;
}
const ReportChartItem = ({
  targetElem,
  setTargetElem,
  removeItem,
  stateProps,
  index,
  isReportPDF,
}: reportChartPorps) => {
  const [isStyleDivOpen, setIsStyleDivOpen] = useState(false);
  const [isFilterOpen, setisFilterOpen] = useState(false);
  const { chartsData, reports, tableDataInSheetID, reportTimeFrame } = stateProps;
  const { chartID } = targetElem;
  const chartSchemaMemo = useMemo(() => chartsData[chartID], [reports]);

  const tableSchema = isReportPDF ? {} : tableDataInSheetID[targetElem?.sheetID] ?? {};

  const chartUpdateDebounce = useDebounce(chartSchemaMemo, 300);

  if (isReportPDF) {
    return (
      <ReportChartItemMain
        isStyleDivOpen={isStyleDivOpen}
        setIsStyleDivOpen={setIsStyleDivOpen}
        setTargetElem={setTargetElem}
        removeItem={removeItem}
        targetElem={targetElem}
        chartUpdateDebounce={chartUpdateDebounce}
        chartSchema={chartsData[chartID]}
        tableSchema={tableSchema}
        reportTimeFrame={reportTimeFrame}
        index={index}
        isFilterOpen={isFilterOpen}
        setisFilterOpen={setisFilterOpen}
        stateProps={stateProps}
      />
    );
  }
  return (
    <Draggable
      key={`${reportId.toString() + index.toString()}`}
      draggableId={`${reportId.toString() + index.toString()}`}
      index={index}
      isDragDisabled={isStyleDivOpen || isFilterOpen}
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{ ...provided?.draggableProps?.style, position: 'static' }}
            {...provided.dragHandleProps}
          >
            <ReportChartItemMain
              isStyleDivOpen={isStyleDivOpen}
              setIsStyleDivOpen={setIsStyleDivOpen}
              setTargetElem={setTargetElem}
              removeItem={removeItem}
              targetElem={targetElem}
              chartUpdateDebounce={chartUpdateDebounce}
              chartSchema={chartsData[chartID]}
              reportTimeFrame={reportTimeFrame}
              tableSchema={tableSchema}
              isFilterOpen={isFilterOpen}
              setisFilterOpen={setisFilterOpen}
              index={index}
              stateProps={stateProps}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

export default ReportChartItem;

export interface ReportChartItemMain {
  setIsStyleDivOpen: Function;
  isStyleDivOpen: boolean;
  setTargetElem: Function;
  isFilterOpen: boolean;
  setisFilterOpen: Function;
  removeItem: Function;
  targetElem: any;
  chartUpdateDebounce: any;
  chartSchema: any;
  tableSchema: any;
  reportTimeFrame: any;
  index: number;
  stateProps: any;
}
const ReportChartItemMain = ({
  setIsStyleDivOpen,
  isStyleDivOpen,
  setTargetElem,
  removeItem,
  targetElem,
  chartSchema,
  tableSchema,
  isFilterOpen,
  setisFilterOpen,
  reportTimeFrame,
  stateProps,
  index,
}: ReportChartItemMain) => {
  const { reportFilter } = stateProps;

  const [initialFilter, setInitialFilter]: any[] = useState(
    JSON.stringify(targetElem?.chartFilter ?? {})
  );
  const [filter, setFilter]: any[] = useState(targetElem?.chartFilter ?? {});

  const handleisFilterOpen = async (action: boolean) => {
    const filterInString = JSON.stringify(filter ?? {});

    if (action) {
      const filterInString = JSON.stringify(filter);
      setInitialFilter(filterInString);
      setisFilterOpen(action);
      return;
    }

    if (filterInString !== initialFilter) {
      const plotDataProps = {
        tableSchema,
        reportTimeFrame,
        chartSchema: { ...chartSchema, filters: filter },
        type: targetElem.type,
        filters: reportFilter,
      };
      const plotDataRes = await getPlotData(plotDataProps);
      if (plotDataRes.skip) {
        return;
      }

      const chartName = chartSchema?.name ?? '';

      const filterString = finalFilterStringFunction({
        filtersSchema: filter,
        chartName,
      });

      setTargetElem({
        ...targetElem,
        chartFilter: filter,
        plotData: plotDataRes.plotData,
        filterMsg: filterString,
      });
    }
    setisFilterOpen(action);
  };
  const handleFilter = (filters: any) => {
    setFilter(filters);
  };
  useEffect(() => {
    setInitialFilter(JSON.stringify(targetElem?.chartFilter ?? {}));
    setFilter(targetElem?.chartFilter ?? {});
  }, [index]);

  return (
    <div className='reportItem reportChartItem'>
      <div className='reportItemContainer '>
        <ReportStyle
          setIsStyleDivOpen={setIsStyleDivOpen}
          isStyleDivOpen={isStyleDivOpen}
          setTargetElem={setTargetElem}
          targetElem={targetElem}
          removeItem={removeItem}
          handleTopFilter={handleisFilterOpen}
          reportElemType={targetElem?.type}
          style={targetElem?.style}
          stateProps={stateProps}
        />
        {isFilterOpen ? (
          <div style={{ position: 'fixed', top: '0px', left: '0px', zIndex: 555 }}>
            <FilterDiv
              isPopUp={isFilterOpen}
              setIsPopUp={handleisFilterOpen}
              setChartFilter={handleFilter}
              chartFilter={filter}
              appID={chartSchema?.appID}
              sheetID={chartSchema.sheetID}
              allTableData={tableSchema?.data ?? []}
              isJoinedTable={tableSchema?.isJoinedTable ?? false}
            />
          </div>
        ) : null}
        {targetElem?.type === defaultReportType.charts ? (
          <>
            <ChartDiv
              chartSchema={{ ...chartSchema, filters: filter }}
              chartWidth={targetElem?.style?.chartWidth}
              targetElem={targetElem}
            />
          </>
        ) : null}
        {targetElem?.type === defaultReportType.insights ? (
          <>
            <InsightDiv
              chartSchema={{ ...chartSchema, filters: filter }}
              chartWidth={targetElem?.style?.chartWidth}
              targetElem={targetElem}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};
interface ChartDivProps {
  chartSchema: any;
  chartWidth: string;
  targetElem: any;
}
const ChartDiv = ({ chartWidth, chartSchema, targetElem }: ChartDivProps) => {
  const loadingScreen = false;
  if (loadingScreen) {
    return (
      <div className='chartPlotArea'>
        <div className='chartLoadingArea'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className='chartPlotArea'>
      <FinalChartDiv
        chartWidth={chartWidth}
        chartSchema={chartSchema}
        plotData={targetElem.plotData}
        targetElem={targetElem}
      />
    </div>
  );
};
interface FinalChartDivProps {
  chartWidth: string;
  chartSchema: any;
  plotData: any;
  targetElem: any;
}
export const FinalChartDiv = ({
  chartWidth,
  chartSchema,
  plotData,
  targetElem,
}: FinalChartDivProps) => {
  const chartWidthHeight =
    containerWidthAttributes[(chartWidth ?? 'HALF') as keyof typeof containerWidthAttributes];
  return (
    <div
      className='mainChart'
      style={{
        width: `${100 / chartWidthHeight.width}%`,
      }}
    >
      <Chart
        chartKey={chartSchema?.chartID}
        chartData={chartSchema ?? {}}
        dataToPlot={plotData ?? {}}
        cardWidth={'FULL'}
        isInsightChartSchema={true}
        isReport={true}
        chartHeight={chartWidthHeight.height}
      />
      {targetElem?.filterMsg ? <FilterMsg targetElem={targetElem} /> : null}
    </div>
  );
};
const InsightDiv = ({ targetElem }: ChartDivProps) => {
  const loadingScreen = false;
  if (loadingScreen) {
    return (
      <div className='chartPlotArea'>
        <div className='chartLoadingArea'>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (
    !(
      targetElem?.plotData?.dataSections &&
      targetElem?.plotData?.dataSections[0] &&
      targetElem?.plotData?.dataSections[0]?.length !== 0
    )
  ) {
    return (
      <div
        style={{
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          wordWrap: 'break-word',
          backgroundColor: '#ffffff',
          backgroundClip: 'border-box',
          borderRadius: '0.475rem',
          border: 0,
          color: '#7e8299',
        }}
        className='p-md-9 fs-4 fw-bolder'
      >
        No data to show with the current settings. Please use another settings!
      </div>
    );
  }
  return (
    <div className='chartPlotArea'>
      <div className='mainChart'>
        <AnalysisIndex mainData={targetElem?.plotData} isReport={true} />
        {targetElem?.filterMsg ? <FilterMsg targetElem={targetElem} /> : null}
      </div>
    </div>
  );
};
const FilterMsg = ({ targetElem }: { targetElem: any }) => {
  return (
    <div
      className='filterMsg'
      style={{
        backgroundColor: 'white',
        padding: ' .5rem 1rem',
        borderRadius: '6px',
        wordWrap: 'break-word',
      }}
    >
      <div
        style={{
          fontSize: '1.5rem',
          fontWeight: 600,
        }}
      >
        Filters
      </div>
      <div dangerouslySetInnerHTML={{ __html: targetElem?.filterMsg ?? '' }} />
    </div>
  );
};
interface FinalInsightDivProps {
  plotData: any;
  targetElem: any;
}
export const FinalInsightDiv = ({ plotData, targetElem }: FinalInsightDivProps) => {
  return (
    <div className='mainChart'>
      <AnalysisIndex mainData={plotData} isReport={true} />
      {targetElem?.filterMsg ? (
        <div className='filterMsg'>
          <div dangerouslySetInnerHTML={{ __html: targetElem?.filterMsg }} />
        </div>
      ) : null}
    </div>
  );
};
const containerWidthAttributes = {
  ONEFOURTH: {
    key: 'ONEFOURTH',
    width: 4,
    height: 200,
  },
  HALF: {
    key: 'HALF',
    width: 2,
    height: 250,
  },
  TWOTHIRD: {
    key: 'TWOTHIRD',
    width: 1.5,
    height: 300,
  },
  FULL: {
    key: 'FULL',
    width: 1,
    height: 300,
  },
};
