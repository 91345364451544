import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import Routes from './routing/Routes';

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  return (
    <BrowserRouter basename={basename}>
      <LayoutProvider>
        <Routes />
      </LayoutProvider>
    </BrowserRouter>
  );
};

export { App };
