import React, { useMemo, useCallback } from 'react';
import { Group } from '@visx/group';
import { Circle } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { defaultMargin as margin, defaultToolTipStyling } from '../../constants/constants';
import { GridRows, GridColumns } from '@visx/grid';

import { mls } from '../../multilanguagesupport/analytics';
import styles from '../../constants/styles.module.css';
import { AxisBottom, AxisLeft } from '@visx/axis';
import AxisLabelComponent from '../common/AxisLabelComponent';
import { voronoi } from '@visx/voronoi';
import visxScaleHelper from '../../helpers/visxScaleHelper';
import { getDefaultColorByIndex, getStyling, hexWithOpacity } from '../../helpers/colorHelper';
import { ScatterPlotToolTip } from '../ChartToolTip/ChartToolTip';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import { allDropableID } from 'lib/reusable-components/reusableData/defaultChartSchema';
import { getStrokDashArray } from 'lib/visx-lib/helpers/gridHelper';
import { widthHelper } from '../XYType/XYType';
const { yAxisId } = allDropableID
const x = (d) => d[0];
const y = (d) => d[1];
let tooltipTimeout;

const ScatterPlotChart = ({
  width,
  height,
  data,
  type,
  isGroupType,
  xAxisLabel,
  yAxisLabel,
  xCategory,
  yCategory,
  colorScale,
  theme,
  group,
  chartData,
}) => {
  // const margin = defaultMargin;
  const points = useMemo(() => {
    const p = [];
    for (let i = 0; i < data.x.length; i++) {
      p.push([data.x[i], data.y[i], group?.[i]]);
    }
    return p;
  }, [data.x, data.y, group]);

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = useMemo(
    () =>
      visxScaleHelper({
        domain: [Math.min(...points.map((x) => x[0])), Math.max(...points.map((x) => x[0]))],
        range: [0, xMax],
        clamp: true,
        category: xCategory,
      }),
    [xMax, points, xCategory]
  );

  const yScale = useMemo(
    () =>
      visxScaleHelper({
        domain: [
          Math.min(...points.map((y) => y[1] ?? 0)),
          Math.max(...points.map((y) => y[1] ?? 0)),
        ],
        range: [yMax, 0],
        clamp: true,
        category: yCategory,
      }),
    [yMax, points, yCategory]
  );

  const voronoiLayout = useMemo(
    () =>
      voronoi({
        x: (d) => {
          return xScale(x(d)) || 0.01;
        },
        // y: (d) => yScale(y(d)) ?? 0,
        y: (d) => yScale(y(d)) || 0.01,
        width,
        height,
      })(points),

    [width, height, xScale, yScale, points]
  );

  const actualWidth = widthHelper(data.x, type, width, margin);
  const dataNum = Math.floor((width / actualWidth) * data.x.length);
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  // event handlers
  const handleMouseOver = (event, datum) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const coords = localPoint(event.target.ownerSVGElement, event);
    const closest = voronoiLayout.find(coords.x - margin.left, coords.y - margin.top, 100);
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: closest?.data,
    });
  };
  const handleMouseLeave = useCallback(() => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const AxisBottomLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        bottomAxis
        valueStyle={theme.xAxisStyle?.valueStyle}
      />
    );
  };
  const AxisleftLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        valueStyle={theme.yAxisStyle?.valueStyle}
      />
    );
  };
  const yAxisValue = theme?.tooltip?.style.find((styleElem) => {
    if (styleElem?.axis === yAxisId) {
      return true;
    }
    return null;
  });
  const horizontalGridLines = chartData?.gridLines?.horizontal || {};
  const verticalGridLines = chartData?.gridLines?.vertical || {};

  const yAxisFormatter = theme?.yAxisStyle?.formatter
  const xAxisFormatter = theme?.xAxisStyle?.formatter
  const automaticOpacity = (chartData?.tooltip?.automaticOpacity ?? 100) / 100;

  return (
    <div>
      <svg width={width + 10} height={height} ref={containerRef}>
        <LinearGradient id='dots-pink' from='#351CAB' to='#621A61' rotate='-45' />

        {theme.xAxisStyle?.show ? (
          <AxisBottom
            top={yMax + margin.top}
            left={margin.left}
            scale={xScale}
            stroke={'#777d84'}
            // numTicks={8}
            tickLength={5}
            // tickStroke={defaultAxisColor}
            tickClassName={styles.bottomAxisTicks}
            tickFormat={((e) => numberFormatter({ type: { ...xAxisFormatter, compact: true }, value: e }))}
            numTicks={dataNum}

            // tickFormat={xCategory === 'CAT' ? categoryDateTickFormat : null}
            tickComponent={AxisBottomLabelWrapper}
            strokeWidth={2.5}
            tickStroke={'#adb5bd'}
            tickLineProps={{
              strokeWidth: 1.5,
            }}
            label={mls(xAxisLabel)}
            labelClassName={styles.chartLabel}
            labelOffset={30}
            labelProps={{
              ...getStyling(theme.xAxisStyle?.style),
              textAnchor: 'middle',
            }}
          />
        ) : null}
        {theme.yAxisStyle?.show ? (
          <AxisLeft
            stroke={'#adb5bd'}
            strokeWidth={1.5}
            top={margin.top}
            left={margin.left}
            scale={yScale}
            tickComponent={AxisleftLabelWrapper}
            tickClassName={styles.leftAxisTicks}
            tickStroke={'#adb5bd'}
            tickLineProps={{
              strokeWidth: 1.5,
            }}
            tickFormat={((e) => numberFormatter({ type: { ...yAxisFormatter, compact: true }, value: e }))}
            labelClassName={styles.chartLabel}
            label={mls(yAxisLabel)}
            labelOffset={40}
            labelProps={{
              ...getStyling(theme.yAxisStyle?.style),
              textAnchor: 'middle',
            }}
          />
        ) : null}
        <Group left={margin.left} top={margin.top}>
          <rect
            width={Math.max(xMax, 0)}
            height={yMax}
            rx={4}
            // fill='url(#dots-pink)'
            fill='white'
            onMouseMove={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onTouchMove={handleMouseOver}
            onTouchEnd={handleMouseLeave}
          />
          {horizontalGridLines?.show && (
            <GridRows
              scale={yScale}
              width={xMax}
              numTicks={horizontalGridLines?.count ?? 5}
              strokeWidth={horizontalGridLines?.width ?? 1}
              stroke={hexWithOpacity(
                horizontalGridLines?.color,
                horizontalGridLines?.opacity / 100
              )}
              strokeDasharray={getStrokDashArray(horizontalGridLines?.type)}
            // lineStyle={{}}
            />
          )}
          {verticalGridLines?.show && (
            <GridColumns
              scale={xScale}
              height={yMax}
              numTicks={verticalGridLines?.count ?? 5}
              strokeWidth={verticalGridLines.width ?? 1}
              stroke={hexWithOpacity(verticalGridLines?.color, verticalGridLines?.opacity / 100)}
              strokeDasharray={getStrokDashArray(verticalGridLines?.type)}
            // lineStyle={{}}
            />
          )}

          <Group pointerEvents='none'>
            {points.map((point, i) => {
              const arcColor = isGroupType
                ?
                colorScale(group?.[i]?.toString()) ?? hexWithOpacity(getDefaultColorByIndex(i), automaticOpacity)
                : theme?.colors[yAxisValue?.uniqueColumnName] ?? '#f6c431'
              return (
                < Circle
                  // onClick={tooltipOpen ? hideTooltip : handleMouseOver}
                  key={`point-${point[0]}-${i}`}
                  className='dot'
                  cx={xScale(x(point))}
                  cy={yScale(y(point))}
                  r={
                    tooltipData
                      ? tooltipData[0] === point[0] && tooltipData[1] === point[1]
                        ? 5
                        : 3
                      : 3
                  }
                  // fill={isGroupType ? colorScale(group?.[i]?.toString()) : '#f6c431'}
                  fill={
                    isGroupType
                      ? arcColor
                      : theme?.colors[yAxisValue?.uniqueColumnName] ?? '#f6c431'
                  }
                />
              )
            })}
          </Group>
        </Group>
      </svg>

      {tooltipOpen && (
        <ScatterPlotToolTip
          defaultToolTipStyling={defaultToolTipStyling}
          tooltipLeft={tooltipLeft}
          tooltipTop={tooltipTop}
          TooltipInPortal={TooltipInPortal}
          tooltipData={tooltipData}
          theme={theme}
          isGroupType={isGroupType}
          xAxisLabel={xAxisLabel}
          colorScale={colorScale}
          yAxisLabel={yAxisLabel}
          backgroundColor={defaultToolTipStyling.backgroundColor}
        />
      )}

      {/* {tooltipOpen && (
        <TooltipInPortal
          style={defaultToolTipStyling}
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          {tooltipData && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                ...getStyling({
                  font: theme.tooltip.font,
                  fontHeight: theme.tooltip.fontHeight,
                  textStyle: theme.tooltip.textStyle,
                  color: '#000',
                  opacity: 100,
                }),
              }}
            >
              {isGroupType && (
                <div
                  style={{
                    color: theme.tooltip.style[2].color,
                    opacity: theme.tooltip.style[2].opacity,
                  }}
                >
                  <strong>{tooltipData[2]}</strong>
                </div>
              )}
              <div
                style={{
                  color: theme.tooltip.style[0].color,
                  opacity: theme.tooltip.style[0].opacity,
                }}
              >
                {mls(xAxisLabel)}:{'\t'}
                <strong>{numberTickFormat(tooltipData[0])}</strong>
              </div>
              <div
                style={{
                  color: theme.tooltip.style?.[1]?.color,
                  opacity: theme.tooltip.style?.[1]?.opacity,
                }}
              >
                {mls(yAxisLabel)}:{'\t'}
                <strong>{numberTickFormat(tooltipData[1])}</strong>
              </div>
            </div>
          )}
        </TooltipInPortal>
      )} */}
    </div>
  );
};

export default ScatterPlotChart;
