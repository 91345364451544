import React, { useEffect, useRef, useState } from 'react';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { IoIosArrowDown } from 'react-icons/io';
import './dropDown.scss';

interface DropDownProps {
  object: any;
  dropDownValue: any;
  setdropDownValue: any;
  changeToReset?: boolean;
  className?: string;
  search?: boolean;
  inputPlaceHolder?: string;
  nameKey?: string;
  alternateName?: string | null;
  mainKey?: string;
  isChildernInsert?: boolean;
  ObjectInArray?: boolean;
  ObjectInObject?: boolean;
  children?: any;
  withIndex?: boolean;
  groupDropDown?: boolean;
  valueIsKey?: boolean;
  outerContainerRef?: React.RefObject<HTMLDivElement> | null;
}
const ObjectDropDown = ({
  object,
  dropDownValue,
  setdropDownValue,
  changeToReset = false,
  className = '',
  search = false,
  inputPlaceHolder = '',
  nameKey = 'name',
  alternateName = null,
  mainKey = 'key',
  isChildernInsert = false,
  ObjectInArray = false,
  ObjectInObject = false,
  children = null,
  withIndex = false,
  outerContainerRef = null,
  groupDropDown = false,
  valueIsKey = true,
}: DropDownProps) => {
  const alternateNameKey = alternateName ?? nameKey;
  const [dropDownData, setdropDownData] = useState({ isOpen: false, top: false, left: false });
  const [filteredData, setFilteredData] = useState(object);

  const defaultSearch = () => {
    if (groupDropDown) {
      let newName = '';
      Object.keys(filteredData).some((groupKey: string) => {
        newName =
          object[groupKey].options[dropDownValue]?.[nameKey] ??
          object[groupKey].options[dropDownValue]?.[alternateNameKey];
        // return true;
        return newName;
      });
      return newName || '';
    }
    if (ObjectInObject) {
      return object[dropDownValue]?.[nameKey] ?? object[dropDownValue]?.[alternateNameKey];
    }
    if (valueIsKey) {
      return object[dropDownValue] ?? object[dropDownValue];
    }
    return dropDownValue;
  };
  const [searchInput, setSearchInput] = useState(defaultSearch());

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleDropDown = () => {
    setdropDownData((currentData) => {
      if (!currentData.isOpen) {
        if (outerContainerRef && outerContainerRef?.current && wrapperRef.current) {
          const finalBottomSpace =
            outerContainerRef.current.scrollHeight - wrapperRef.current.offsetTop;
          const dropDownHeight = 200;
          if (dropDownHeight >= finalBottomSpace) {
            return { ...currentData, isOpen: !currentData.isOpen, top: true };
          } else {
            return { ...currentData, isOpen: !currentData.isOpen, top: false };
          }
        }
      }
      return { ...currentData, isOpen: !currentData.isOpen };
    });
  };
  const stopPropgation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setdropDownData((currentData) => ({ ...currentData, isOpen: true }));
  };
  interface handleSelectionProps {
    mainKey: any;
    groupKey?: string;
    index: number;
  }
  const handleSelection = ({ mainKey, index, groupKey = '' }: handleSelectionProps) => {
    let dropDownResponse: any;
    dropDownResponse = setdropDownValue(mainKey);

    if (withIndex) {
      dropDownResponse = setdropDownValue({ data: mainKey, index });
    }
    if (!dropDownResponse?.isError) {
      setFilteredData(object);
      handleDropDown();
      setSearchInput(() => {
        if (groupDropDown) {
          return (
            object[groupKey].options[mainKey]?.[nameKey] ??
            object[groupKey].options[mainKey]?.[alternateNameKey]
          );
        }

        if (ObjectInObject) {
          return object[mainKey]?.[nameKey] ?? object[mainKey]?.[alternateNameKey];
        }
        return mainKey?.[nameKey] ?? mainKey?.[alternateNameKey] ?? mainKey;
      });
    }
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    let keyword = e.target.value.toLowerCase();

    if (groupDropDown) {
      let newFilterObject: { [key: string]: any } = {};
      Object.keys(object).forEach((groupKey: string) => {
        const groupValue = object[groupKey];
        if (groupValue?.title !== null) {
          const isTitleFound = groupValue?.title?.toLowerCase()?.includes(keyword);
          if (isTitleFound) {
            newFilterObject = { ...newFilterObject, [groupKey]: groupValue };
            return;
          }
          Object.keys(groupValue.options).forEach((groupValueElemKey: string) => {
            const groupValueElem = groupValue.options[groupValueElemKey];
            if (groupValueElem?.[nameKey] !== null) {
              const groupValueNameFound = groupValueElem?.[nameKey]
                ?.toLowerCase()
                ?.includes(keyword);
              if (groupValueNameFound) {
                newFilterObject = {
                  ...newFilterObject,
                  [groupKey]: {
                    ...groupValue,
                    options: {
                      ...(newFilterObject[groupKey]?.options ?? {}),
                      [groupValueElemKey]: groupValueElem,
                    },
                  },
                };
                return;
              }
            }
            if (groupValueElem?.[alternateNameKey] !== null) {
              const groupValueAlternateNameFound = groupValueElem?.[alternateNameKey]
                ?.toLowerCase()
                ?.includes(keyword);
              if (groupValueAlternateNameFound) {
                newFilterObject = {
                  ...newFilterObject,
                  [groupKey]: {
                    ...groupValue,
                    options: {
                      ...(newFilterObject[groupKey]?.options ?? {}),
                      [groupValueElemKey]: groupValueElem,
                    },
                  },
                };
                return;
              }
            }
          });
        }
      });

      setFilteredData(newFilterObject);
      setSearchInput(e.target.value);
      return;
    }
    if (ObjectInObject) {
      const filtered: any = Object.values(object).filter(function (str: any) {
        if (str?.[nameKey] !== null) {
          return str?.[nameKey]?.toLowerCase()?.includes(keyword);
        }
        if (str?.[alternateNameKey] !== null) {
          return str?.[alternateNameKey]?.toLowerCase()?.includes(keyword);
        }
        return str.toLowerCase()?.includes(keyword);
      });
      const nestedObject: any = {};
      for (const obj of filtered) {
        nestedObject[obj?.[mainKey]] = obj;
      }
      setFilteredData(nestedObject);
      setSearchInput(e.target.value);
      return;
    }
    // const filtered = object.filter(function (str: any) {
    //   if (str?.[nameKey] !== null) {
    //     return str?.[nameKey].toLowerCase().includes(keyword);
    //   }
    //   return str.toLowerCase().includes(keyword);
    // });
    // setFilteredData(filtered);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSearchInput(defaultSearch());
        setFilteredData(object);
        handleDropDown();
      }
    }
    // if (wrapperRef.current) {
    //   setDropDownWidth(wrapperRef?.current?.getBoundingClientRect()?.width ?? 0);
    // }
    if (dropDownData.isOpen) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, dropDownData]);

  const isSelected = (element: any, dropdown: any) => {
    if (!isNaN(Number(element)) && !isNaN(Number(dropdown))) {
      return Number(element) === Number(dropdown) ? 'dropDownItems selected' : 'dropDownItems';
    }
    return element === dropdown ? 'dropDownItems selected' : 'dropDownItems';
  };

  useUpdateEffect(() => {
    setSearchInput(defaultSearch());
  }, [changeToReset]);
  useUpdateEffect(() => {
    setFilteredData(object);
    setSearchInput(defaultSearch());
  }, [object]);
  useUpdateEffect(() => {
    setSearchInput(defaultSearch());
  }, [dropDownValue]);
  return (
    <div className={`CustomDropdown ${className}`} ref={wrapperRef}>
      <div
        className={search ? 'mainTitle searchDiv' : 'mainTitle'}
        onClick={() => handleDropDown()}
      >
        {isChildernInsert ? (
          <>{children}</>
        ) : (
          <>
            {search ? (
              <input
                className='searchInput'
                type='text'
                placeholder={inputPlaceHolder}
                value={searchInput}
                onClick={(e) => stopPropgation(e)}
                onChange={(e) => {
                  handleFilter(e);
                }}
              />
            ) : (
              <div className='textDiv' title={defaultSearch()}>
                {defaultSearch() || inputPlaceHolder}
              </div>
            )}
            <div className={dropDownData.isOpen ? `dropDownarrow open` : 'dropDownarrow'}>
              <IoIosArrowDown />
            </div>
            {/* <img className={dropDownData.isOpen ? ' open' : ''} src={arrowDropDownIcon} alt='' /> */}
          </>
        )}
      </div>
      <div
        className={`dropDownArray ${dropDownData.isOpen ? 'open' : ''} ${
          dropDownData.top ? 'upperDropDown' : ''
        }`}
      >
        {groupDropDown ? (
          <GroupDropDown
            filteredData={filteredData}
            isSelected={isSelected}
            handleSelection={handleSelection}
            dropDownValue={dropDownValue}
            nameKey={nameKey}
            alternateNameKey={alternateNameKey}
          />
        ) : (
          React.Children.toArray(
            Object.keys(filteredData).map((key: any, index: number) => {
              return (
                <div
                  className={isSelected(key, dropDownValue)}
                  onClick={() => handleSelection({ mainKey: key, index })}
                  title={
                    ObjectInObject
                      ? object[key]?.[nameKey] ?? object[key]?.[alternateNameKey]
                      : object[key]
                  }
                >
                  {ObjectInObject
                    ? object[key]?.[nameKey] ?? object[key]?.[alternateNameKey]
                    : object[key]}
                </div>
              );
            })
          )
        )}
        {Object.keys(filteredData).length === 0 ? (
          <div className='dropDownItems'>No Options</div>
        ) : null}
      </div>
    </div>
  );
};

export default ObjectDropDown;

interface groupDropDownProps {
  filteredData: any;
  isSelected: Function;
  handleSelection: Function;
  dropDownValue: any;
  nameKey: string;
  alternateNameKey: string;
}
const GroupDropDown = (groupDropDownProps: groupDropDownProps) => {
  const { filteredData, isSelected, handleSelection, dropDownValue, nameKey, alternateNameKey } =
    groupDropDownProps;
  return (
    <div className='nestedDropDownGroupWrapperOuter'>
      {React.Children.toArray(
        Object.keys(filteredData).map((groupKey) => (
          <GroupDropDownInner
            groupKey={groupKey}
            filteredData={filteredData}
            isSelected={isSelected}
            handleSelection={handleSelection}
            dropDownValue={dropDownValue}
            nameKey={nameKey}
            alternateNameKey={alternateNameKey}
          />
        ))
      )}
    </div>
  );
};

interface groupInnerDropDownProps {
  filteredData: any;
  isSelected: Function;
  handleSelection: Function;
  dropDownValue: any;
  nameKey: string;
  alternateNameKey: string;
  groupKey: string;
}
const GroupDropDownInner = (groupInnerDropDownProps: groupInnerDropDownProps) => {
  const {
    filteredData,
    isSelected,
    handleSelection,
    dropDownValue,
    nameKey,
    alternateNameKey,
    groupKey,
  } = groupInnerDropDownProps;
  const [isOpen, setIsOpen] = useState(true);
  const handleDivOpen = () => {
    setIsOpen((currentData) => !currentData);
  };
  return (
    <div
      className={`nestedDropDownGroupWrapper ${isOpen ? 'open' : ''}`}
      title={filteredData[groupKey]?.title}
    >
      <div className='nestedDropDownGroupTitle' onClick={() => handleDivOpen()}>
        <div className='groupDropDownTitle'>{filteredData[groupKey]?.title}</div>
        <div className='groupDropDownTitleArrow'>
          <IoIosArrowDown />
        </div>
      </div>
      <div className='nestedItemsContainer'>
        {React.Children.toArray(
          Object.keys(filteredData[groupKey]?.options).map((optionsKey, index) => {
            return (
              <div
                // className='nestedItems'
                className={isSelected(optionsKey, dropDownValue)}
                onClick={() => handleSelection({ mainKey: optionsKey, index: index, groupKey })}
                title={
                  filteredData[groupKey].options[optionsKey]?.[nameKey] ??
                  filteredData[groupKey].options[optionsKey]?.[alternateNameKey]
                }
              >
                {filteredData[groupKey].options[optionsKey]?.[nameKey] ??
                  filteredData[groupKey].options[optionsKey]?.[alternateNameKey]}
                {/* {stringToBold(filteredData[groupKey].options[optionsKey]?.name)} */}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
