import React, { useEffect, useRef, useState } from 'react';
import { reportToolsPorps } from '../ReportWrapper';
import { Draggable } from 'react-beautiful-dnd';
import { allDropableID } from '../../../data/defaultSchema';

import { getStyling } from '../../../utils/helperFunction';
import { ReportStyle } from '../../reportStyle/ReportElemStyle';
import { mls } from 'lib/multilanguagesupport';

const { reportId } = allDropableID;
const LineTools = ({
  targetElem,
  setTargetElem,
  removeItem,
  index,
  isReportPDF,
  stateProps,
}: reportToolsPorps) => {
  const [isStyleDivOpen, setIsStyleDivOpen] = useState(false);

  if (isReportPDF) {
    return <FinalLineDiv targetElem={targetElem} />;
  }
  return (
    <Draggable
      key={`${index}`}
      draggableId={`${reportId.toString() + index.toString()}`}
      index={index}
      isDragDisabled={isStyleDivOpen}
    >
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className='reportItem'>
              <div className='reportItemContainer reportLineTool'>
                <ReportStyle
                  setIsStyleDivOpen={setIsStyleDivOpen}
                  isStyleDivOpen={isStyleDivOpen}
                  setTargetElem={setTargetElem}
                  targetElem={targetElem}
                  removeItem={removeItem}
                  reportElemType={targetElem?.reportToolType}
                  style={targetElem?.style}
                  stateProps={stateProps}
                />
                <FinalLineDiv targetElem={targetElem} />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};
export const FinalLineDiv = ({ targetElem }: { targetElem: any }) => {
  const style = getStyling(targetElem?.style);
  return <hr style={{ ...style, borderTop: `solid 1px ${style.color}` }} />;
};
interface InputTextPorps {
  targetElem: any;
  index: number;
  setTargetElem: Function;
  removeItem: Function;
  isReportPDF: boolean;
  stateProps: any;
}
const InputTextTools = ({
  targetElem,
  setTargetElem,
  removeItem,
  index,
  isReportPDF,
  stateProps,
}: InputTextPorps) => {
  const [inputToggleOpen, setInputToggleOpen] = useState(false);
  const [isStyleDivOpen, setIsStyleDivOpen] = useState(false);

  if (isReportPDF) {
    return <FinalReportText targetElem={targetElem} />;
  }

  return (
    <Draggable
      key={`${index}`}
      draggableId={`${reportId.toString() + index.toString()}`}
      index={index}
      isDragDisabled={inputToggleOpen || isStyleDivOpen}
    >
      {(provided, snapshot) => {
        const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
          setTargetElem({ ...targetElem, input: e.target.value });
        };

        const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            setInputToggleOpen(false);
          }
        };

        const style = getStyling(targetElem?.style);
        return (
          <div
            className='reportItem reportTools'
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{ ...provided?.draggableProps?.style, position: 'static' }}
            {...provided.dragHandleProps}
          >
            <div
              className='reportItemContainer inputTextTool'
              onDoubleClick={() => setInputToggleOpen(true)}
            >
              <ReportStyle
                setInputToggleOpen={setInputToggleOpen}
                inputToggleOpen={inputToggleOpen}
                setIsStyleDivOpen={setIsStyleDivOpen}
                isStyleDivOpen={isStyleDivOpen}
                setTargetElem={setTargetElem}
                targetElem={targetElem}
                removeItem={removeItem}
                reportElemType={targetElem?.reportToolType}
                style={targetElem?.style}
                stateProps={stateProps}
              />
              {inputToggleOpen ? (
                <input
                  type='text'
                  placeholder={mls('Input Text')}
                  value={targetElem?.input}
                  onChange={handleInput}
                  onKeyPress={handleEnter}
                  style={style}
                  className='mainInputTextTool'
                />
              ) : targetElem?.input ? (
                <FinalReportText targetElem={targetElem} />
              ) : (
                <div className='inputTextItem noTextFound'>{mls('Input Text')}</div>
              )}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};
export const FinalReportText = ({ targetElem }: { targetElem: any }) => {
  const style = getStyling(targetElem?.style);
  return (
    <div className='inputTextItem' style={style}>
      {targetElem?.input}
    </div>
  );
};
interface PageBreakPorps {
  targetElem: any;
  index: number;
  setTargetElem: Function;
  removeItem: Function;
  isReportPDF: boolean;
}
const PageBreakTools = ({
  targetElem,
  setTargetElem,
  removeItem,
  index,
  isReportPDF,
  stateProps,
}: InputTextPorps) => {
  const [inputToggleOpen, setInputToggleOpen] = useState(false);
  const [isStyleDivOpen, setIsStyleDivOpen] = useState(false);
  if (isReportPDF) {
    return <FinalPageBreakTools targetElem={targetElem} />;
  }
  return (
    <Draggable
      key={`${index}`}
      draggableId={`${reportId.toString() + index.toString()}`}
      index={index}
    >
      {(provided, snapshot) => {
        const style = getStyling(targetElem?.style);
        return (
          <div
            className='reportItem reportTools'
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{ ...provided?.draggableProps?.style, position: 'static' }}
            {...provided.dragHandleProps}
          >
            <div
              className='reportItemContainer pageBreak'
              onDoubleClick={() => setInputToggleOpen(true)}
            >
              <ReportStyle
                setInputToggleOpen={setInputToggleOpen}
                inputToggleOpen={inputToggleOpen}
                setIsStyleDivOpen={setIsStyleDivOpen}
                isStyleDivOpen={isStyleDivOpen}
                setTargetElem={setTargetElem}
                targetElem={targetElem}
                removeItem={removeItem}
                reportElemType={targetElem?.reportToolType}
                style={targetElem?.style}
                isOption={false}
                stateProps={stateProps}
              />
              <div>
                - - - - - - - - - - - - - - - - - - - - - {targetElem?.title} - - - - - - - - - - -
                - - - - - - - - - -
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export const FinalPageBreakTools = ({ targetElem }: { targetElem: any }) => {
  return <div style={{ pageBreakBefore: 'always' }}></div>;
};
export { LineTools, InputTextTools, PageBreakTools };
