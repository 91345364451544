import React, { useState, useRef, useMemo, useEffect, memo, useCallback } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import { STARTING_DATA_LENGTH, defaultToolTipStyling } from '../../constants/constants';
import styles from '../../constants/styles.module.css';
import { mls } from '../../multilanguagesupport/analytics';
import { lightTheme } from '@visx/xychart';
import * as d3 from 'd3';

import { XYChart, Tooltip } from '@visx/xychart';
import { scaleBand, scaleOrdinal } from '@visx/scale';
import { AxisBottom } from '@visx/axis';
import AxisLabelComponent from '../common/AxisLabelComponent';
import { Line } from '@visx/shape';
import MainXYChart from './MainXYChart';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';
import XYChartAxis from '../common/XYChartAxis';
import { getStyling } from '../../helpers/colorHelper';
import { XYMultiAxisToolTip } from '../ChartToolTip/ChartToolTip';
import { Text } from '@visx/text';

export const widthHelper = (x, type, chartWidth, margin) => {
  // Effective screen width
  const lineAreaWidth = 50;
  const effectiveWidth = chartWidth;

  // In case of a horizontal bar chart, width is always fine

  // Number of items to plot
  const plotNum = x.length;
  // Padding between each plot
  const padding = 0.1;

  // Find the width of each node based on chart type
  const nodeWidth = lineAreaWidth;

  // Calculate value of padding
  const paddingVal = nodeWidth * padding;
  const reqWidth = (nodeWidth + paddingVal * 2) * plotNum + margin.left + margin.right;

  // Return maximum of screenWidth or required Width
  return Math.max(reqWidth, effectiveWidth);
};

const accessors = {
  xAccessor: (d) => d?.x[0],
  yAccessor: (d) => d?.y,
};

const brushMargin = {
  top: 0,
  bottom: 10,
  left: 60,
  right: 20,
};

const margin = { left: 60, top: 20, bottom: 40, right: 20 };

const capitalizeOnlyFirst = (s) => {
  s = s.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const getRequiredData = (dataToPlot, xDepth) => {
  const data = [];
  const xRangeBands = [];
  const xLabels = [];
  for (let i = 0; i < xDepth - 1; i++) {
    xLabels.push([]);
    xRangeBands.push([]);
  }
  if (xDepth === 1) xLabels.push([]);
  const getData = (flatData, data, depth) => {
    data.forEach((obj) => {
      if (!obj.data) {
        const arr = [];
        obj.x.forEach((xd, i) => {
          arr.push({
            x: [xd + '-' + flatData.length.toString() + '-' + i.toString()],
            y: obj.y.map((y) => {
              const yKey = Object.keys(y)[0];
              return {
                [yKey]: {
                  data: [y[yKey].data[i]],
                },
              };
            }),
            parents: xLabels.map((l) => l[l.length - 1]),
          });
        });
        flatData.push(...arr);
        for (let i = 0; i < xDepth - 1; i++) {
          xRangeBands[i].push(xLabels[i].length - 1);
        }
      } else {
        xLabels[depth].push(obj.label);
        getData(flatData, obj.data, depth + 1);
      }
    });
  };
  getData(data, dataToPlot, 0);
  return { data, xRangeBands, xLabels };
};

const XYMultiAxisType = ({
  chartData,
  dataToPlot: plotData,
  optionData,
  useChartSettingsButton,
  isProd,
  updateFilter,
  cardRef,
  parentSize,
  chartHeight,
  theme,
  columnLabelMap,
}) => {
  const { width } = useDivDimensions(cardRef);
  const dataToPlot = useMemo(() => plotData?.plotData || [], [plotData]);
  const type = chartData.chartType;
  const height = chartHeight;
  const isBrush = chartData.isZoomBrush === false ? false : true;

  // const { xAxis, yAxis } = chartData;
  const xAxis = useMemo(() => chartData.xAxis, [chartData.xAxis]);
  const yAxis = useMemo(() => chartData.yAxis, [chartData.yAxis]);

  // Method to only capitalize first letter

  const xOperations = capitalizeOnlyFirst(xAxis[0]?.operations?.type || '');

  const xAxisLabel =
    theme.xAxisStyle?.label ||
    (chartData.xAxisLabel === ''
      ? xOperations === ''
        ? xAxis[0].label
        : `${xOperations}[ ${xAxis[0].label} ]`
      : chartData.xAxisLabel);

  const yOperations = capitalizeOnlyFirst(yAxis[0]?.operations?.type || '');
  const yAxisLabel =
    theme.yAxisStyle?.label ||
    (chartData.yAxisLabel === ''
      ? yOperations === ''
        ? yAxis[0].label
        : `${yOperations}[ ${yAxis[0].label} ]`
      : chartData.yAxisLabel);
  const xAxisLabels = useMemo(() => {
    const axisLabels = xAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, xAxis]);

  const yAxisLabels = useMemo(() => {
    const axisLabels = yAxis.map((obj) => obj.uniqueColumnName || obj.column);
    const styleLabels = {};
    theme.tooltip.style.forEach((obj) => {
      const op = capitalizeOnlyFirst(obj.operations?.type || '');
      const value = op === '' ? obj.label : `${op}(${obj.label})`;
      const key = obj.uniqueColumnName || obj.column;
      if (axisLabels.includes(key)) styleLabels[key] = value;
    });
    return styleLabels;
  }, [theme.tooltip.style, yAxis]);

  const xDepth = Object.keys(xAxisLabels).length;

  const {
    data: allData,
    xRangeBands,
    xLabels,
  } = useMemo(() => getRequiredData(dataToPlot, xDepth), [dataToPlot, xDepth]);
  const [data, setData] = useState(isBrush ? allData.slice(-STARTING_DATA_LENGTH) : allData);
  const actualWidth = widthHelper(data, type, width, margin);
  const dataNum = Math.floor((width / actualWidth) * data.length);
  const [brushPosition, setBrushPosition] = useState([
    data[data.length - dataNum],
    data[data.length - 1],
  ]);

  const [filteredData, setFilteredData] = useState(data);
  const [filteredXRangeBands, setFilteredXRangeBands] = useState(xRangeBands);
  const [filteredXLabels, setFilteredXLabels] = useState(xLabels);

  const extraAxisHeight = xDepth * 20;
  const topChartHeight = isBrush ? height * 0.88 - extraAxisHeight : height - extraAxisHeight;
  const bottomChartHeight = height - topChartHeight - extraAxisHeight || 0;

  const xScales = useMemo(() => {
    const scales = [];
    filteredXLabels.forEach((xls, j) => {
      if (j === xDepth - 1) return;
      let currentW = 0;
      scales.push(
        scaleOrdinal({
          domain: xls,
          range: xls.map((x, i) => {
            const freq = filteredXRangeBands[j].filter((y) => y === i).length;
            const per = (width - margin.left - margin.right) / filteredData.length;
            const w = per * freq;
            currentW += w;
            return currentW - w + w / 2;
          }),
        })
      );
    });
    return scales;
  }, [filteredXLabels, filteredXRangeBands, filteredData, width, xDepth]);
  // let currentW = 0;
  const currentW = useRef(0);
  const xBrushMax = width - brushMargin.left - brushMargin.right;
  const brushXScale = useMemo(
    () =>
      scaleBand({
        domain: data.map((d) => d.x[0]),
        range: [margin.left, xBrushMax + margin.left],
        padding: 0.3,
      }),
    [data, xBrushMax]
  );
  const miniRef = useRef(null);

  const brushed = useCallback(
    function ({ selection }) {
      if (!selection) return;
      const [x0, x1] = selection;
      const newFilData = data.filter((d, i) => {
        if (
          x0 <= brushXScale(accessors.xAccessor(d)) &&
          brushXScale(accessors.xAccessor(d)) <= x1
        ) {
          return true;
        }
        return false;
      });
      setFilteredData(newFilData);
      if (x0 - brushMargin.left <= width * 0.1 && data.length !== allData.length) {
        const n = Math.min(STARTING_DATA_LENGTH, allData.length - data.length);
        const newData = allData.slice(-data.length - n);
        setData(newData);

        setBrushPosition([newFilData[0], newFilData[newFilData.length - 1]]);
        return;
      }
      const newRangeBands = [];
      const newLabels = [];
      for (let i = 0; i < xDepth - 1; i++) {
        newRangeBands.push([]);
        newLabels.push([]);
      }
      if (xDepth === 1) {
        newRangeBands.push([]);
        newLabels.push([]);
      }
      newFilData.forEach((d) => {
        d.parents.forEach((p, i) => {
          if (newLabels.length === 0 || newLabels[i][newLabels[i].length - 1] !== p) {
            newLabels[i].push(p);
          }
          newRangeBands[i].push(newLabels[i].length - 1);
        });
      });
      setFilteredXLabels(newLabels);
      setFilteredXRangeBands(newRangeBands);
    },
    [data, xDepth, brushXScale, allData, width]
  );
  const brushRef = useRef(null);

  useEffect(() => {
    if (!isBrush) {
      return;
    }

    let svg = d3.select(miniRef.current);
    const brush = d3
      .brushX()
      .extent([
        [brushMargin.left, brushMargin.top],
        [
          brushMargin.left + xBrushMax || 0,
          bottomChartHeight - brushMargin.bottom < 0 ? 0 : bottomChartHeight - brushMargin.bottom,
        ],
      ])
      .on('brush', brushed)
      .on('end', brushed);
    brushRef.current = brush;

    const defaultSelection = [
      brushXScale(accessors.xAccessor(brushPosition[0])),
      brushXScale(accessors.xAccessor(brushPosition[1])) + brushXScale.bandwidth(),
    ];

    const gb = svg.append('g').call(brush).call(brush.move, defaultSelection);
    gb.selectAll('.selection')
      .attr('fill', 'steelblue')
      .attr('stroke', '#009ef7')
      .attr('fill-opacity', '20%')
      .attr('stroke-width', 1);

    return () => {
      gb.remove();
    };
  }, [data, bottomChartHeight, brushXScale, brushed, isBrush, xBrushMax, dataNum, brushPosition]);

  const { Legend, colorScale } = useOrdinalLegend({
    legendGlyphSize: 20,
    colorScale: theme.colors,
    labels: columnLabelMap,
  });

  // Wrapper component for bottom axis using AxisLabelComponent
  const AxisBottomLabelWrapper = ({ x, y, formattedValue }) => {
    return (
      <AxisLabelComponent
        x={x}
        y={y}
        formattedValue={formattedValue}
        bottomAxis
        valueStyle={theme.xAxisStyle.valueStyle}
      />
    );
  };
  return (
    <>
      <>
        {data ? (
          <>
            <XYChart
              theme={{ ...lightTheme, colors: colorScale.range() }}
              height={topChartHeight + extraAxisHeight}
              width={width}
              xScale={{
                type: 'band',
                paddingInner: 0.3,
              }}
              yScale={{
                type: 'linear',
              }}
              margin={{ ...margin, bottom: extraAxisHeight + margin.bottom }}
            >
              {/* <Axis
                tickComponent={AxisBottomLabelWrapper}
                numTicks={dataNum}
                orientation='bottom'
                tickFormat={(d) => d.split('-')[0]}
                tickClassName={styles.bottomAxisTicks}
                tickStroke={'#adb5bd'}
                labelClassName={styles.chartLabel}
                stroke={'#777d84'}
              />
              <Axis
                orientation='left'
                tickComponent={AxisleftLabelWrapper}
                labelClassName={styles.chartLabel}
                tickClassName={styles.leftAxisTicks}
                strokeWidth={1.5}
                stroke={'#adb5bd'}
                tickStroke={'#adb5bd'}
              /> */}
              <XYChartAxis
                xStyling={theme.xAxisStyle}
                yStyling={theme.yAxisStyle}
                dataNum={dataNum}
                xAxisProps={{
                  tickFormat: (d) => d.split('-')[0],
                }}
                yAxisLabel={yAxisLabel}
                // xAxisLabel={xAxisLabel}
              />

              <MainXYChart data={filteredData} type={type} />
              {theme.xAxisStyle.show ? (
                <>
                  {[...xScales].reverse().map((xS, i) => {
                    if (i > 2) {
                      return <></>;
                    }
                    return (
                      <AxisBottom
                        key={i}
                        top={(i + 1) * 30 + topChartHeight - margin.bottom}
                        left={margin.left}
                        scale={xS}
                        stroke={'#777d84'}
                        tickLength={3}
                        tickClassName={styles.bottomAxisTicks}
                        tickStroke={'#adb5bd'}
                        // label={i===xScales.length-1 ?}
                        labelClassName={styles.chartLabel}
                        labelOffset={20}
                        numTicks={dataNum}
                        tickComponent={AxisBottomLabelWrapper}
                        strokeWidth={2}
                        hideAxisLine
                        hideTicks
                        labelProps={{
                          ...getStyling(theme.xAxisStyle?.style),
                          textAnchor: 'middle',
                        }}
                      />
                    );
                  })}
                  {xScales?.length <= 2 ? (
                    <Text
                      x={(width + 30) / 2}
                      // y={
                      //   -Math.sin(goalAngle * (Math.PI / 180)) *
                      //   (outerRadius - (outerRadius / 100) * -3)
                      // }
                      y={(xScales?.length + 1) * 30 + topChartHeight - margin.bottom}
                      // textAnchor={goalAngle >= 90 ? 'start' : 'end'}
                      // fill='#9ca3af'
                      // fontSize={`${(1 * targetElem.fontHeight) / 10}rem`}
                      style={{
                        ...getStyling(theme.xAxisStyle?.style),
                      }}
                      textAnchor='middle'
                      verticalAnchor='middle'
                    >
                      {xAxisLabel}
                    </Text>
                  ) : null}
                </>
              ) : null}

              {filteredXLabels[0].slice(0, -1).map((label, i) => {
                const freq = filteredXRangeBands[0]?.filter((y) => y === i).length;
                const per = (width - margin.left - margin.right) / filteredData.length;
                const w = per * freq;
                currentW.current += w;
                return (
                  <Line
                    key={i}
                    fill='black'
                    from={{ x: currentW.current + margin.left, y: margin.top }}
                    strokeWidth={2}
                    to={{
                      x: currentW.current + margin.left,
                      y: String(extraAxisHeight + topChartHeight - margin.top),
                    }}
                    stroke={'#adb5bd'}
                    strokeDasharray='3,3'
                  />
                );
              })}
              {/* <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                // showDatumGlyph
                style={defaultToolTipStyling}
                renderTooltip={({ tooltipData, colorScale }) => {
                  const { datum } = tooltipData.nearestDatum;
                  return (
                    <div
                      style={{
                        fontWeight: 'normal',
                      }}
                    >
                      <div style={{ fontSize: '14px' }}>
                        <div style={{ marginBottom: 6 }}>
                          {Object.values(xAxisLabels).map((val, i) => {
                            return (
                              <div key={i}>
                                <strong>{val}:</strong>{' '}
                                {datum.parents[i] || accessors.xAccessor(datum).split('-')[0]}
                              </div>
                            );
                          })}
                        </div>
                        {datum.y.map((obj, i) => {
                          const key = Object.keys(obj)[0];
                          const tStyling = theme.tooltip.style.find(
                            (item) => (item.uniqueColumnName || item.column) === key
                          );

                          const label = yAxisLabels[key];
                          const val = obj[key].data[0];
                          if (!tStyling.show) return null;
                          return (
                            <div
                              style={{
                                paddingBottom: '3px',
                                color: colorScale(key),
                                ...getStyling({
                                  color: theme.colors[key],
                                  textStyle: theme.tooltip.textStyle,
                                  fontHeight: theme.tooltip.fontHeight,
                                  opacity: tStyling?.opacity,
                                }),
                              }}
                              key={key}
                            >
                              <strong style={{ color: colorScale(i + 1) }}>{label}</strong>
                              <span>{`: ${val}`}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                }}
              /> */}
              <XYMultiAxisToolTip
                defaultToolTipStyling={defaultToolTipStyling}
                accessors={accessors}
                theme={theme}
                colorScale={colorScale}
                xAxisLabels={xAxisLabels}
                yAxisLabels={yAxisLabels}
                backgroundColor={defaultToolTipStyling.backgroundColor}
              />
            </XYChart>
            {isBrush && (
              <svg height={bottomChartHeight || 0} width={width} ref={miniRef}>
                <MiniChart
                  bottomChartHeight={bottomChartHeight}
                  width={width}
                  data={data}
                  type={type}
                  colors={colorScale.range()}
                />
              </svg>
            )}
            {/* </svg> */}
          </>
        ) : (
          <div
            className='fw-bolder my-1 fs-4 d-flex justify-content-center'
            style={{ color: '#7e8299' }}
          >
            {mls('No data to show with the current settings. Please use another settings!')}
          </div>
        )}
        <Legend />
      </>
    </>
  );
};

export default XYMultiAxisType;

const MiniChart = memo(({ bottomChartHeight, width, data, type, colors }) => {
  return (
    <XYChart
      theme={{ ...lightTheme, colors: colors }}
      height={bottomChartHeight}
      width={width}
      xScale={{
        type: 'band',
        paddingInner: 0.3,
      }}
      yScale={{
        type: 'linear',
      }}
      margin={brushMargin}
    >
      <MainXYChart data={data} type={type} />
    </XYChart>
  );
});
