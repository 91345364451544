// import { categorical, timeSeries } from './Extra/exportData';

import { defaultDateObject } from 'lib/reusable-components/reusableData/formatterData';
import { getDatesByPeriod } from 'lib/reusable-components/reusableFunction/dateFormater';
import { dateTimeFormatter } from 'lib/reusable-components/reusableFunction/formatter';

// TIMESERIES
export const TIMESERIES = {
  TIMESERIESTITLE: 'Time Series',
  TIMESERIES: 'Time Series based Trend Analysis and Insights',
  DURATION: 'Time Duration for Data',

  GROWTHRATE: 'Growth Rate Analysis',
  currentWeek: 'Current Week',
  currentMonth: 'Current Month',
  currentQuarter: 'Current Quarter',
  currentYear: 'Current Year',
  last7days: 'Last 7 Days',
  last30days: 'Last 30 Days',
  last90days: 'Last 90 Days',
  last365days: 'Last 365 Days',

  MAXIMAMINIMA: 'Maximum Minimum Analysis',
  weekDayMaxima: 'Day of Week With Best Performance',
  weekMaxima: 'Week With Best Performance',
  monthMaxima: 'Month With Best Performance',
  quarterMaxima: 'The Quarter With Best Performance',
  yearMaxima: 'Year With Best Performance',

  MinimumAnalysisTitle: 'Minimum Analysis',
  MaxmimumAnalysisTitle: 'Maxmimum Analysis',
  GrowthRateTitle: 'Growth Rate',

  weekDayMinima: 'Day of Week With Worse Performance',
  weekMinima: 'Week With Worse Performance',
  monthMinima: 'Month With Worse Performance',
  quarterMinima: 'The Quarter With Worse Performance',
  yearMinima: 'Year With Worse Performance',

  percentageDifference: 'The percentage difference between the maximum value and the minimum value',
  VARIANCE: 'How Variance is affecting over time',

  '80-20RULE': 'Pareto Rule Analysis',
  top3MonthsThisYear: 'Best 3 performer months of this year',
  top3MonthsImpactThisYear:
    'Percentage of impact the top 3 months combined are having in this year',
  top3MonthsLastYear: 'Best 3 performer months of last year',
  top3MonthsImpactLastYear:
    'Percentage of impact the top 3 months combined are having in last year',

  top20PercentList: 'Categories in top 20 percentile',
  top20PercentImpact: 'Percentage of impact these top 20 percentile combined amounts to:',
};

export const TIMETYPES: Record<string, string> = {
  GROWTHRATE: 'Growth Rate Analysis',
  MAXIMAMINIMA: 'Maximum Minimum Analysis',
  '80-20RULE': 'Pareto Rule Analysis',
};

function changeNumFormat(num: number) {
  const labelValue = roundFigure(num);
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'Bn'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'Mn'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
    : Math.abs(Number(labelValue)).toFixed(2);
}

const roundFigure = (num: number) => {
  return Math.round(num * 100) / 100;
};

export const getStatisticalData = (sectionObj: any) => {
  return [
    [
      'Top 20 Percentile',
      changeNumFormat(sectionObj?.top20Percentage?.mean),
      changeNumFormat(sectionObj?.top20Percentage?.median),
    ],
    [
      'Bottom 20 Percentile',
      changeNumFormat(sectionObj?.bottom20Percentage?.mean),
      changeNumFormat(sectionObj?.bottom20Percentage?.median),
    ],
    [
      'Percentage of Categories Above 50%:',
      changeNumFormat(sectionObj?.percentageOfCategoriesAbove?.mean),
      changeNumFormat(sectionObj?.percentageOfCategoriesAbove?.median),
    ],
    [
      'Percentage of Categories Below 50%:',
      changeNumFormat(sectionObj?.percentageOfCategoriesBelow?.mean),
      changeNumFormat(sectionObj?.percentageOfCategoriesBelow?.median),
    ],
    [
      'Overall Analysis',
      changeNumFormat(sectionObj?.overAll?.mean),
      changeNumFormat(sectionObj?.overAll?.median),
    ],
  ];
};

export const getGrowthRateData = (sectionObj: any, type: string) => {
  if (type === 'Current') {
    return [
      [getDatesByPeriod({ period: defaultDateObject.THISWEEK.period }), sectionObj?.currentWeek],
      [getDatesByPeriod({ period: defaultDateObject.THISMONTH.period }), sectionObj?.currentMonth],
      [
        getDatesByPeriod({ period: defaultDateObject.THISQUARTER.period }),
        sectionObj?.currentQuarter,
      ],
      [getDatesByPeriod({ period: defaultDateObject.THISYEAR.period }), sectionObj?.currentYear],
    ];
  } else if (type === 'Last') {
    return [
      [
        getDatesByPeriod({ period: defaultDateObject.LASTXDAYS.period, xDay: 7 }),
        sectionObj?.last7days,
      ],
      [
        getDatesByPeriod({ period: defaultDateObject.LASTXDAYS.period, xDay: 30 }),
        sectionObj?.last30days,
      ],
      [
        getDatesByPeriod({ period: defaultDateObject.LASTXDAYS.period, xDay: 90 }),
        sectionObj?.last90days,
      ],
      [
        getDatesByPeriod({ period: defaultDateObject.LASTXDAYS.period, xDay: 365 }),
        sectionObj?.last365days,
      ],
    ];
  }
};

export const getMaximaMinimaData = (sectionObj: any, type: string) => {
  if (type === 'Maxima') {
    return [
      [
        'Day of Week With Best Performance',
        sectionObj?.maxima?.weekDay[0],
        sectionObj?.maxima?.weekDay[1],
        roundFigure(sectionObj?.percentageDifference?.weekDay),
      ],
      [
        'Week With Best Performance',
        sectionObj?.maxima?.week[0],
        sectionObj?.maxima?.week[1],
        roundFigure(sectionObj?.percentageDifference?.week),
      ],
      [
        'Month With Best Performance',
        sectionObj?.maxima?.month[0],
        sectionObj?.maxima?.month[1],
        roundFigure(sectionObj?.percentageDifference?.month),
      ],
      [
        'The Quarter With Best Performance',
        sectionObj?.maxima?.quarter[0],
        sectionObj?.maxima?.quarter[1],
        roundFigure(sectionObj?.percentageDifference?.quarter),
      ],
      [
        'Year With Best Performance',
        sectionObj?.maxima?.year[0],
        sectionObj?.maxima?.year[1],
        roundFigure(sectionObj?.percentageDifference?.year),
      ],
    ];
  } else if (type === 'Minima') {
    return [
      [
        'Day of Week With Worse Performance',
        sectionObj?.minima?.weekDay[0],
        sectionObj?.minima?.weekDay[1],
        roundFigure(sectionObj?.percentageDifference?.weekDay),
      ],
      [
        'Week With Worse Performance',
        sectionObj?.minima?.week[0],
        sectionObj?.minima?.week[1],
        roundFigure(sectionObj?.percentageDifference?.week),
      ],
      [
        'Month With Worse Performance',
        sectionObj?.minima?.month[0],
        sectionObj?.minima?.month[1],
        roundFigure(sectionObj?.percentageDifference?.month),
      ],
      [
        'The Quarter With Worse Performance',
        sectionObj?.minima?.quarter[0],
        sectionObj?.minima?.quarter[1],
        roundFigure(sectionObj?.percentageDifference?.quarter),
      ],
      [
        'Year With Worse Performance',
        sectionObj?.minima?.year[0],
        sectionObj?.minima?.year[1],
        roundFigure(sectionObj?.percentageDifference?.year),
      ],
    ];
  }
};

export const top3MonthsThisYear = {
  columName2: 'Time Period',
  columName3: 'Percentage',
};

export const TYPES = {
  TOP5BOTTOM5: {
    normal: 'Top And Bottom Performers List',
    wordcloud: 'Most And Least Words Count',
  },

  '80-20RULE': { normal: 'Pareto Rule Analysis', wordcloud: 'Pareto Rule Analysis' },
  STATISTICAL: { normal: 'Statistical Analysis', wordcloud: 'Statistical Analysis' },
};
export const normalCatType = {
  TOP5BOTTOM5: {
    mainTitle: 'Top And Bottom Performers List',
    topPerformers: {
      title: 'Top Performers',
      column1: null,
      column2: 'Value',
    },
    bottomPerformers: {
      title: 'Bottom Performers',
      column1: null,
      column2: 'Value',
    },
  },
  '80-20RULE': {
    mainTitle: 'Pareto Rule Analysis',
  },
  STATISTICAL: {
    mainTitle: 'Statistical Analysis',
  },
};
export const wordcloudCatType = {
  ...normalCatType,
  TOP5BOTTOM5: {
    mainTitle: 'Most And Least Words Count',
    topPerformers: {
      title: 'Most Used Words',
      column1: 'Words',
      column2: 'Count',
    },
    bottomPerformers: {
      title: 'Least Used Words',
      column1: 'Words',
      column2: 'Count',
    },
  },
};
// CATEGORY
export const CATEGORY = {
  CATEGORYTITLE: 'Category Analysis',
  CATEGORY: 'Analysis and Insights of Categorical Data',
  top5: 'Top performers List',
  bottom5: 'Bottom Performers List',
  TOP5BOTTOM5: 'Top And Bottom Performers List',
  top20PercentImpact: 'Percentage of impact these top 20 percentile combined amounts to:',
  columnName1: 'Phones',
  columName2: 'Values',
  columName3: 'Percentage',
};
