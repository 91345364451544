import { mls } from '../../multilanguagesupport/analytics';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import * as topojson from 'topojson-client';
import { geoGroupsToSteps, getPercentile } from '../../helpers/legendHelper';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { defaultToolTipStyling } from '../../constants/constants';
import { createColorArray } from '../../helpers/colorHelper';
import LoadingSpinner from 'lib/visx-lib/helpers/components/LoadingSpinner';
import { GeoToolTip } from '../ChartToolTip/ChartToolTip';
import ObjectDropDown from '../../helpers/components/DropDown/ObjectDropDown';
import { MdClose } from 'react-icons/md';
import './geoType.scss';
import styles from '../../constants/geoStyles.module.css';

import useGeoLegend from 'lib/visx-lib/helpers/hooks/useGeoLegend';
const GeoTypeSelect = React.lazy(() => import('./GeoTypeSelect'));

const getData = (data, yLabel) => {
  const stateData = {};
  data.x.forEach((d, i) => {
    stateData[d] = data.y[yLabel]?.[i] || 0;
  });
  return stateData;
};

const gettooltipData = (geoData, state) => {
  const index = geoData.x.indexOf(state);
  const tooltipData = {};
  Object.keys(geoData.y).forEach((key) => {
    tooltipData[key] = geoData.y[key][index];
  });
  return tooltipData;
};

const getColor = (value, steps, colors) => {
  if (value === null || value === undefined) {
    return '#ddd';
  }
  let index = 0;
  steps?.map((elem, i) => {
    if (elem < value) {
      index = i + 1;
    }
  });
  return colors[index];
};

const GeoIndiaType = ({ data: geoData, type, handleClick, groups, theme, chartHeight, labels }) => {
  const [selectedLabel, setSelectedLabel] = useState(Object.keys(geoData.y)[0]);
  const [isAxisSelectorOn, setisAxisSelectorOn] = useState(false);
  const data = useMemo(() => getData(geoData, selectedLabel), [geoData, selectedLabel]);
  const [topo, setTopo] = useState(null);

  useEffect(() => {
    let cancel = false;
    if (type === 'GEOINDIA') {
      import('./topos/india-topo.json').then((indiaTopo) => {
        if (cancel) return;
        setTopo(indiaTopo);
      });
      return;
    }
    if (type === 'GEOUSA') {
      import('./topos/usa-topo.json').then((usaTopo) => {
        if (cancel) return;
        setTopo(usaTopo);
      });
      return;
    }
    if (type === 'GEOWORLD') {
      import('./topos/world-topo.json').then((worldTopo) => {
        if (cancel) return;
        setTopo(worldTopo);
      });
      return;
    }

    import('./topos/canada-topo.json').then((canadaTopo) => {
      if (cancel) return;
      setTopo(canadaTopo);
    });

    return () => {
      cancel = true;
    };
  }, [type]);

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip();
  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const geoIndia = useMemo(() => {
    if (!topo) return null;
    if (type === 'GEOWORLD') return topojson.feature(topo, topo?.objects?.global);
    return topojson.feature(topo, topo?.objects?.states);
  }, [topo]);

  const indiaMeshes = useMemo(() => {
    if (!topo) return null;
    if (type === 'GEOWORLD') return topojson.mesh(topo, topo?.objects?.global);
    return topojson.mesh(topo, topo?.objects?.states, (a, b) => a !== b);
  }, [topo]);

  const groupsteps = useMemo(() => geoGroupsToSteps(groups), [groups]);
  const steps = useMemo(() => {
    return groups && groups.length > 0 ? groupsteps.steps : getPercentile(Object.values(data), 5);
  }, [groups, groupsteps, data]);
  const colors = createColorArray(5, theme.colors[selectedLabel]).reverse();
  const axisStyle = theme.tooltip.style.find(
    (item) => (item.uniqueColumnName || item.column) === selectedLabel
  );
  const handleMouse = useCallback(
    (event, d) => {
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;

      const name = d.properties.name;
      const index = geoData.x.indexOf(name);
      const value = index >= 0 ? geoData?.y[selectedLabel]?.[index] : null;
      const color = getColor(value, steps, colors);
      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: {
          x: {
            name: name,
            style: {
              color: color,
              opacity: value === null || value === undefined ? 100 : axisStyle?.opacity,
            },
          },
          y: { name: selectedLabel, value },
        },
      });
    },
    [containerBounds, showTooltip, geoData, selectedLabel, theme]
  );

  const { thresholdScale, GeoLegend, selectedLegendExtent } = useGeoLegend({
    legendGlyphSize: 20,
    steps: steps,
    colors: colors,
    opacity: 1,
  });
  if (!topo) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: chartHeight }}>
        <LoadingSpinner />;
      </div>
    );
  }

  return (
    <>
      {geoData.x && geoData.x.length !== 0 && geoData.y && geoData.y.length !== 0 ? (
        <>
          <div style={{ height: chartHeight }}>
            <ParentSize>
              {({ width, height }) => (
                <div style={{ display: 'flex', alignItems: 'center', height: height }}>
                  <Suspense fallback={<LoadingSpinner />}>
                    <GeoTypeSelect
                      geoFeature={geoIndia}
                      meshes={indiaMeshes}
                      stateData={data}
                      width={width}
                      height={height}
                      steps={steps}
                      // colors={groupsteps?.colors}
                      type={type}
                      colors={colors}
                      theme={theme}
                      labels={labels}
                      ref={containerRef}
                      handleMouse={handleMouse}
                      hideTooltip={hideTooltip}
                      handleClick={handleClick}
                      axisStyle={axisStyle}
                      thresholdScale={thresholdScale}
                      GeoLegend={GeoLegend}
                      selectedLegendExtent={selectedLegendExtent}
                    />
                  </Suspense>
                </div>
              )}
            </ParentSize>
            {tooltipOpen && (
              <GeoToolTip
                defaultToolTipStyling={defaultToolTipStyling}
                selectedLabel={selectedLabel}
                tooltipLeft={tooltipLeft}
                tooltipTop={tooltipTop}
                TooltipInPortal={TooltipInPortal}
                tooltipData={tooltipData}
                theme={theme}
                labels={labels}
                stateData={data}
                backgroundColor={defaultToolTipStyling.backgroundColor}
              />
            )}
          </div>
          {/* <div className={styles.legend}>
            <GeoLegend />
          </div> */}
          <div className={styles.legend2}>
            <GeoLegend />
          </div>
        </>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}

      {Object.keys(labels).length > 1 ? (
        <div
          style={{
            // color: '#7e8299',
            // position: 'absolute',
            padding: '0.5rem 0',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {!isAxisSelectorOn ? (
            <button
              onClick={() => setisAxisSelectorOn(true)}
              class='btn btn-link'
              style={{ textDecoration: 'underline' }}
            >
              {mls('Select Y-Axis')}
            </button>
          ) : (
            <div
              style={{
                width: '15rem',
                background: 'white',
                padding: '.25rem .5rem',
                borderRadius: '6px',
              }}
            >
              <div
                className='titleDiv'
                style={{
                  width: 'calc(100%)',
                  display: 'flex',
                  gap: '.5rem',
                }}
              >
                <div
                  className='title'
                  style={{
                    width: 'calc(100% - 1.75rem)',
                    fontSize: '1.2rem',
                  }}
                >
                  &nbsp;{mls('Choose Column')}
                </div>
                <div
                  className='close'
                  style={{
                    width: '1.25rem',
                    fontSize: '1.25rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setisAxisSelectorOn(false);
                  }}
                >
                  <MdClose />
                </div>
              </div>
              <ObjectDropDown
                object={labels}
                dropDownValue={selectedLabel}
                setdropDownValue={(e) => {
                  setSelectedLabel(e);
                }}
                className='geoChooseColumnDropdown'
              />
            </div>
          )}
        </div>
      ) : null}
      {/* {Object.keys(labels).length > 1 && (
        <FormControl
          sx={{
            marginTop: '1rem',
          }}
          fullWidth
        >
          <InputLabel id='demo-simple-select-label'>Attribute</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectedLabel}
            label='Attribute'
            onChange={(e) => {
              setSelectedLabel(e.target.value);
            }}
          >
            {Object.entries(labels).map(([key, value]) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )} */}
    </>
  );
};

export default GeoIndiaType;

/**
 * {tooltip && (
        <div
          className={styles.tooltip}
          style={{
            left: tooltip.x - 5,
            top: tooltip.y + 30,
            color: tooltipColor,
            ...defaultToolTipStyling,
          }}
        >
          <div>
            <div
              style={{
                ...getStyling({
                  textStyle: theme.tooltip.textStyle,
                  font: theme.tooltip.font,
                  fontHeight: theme.tooltip.fontHeight,
                  color: theme.tooltip.style[0].color,
                  opacity: theme.tooltip.style[0].opacity,
                }),
              }}
            >
              <strong>State: </strong> {tooltip.region}
            </div>
            <div
              style={{
                ...getStyling({
                  textStyle: theme.tooltip.textStyle,
                  font: theme.tooltip.font,
                  fontHeight: theme.tooltip.fontHeight,
                  color: theme.tooltip.style[1].color,
                  opacity: theme.tooltip.style[1].opacity,
                }),
              }}
            >
              
              {Object.entries(geoData.y).map(([key, value]) => {
                return (
                  <div key={key}>
                    <strong>{labels[key]}:</strong>{' '}
                    {format('~s')(value[geoData.x.findIndex((d) => d === tooltip.region)])}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
 * 
 * 
 */

/* <strong> {yAxisLabel}:</strong> {format('~s')(tooltip.confirmed)} */

const sampleIndiaData = {
  geoData: {
    x: [
      'Assam',
      'Chhattisgarh',
      'Delhi',
      'Karnataka',
      'Kerala',
      'Maharashtra',
      'Odisha',
      'Rajasthan',
      'Tamil Nadu',
    ],
    y: {
      'Eureka Forbes Demo Data - Dealers.NumberofitemsSold-2': [
        286, 195, 149, 202, 157, 134, 247, 82, 33,
      ],
    },
  },
  selectedLabel: 'Eureka Forbes Demo Data - Dealers.NumberofitemsSold-2',
};
const sampleUSAData = {
  geoData: {
    Alabama: 938,
    Alaska: 942,
    Arizona: 914,
    Arkansas: 826,
    California: 793,
    Colorado: 972,
    Connecticut: 1003,
    Delaware: 933,
    Florida: 807,
    Georgia: 937,
    Hawaii: 885,
    Idaho: 825,
    Illinois: 902,
    Indiana: 817,
    Iowa: 883,
    Kansas: 821,
    Kentucky: 856,
    Louisiana: 921,
    Maine: 924,
    Maryland: 868,
    Massachusetts: 955,
    Michigan: 864,
    Minnesota: 922,
    Mississippi: 924,
    Missouri: 963,
    Montana: 946,
    Nebraska: 832,
    Nevada: 852,
    'New Hampshire': 947,
    'New Jersey': 958,
    'New Mexico': 873,
    'New York': 931,
    'North Carolina': 999,
    'North Dakota': 832,
    Ohio: 822,
    Oklahoma: 915,
    Oregon: 824,
    Pennsylvania: 779,
    'Rhode Island': 908,
    'South Carolina': 923,
    'South Dakota': 874,
    Tennessee: 860,
    Texas: 872,
    Utah: 867,
    Vermont: 884,
    Virginia: 825,
    Washington: 796,
    'West Virginia': 856,
    Wisconsin: 911,
    Wyoming: 901,
  },
  selectedLabel: '8cc8d7ad-74c5-4624-8af1-40d479329d62.Age-2',
};
