import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import convertToGraphFormat from '../helpers/convertToGraphFormat';
import { defaultMargin } from '../constants/constants';
import GeoType from './GeoType/GeoType';
import WordCloudType from './WordCloudType/WordCloudType';
import ScatterPlotType from './ScatterPlot/ScatterPlotType';
import GroupScatterPlotType from './ScatterPlot/GroupScatterPlotType';
import BubblePlotType from './BubblePlotType/BubblePlotType';
import TreeMapType from './TreeMapType/TreeMapType';
import RadarType from './RadarType/RadarType';
import RadialLineType from './RadarLineType/RadarLineType';
import YXType from './YXType/YXType';
import ForecastLineType from './ForecastLineType/ForecastLineType';
import FunnelType from './FunnelType/FunnelType';
import KPIMeterType from './KPIMeterType/KPIMeterType';
import XYType from './BarStack&LineType/BarStackLineType';
import BarGroupType from './BarStack&LineType/BarGroupType';
import DoubleYAxisChart from './DoubleYAxisChart/DoubleYAxisChart';
import PivotTable from './PivotTable/PivotTable';
import HeatMap from './HeatMap/HeatMap';
import { defaultDateTimeFrame } from 'lib/reusable-components/reusableData/formatterData';
import { useDispatch, useSelector } from 'react-redux';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import { create } from 'lib/reusable-components/reusableUtils/redux/stateSlice';

const capitalizeOnlyFirst = (s) => {
  s = s.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const xGridTypes = ['BAR', 'BARSTACK', 'MULTILINE', 'LINE', 'BARGROUP'];
const yGridTypes = ['BARHORIZONTAL', 'BARSTACKHORIZONTAL', 'BARGROUPHORIZONTAL'];

const typeChartObject = {
  BAR: XYType,
  BARSTACK: XYType,
  AREASTACK: XYType,
  BARGROUP: XYType,
  AREA: XYType,
  MULTILINE: XYType,
  LINE: XYType,
  BARSTACKLINE: XYType,
  BARSTACKHORIZONTAL: YXType,
  BARGROUPHORIZONTAL: YXType,
  BARHORIZONTAL: YXType,
  LINEHORIZONTAL: YXType,
  AREAHORIZONTAL: YXType,
  MULTILINEHORIZONTAL: YXType,
  AREASTACKHORIZONTAL: YXType,
  SCATTERPLOT: ScatterPlotType,
  GROUPSCATTERPLOT: GroupScatterPlotType,
  BUBBLEPLOT: BubblePlotType,
  GROUPBUBBLEPLOT: BubblePlotType,
  GEOUSA: GeoType,
  GEOINDIA: GeoType,
  GEOCANADA: GeoType,
  GEOWORLD: GeoType,
  WORDCLOUD: WordCloudType,
  TREEMAP: TreeMapType,
  RADAR: RadarType,
  RADIALLINE: RadialLineType,
  FUNNEL: FunnelType,
  KPIMETER: KPIMeterType,
  PIVOTTABLE: PivotTable,
  HEATMAP: HeatMap,
  BARCOLOR: XYType,
  LINECOLOR: XYType,
  AREACOLOR: XYType,
  BARHORIZONTALCOLOR: YXType,
};

const VisxChart = ({
  chartKey,
  chartData,
  dataToPlot: dataToPlotProp,
  cardWidth,
  useChartSettingsButton,
  isProd,
  updateFilter,
  handleChartUpdates,
  isUpdateLoading,
  cardRef,
  filterSchema,
  chartHeight,
  predictionData,
  isForecast,
  targetData,
  theme,
  group,
  isHorizontal,
  isReport,
}) => {
  const chartID = chartData.chartID;
  const sheetID = chartData.sheetID;
  // const dataToPlot = dataToPlotProp?.plotData || [];
  const dataToPlot = useMemo(() => dataToPlotProp?.plotData || [], [dataToPlotProp?.plotData]);
  const plotOptions = useMemo(() => dataToPlotProp?.options || [], [dataToPlotProp?.options]);
  // Title of Chart
  const title = chartData.name;
  const desc = chartData.desc;
  // Type of Chart
  const type = chartData.chartType;
  // xAxis and yAxis
  // const { xAxis, yAxis } = chartData;
  const xAxis = useMemo(() => chartData.xAxis, [chartData.xAxis]);
  const yAxis = useMemo(() => chartData.yAxis, [chartData.yAxis]);

  // color of chart
  const chartColor = chartData.chartColor;

  // If the colors used for chart are monochromatic
  const colorType = chartData?.colorType || 'Variant';
  // const group = chartData.group;

  const isInsight = chartData?.isInsight || false;

  // Method to only capitalize first letter
  const xColumnMain = xAxis[0];
  const yColumnMain = yAxis[0];

  const xOperations = capitalizeOnlyFirst(xColumnMain?.operations?.type || '');

  const xAxisLabel =
    theme.xAxisStyle?.label ||
    (chartData.xAxisLabel === ''
      ? xOperations === ''
        ? xColumnMain.label
        : `${xOperations}[ ${xColumnMain.label} ]`
      : chartData.xAxisLabel);

  const xCategory = xColumnMain?.dataType;
  const xColumn = xColumnMain?.column;
  const yOperations = capitalizeOnlyFirst(yColumnMain?.operations?.type || '');
  const yAxisLabel =
    theme.yAxisStyle?.label ||
    (chartData.yAxisLabel === ''
      ? yOperations === ''
        ? yColumnMain.label
        : `${yOperations}[ ${yAxis[0].label} ]`
      : chartData.yAxisLabel);
  const yCategory = yColumnMain?.dataType || '';
  const yColumn = yColumnMain?.column || '';

  // Constant for Grid Lines

  const xGrid = xGridTypes.includes(type) ? true : false;
  const yGrid = yGridTypes.includes(type) ? true : false;
  // Constant for margin
  const margin = defaultMargin;
  const [data, setData] = useState(
    convertToGraphFormat(type, dataToPlot, xAxis, yAxis, chartData, plotOptions)
  );
  const [forecastData, setForecastData] = useState(
    isForecast
      ? convertToGraphFormat(type, predictionData, xAxis, yAxis, chartData, plotOptions)
      : null
  );
  const dispatch = useDispatch();

  const timeFrameData = useSelector(
    (state) => state[reduxConstants.STORE_NAME][reduxConstants.config.DATE_TIME_TOGGLE]
  );
  const getDateTimeToggle = () => {
    if (isHorizontal) {
      if (yCategory === 'DATETIME' && !isUpdateLoading) {
        return yColumnMain?.operations?.type ?? defaultDateTimeFrame;
      }
    }
    if (xCategory === 'DATETIME' && !isUpdateLoading) {
      return xColumnMain?.operations?.type ?? defaultDateTimeFrame;
    }
    return defaultDateTimeFrame;
  };
  // toggle button for day week month quarter and year
  const [toggle, setToggle] = useState(getDateTimeToggle());
  // WEEK
  const [isToggleClicked, setClick] = useState(false);
  const handleToggle = async (item) => {
    dispatch(
      create({
        setPath: reduxConstants.config.DATE_TIME_TOGGLE,
        value: { toggle: { ...timeFrameData?.value, [chartID]: item } },
      })
    );
    setClick(true);
    const updates = {
      [chartID]: {
        dateRange: item,
      },
    };
    await handleChartUpdates(updates);
    setToggle(item);
    setClick(false);
  };
  useEffect(() => {
    let newData = convertToGraphFormat(type, dataToPlot, xAxis, yAxis, chartData, plotOptions);
    // if (xCategory === 'DATETIME' && !isUpdateLoading) {
    //   newData = {
    //     ...newData,
    //     x: newData.x,
    //     // x: [...axisDateFormatter({ data: newData.x, toggle, type: theme.xAxisStyle.formatter })],
    //   };
    // }
    // if (yCategory === 'DATETIME' && !isUpdateLoading) {
    //   newData = {
    //     ...newData,
    //     y: newData.y,
    //     // y: [...axisDateFormatter({ data: newData.y, toggle, type: theme.yAxisStyle.formatter })],
    //   };
    // }

    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateLoading, dataToPlot, toggle, theme.xAxisStyle.formatter, theme.yAxisStyle.formatter]);
  useEffect(() => {
    if (!isForecast) {
      return;
    }
    let newData = convertToGraphFormat(type, predictionData, xAxis, yAxis, chartData, plotOptions);
    if (xCategory === 'DATETIME' && !isUpdateLoading) {
      newData = {
        ...newData,
        x: newData.x,
        // x: [...axisDateFormatter({ data: forecastData.x, toggle, type: theme.xAxisStyle.formatter })],
      };
    }
    setForecastData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateLoading, predictionData]);
  const labels = useMemo(() => {
    const newLabels = {};
    const tooltipLabels = {};
    theme.tooltip.style.forEach((data) => {
      tooltipLabels[data.uniqueColumnName || data.column] = data.label;
    });
    (isHorizontal ? xAxis : yAxis).forEach((data) => {
      newLabels[data.uniqueColumnName || data.column] =
        tooltipLabels[data.uniqueColumnName || data.column] || data.label;
    });
    if (!isForecast) {
      chartData.constantLine?.forEach((data) => {
        if (data.uniqueColumnName) {
          newLabels[data.uniqueColumnName] = data.label;
        } else {
          newLabels[data.column] = data.label;
        }
      });
    }
    return newLabels;
  }, [isForecast, yAxis, xAxis, isHorizontal, chartData.constantLine, theme.tooltip.style]);
  const newTargetData = useMemo(() => {
    let newTData = null;
    if (dataToPlotProp?.constantLine) {
      newTData = { x: [], y: {} };

      Object.entries(dataToPlotProp?.constantLine).forEach(([key, value]) => {
        newTData.y[key] = {
          data: value,
        };
      });
    }
    return newTData;
  }, [dataToPlotProp]);

  // Check if category is datetime, then update the values
  // if (yCategory === 'DATETIME') {
  //   data = {
  //     ...data,
  //     y: [...dateFormatHelper(data.y)],
  //   };
  // }

  //  This Function enables onclicking bar, pie, bargroup filter will be applied
  const handleClick = (value) => {
    let col = '';
    if (xCategory === 'CAT') {
      col = xColumn.split('.').slice(1).join('.');
    } else if (yCategory === 'CAT') {
      col = yColumn.split('.').slice(1).join('.');
    }
    if (xCategory === 'DATETIME') {
      col = xColumn.split('.').slice(1).join('.');
    } else if (yCategory === 'DATETIME') {
      col = yColumn.split('.').slice(1).join('.');
    }
    const schema = filterSchema.filter(
      (obj) => obj.columnName === col && obj.sheetID === sheetID
    )[0];

    let filterValue = [value];
    if (xCategory === 'DATETIME' || yCategory === 'DATETIME') {
      const timestamp = Date.parse(value);
      const dateObject = new Date(timestamp);
      filterValue = {
        endDate: dateObject,
        period: 'CUSTOM',
        startDate: dateObject,
      };
    }
    const newFilters = {
      [schema?.tableName + '.' + schema?.columnName]: {
        column: schema?.columnName,
        dataType: schema?.dataType,
        tableName: schema?.tableName,
        sheetID: schema?.sheetID,
        value: filterValue,
      },
    };
    updateFilter(newFilters);
  };

  // Determine height of the card

  // Ref to the parent card div
  // const cardRef = useRef(null);

  // Determine the class of container based on the chartWidth

  // if (dataToPlot.length === 0) {
  //   return noDataDisplay(colClass, chartID, cardRef);
  // }

  // Function to determine the type of chart
  // and what component to return

  const [chartReady, setChartReady] = useState(false);

  // This is to make sure that ref is not null
  useEffect(() => {
    setChartReady(true);
  }, []);

  const ChartComponent =
    (type === 'LINE' || type === 'MULTILINE') && chartData?.isForecast
      ? ForecastLineType
      : typeChartObject[type];

  if (!chartReady) {
    return null;
  }
  return (
    // <div className={`${colClass()} mb-4`} key={chartKey}>
    // <div className='card' ref={cardRef} style={{ height: height }}>

    <ChartComponent
      data={data}
      targetData={newTargetData}
      isForecast={isForecast}
      predictionData={forecastData}
      title={title}
      desc={desc}
      dataToPlot={dataToPlot}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisLabel}
      yCategory={yCategory}
      xCategory={xCategory}
      xGrid={xGrid}
      yGrid={yGrid}
      margin={margin}
      type={type}
      cardRef={cardRef}
      chartID={chartID}
      useChartSettingsButton={useChartSettingsButton}
      isProd={isProd}
      chartColor={chartColor}
      cardWidth={cardWidth}
      colorType={colorType}
      handleClick={handleClick}
      labels={labels}
      toggle={toggle}
      handleToggle={handleToggle}
      isToggleClicked={isToggleClicked}
      isUpdateLoading={isUpdateLoading}
      chartHeight={chartHeight}
      theme={theme}
      group={group}
      chartData={chartData}
      isInsight={isInsight}
      plotOptions={plotOptions}
      isReport={isReport}
    />
  );

  // return <>{chartData}</>;
};

VisxChart.propTypes = {
  dataToPlot: PropTypes.object,
  halfWidth: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  chartData: PropTypes.object.isRequired,
  useChartSettingsButton: PropTypes.func.isRequired,
  isProd: PropTypes.string.isRequired,
};

VisxChart.defaultProps = {
  halfWidth: false,
};

export default VisxChart;
