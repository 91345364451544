import { mls } from '../../multilanguagesupport/analytics';
import React, { useMemo } from 'react';
import TreeMapChart from './TreeMapChart';
import useDivDimensions from '../../helpers/divDimensionHelper';

const TreeMapType = ({
  data: treeMapData,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  gradientColor,
  chartHeight,
  chartData,
  theme,
  isInsight,
  optionData,
}) => {
  const width = useDivDimensions(cardRef).width;
  const data = useMemo(() => {
    const { x, y } = treeMapData;
    if (!x || !y) return [];
    const id = x;
    const parent = '__d3__root_of_tree';
    const size = y;
    if (!id[0]) {
      return [];
    }
    const convertData = [{ id: parent, parent: null, size: 0 }];
    for (let i = 0; i < id.length; i++) {
      if (size[i] !== 0) {
        convertData.push({
          id: id[i],
          parent: parent,
          size: Math.abs(size[i]),
          realValue: size[i],
        });
      }
    }
    return convertData;
  }, [treeMapData]);
  return (
    <>
      {data.length !== 0 ? (
        <>
          {true ? (
            // {optionData?.anyPositive ? (
            <div style={{ width: '100%', height: chartHeight }}>
              <TreeMapChart
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                handleClick={handleClick}
                data={data}
                width={width}
                height={chartHeight}
                // height={350}
                chartData={chartData}
                theme={theme}
              />
            </div>
          ) : (
            <div
              className='fw-bolder my-1 fs-4 d-flex justify-content-center'
              style={{ color: '#7e8299' }}
            >
              {mls(
                "Consider using a different chart type, as TreeMap charts aren't suitable for negative or zero values!"
              )}
            </div>
          )}
        </>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

export default TreeMapType;
