import React from 'react';
import './TableData.scss';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { BsArrowDownShort } from 'react-icons/bs';
import { changeNumFormat } from '../../utils/converterFunction';
import { dateTimeFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import { mls } from 'lib/multilanguagesupport';

interface TableDataProps {
  elem: any;
  columnName1?: string;
  columnName2: string | undefined;
  columnName3: string;
  columnName4?: string;
  type?: string;
  chartType: string;
  mainData: any;
  arrow?: boolean;
}

const TableData = ({
  elem,
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  chartType,
  mainData,
  arrow = false,
}: TableDataProps) => {
  if (type === 'Statistical') {
    return (
      <Statistical
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        arrow={arrow}
      />
    );
  }
  if (type === 'MaximaMinima') {
    return (
      <MaximaMinima
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        arrow={arrow}
      />
    );
  }
  if (type === 'twoColumns') {
    return (
      <TwoColumns
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        chartType={chartType}
        mainData={mainData}
        arrow={arrow}
      />
    );
  }
  if (type === 'LastGrowth') {
    return (
      <TwoColumns
        columnName1={columnName1}
        columnName2={columnName2}
        columnName3={columnName3}
        columnName4={columnName4}
        type={type}
        elem={elem}
        chartType={chartType}
        mainData={mainData}
        arrow={arrow}
      />
    );
  }
  return (
    <TwoColumns
      columnName1={columnName1}
      columnName2={columnName2}
      columnName3={columnName3}
      columnName4={columnName4}
      type={type}
      elem={elem}
      chartType={chartType}
      mainData={mainData}
      arrow={arrow}
    />
  );
};

export default TableData;

interface TwoColumnsProps {
  columnName1?: string;
  columnName2?: string;
  columnName3?: string;
  columnName4?: string;
  type?: string;
  elem: any;
  chartType: string;
  mainData: any;
  arrow?: boolean;
}
const TwoColumns = ({
  columnName2,
  columnName3,
  type,
  elem,
  chartType,
  mainData,
  arrow = false,
}: TwoColumnsProps) => {
  return (
    <>
      <div className='container-fluid  w-100 twoColumnDivContainer'>
        <div className='col-8  p-4 g-0  w-100  insight-table-column-header-left'>
          <b>{columnName2}</b>
        </div>
        <div className='col  p-4 g-0   w-100 insight-table-column-header-right '>
          <b>{columnName3}</b>
        </div>
        {Array.isArray(elem) &&
          elem.map((data: [any, any], i: number) => {
            return (
              <>
                {type === 'LastGrowth' ? (
                  <div className='col p-4 g-0  w-100 insight-table-data-cell-left'>{data[0]}</div>
                ) : chartType === allChartKeys.PIVOTTABLE ? (
                  <div className='col-8  p-4 g-0  w-100 insight-table-data-cell-left'>
                    {Object.keys(data[0]).map((columnKey) => {
                      const dateTimeFormat = mainData?.datetimeperiodType?.[columnKey] ?? null;
                      return (
                        <>
                          <b>{mainData?.xcolumnName?.[columnKey]}:</b>{' '}
                          {dateTimeFormat
                            ? dateTimeFormatter({
                                type: { dateTimeFormat },
                                value: data[0][columnKey],
                              })
                            : data[0][columnKey]}
                          <br />
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className='col-8  p-4 g-0  w-100 insight-table-data-cell-left'>
                    {data[0]}
                  </div>
                )}
                {type === 'LastGrowth' ? (
                  <LastGrowthDiv data={data} arrow={arrow} />
                ) : (
                  <div className='col p-4 g-0  w-100 insight-table-data-cell-right'>
                    <ValueNumber number={data[1]} arrow={arrow} />
                  </div>
                )}
              </>
            );
          })}
      </div>
    </>
  );
};
interface ValueNumberProps {
  number: number | null;
  arrow?: boolean;
}
const ValueNumber = ({ number, arrow }: ValueNumberProps) => {
  const numberData = changeNumFormat(number);
  return (
    <>
      {!numberData?.isNull && arrow ? (
        <>
          {numberData?.isNegative ? (
            <BsArrowDownShort style={{ color: 'red' }} />
          ) : (
            <BsArrowDownShort style={{ rotate: '180deg', color: 'green' }} />
          )}
        </>
      ) : null}
      {numberData.value}
    </>
  );
};
const ValueNumberPercentage = ({ number, arrow }: ValueNumberProps) => {
  const numberData = changeNumFormat(number, { maxDecimal: 0 });
  if (numberData?.isNull) {
    return <></>;
  }
  return (
    <>
      <span
        style={{
          fontSize: '8px',
          // color: '#721c24',
          color: `${numberData?.isNegative ? '#721c24' : '#155724'}`,
          background: `${numberData?.isNegative ? '#f8d7da' : '#d4edda'}`,
          borderRadius: '6px',
          padding: '4px',
        }}
      >
        {!numberData?.isNull && arrow ? (
          <>
            {numberData?.isNegative ? (
              <BsArrowDownShort style={{}} />
            ) : (
              <BsArrowDownShort style={{ rotate: '180deg' }} />
            )}
          </>
        ) : null}
        {numberData.value}%
      </span>
    </>
  );
};
const LastGrowthDiv = ({ data, arrow }: { data: [any, any]; arrow: boolean }) => {
  const numberData = changeNumFormat(data[1].growth_rate, { maxDecimal: 0 });
  return (
    <div
      className='col p-4 g-0  w-100 insight-table-data-cell-right'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: !numberData?.isNull ? 'space-between' : 'center',
        flexWrap: 'wrap',
        gap: '.25rem',
      }}
    >
      <ValueNumber number={data[1].current_value} arrow={false} />
      {!numberData?.isNull ? (
        <span
          style={{
            fontSize: '8px',
            // color: '#721c24',
            color: numberData?.isNegative ? '#721c24' : '#155724',
            background: numberData?.isNegative ? '#f8d7da' : '#d4edda',
            borderRadius: '6px',
            padding: '4px',
          }}
        >
          {arrow ? (
            <>
              {numberData?.isNegative ? (
                <BsArrowDownShort style={{}} />
              ) : (
                <BsArrowDownShort style={{ rotate: '180deg' }} />
              )}
            </>
          ) : null}
          {numberData.value}%
        </span>
      ) : null}
    </div>
  );
};
interface StatisticalProps {
  columnName1: any;
  columnName2: any;
  columnName3: any;
  columnName4: any;
  type: any;
  elem: any;
  arrow?: boolean;
}
const Statistical = ({
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  elem,
  arrow = false,
}: StatisticalProps) => {
  return (
    <div className='container-fluid h-100 w-100 mb-2 statisticalDivContainer'>
      <div className='col-8 p-4 w-100  insight-table-column-header-left'>
        <b>{mls(columnName1)}</b>
      </div>
      <div className='col p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName2)}</b>
      </div>
      <div className='col p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName3)}</b>
      </div>
      {Array.isArray(elem) &&
        elem.map((data: [any, any, any], i: number) => (
          <>
            <div className='col-8 p-4 g-0 w-100 insight-table-data-cell-left'>{data[0]}</div>
            <div className='col p-4 g-0 w-100 insight-table-data-cell-right'>{data[1]}</div>
            <div className='col p-4 g-0 w-100 insight-table-data-cell-right'>{data[2]}</div>
          </>
        ))}
    </div>
  );
};
interface MaximaMinimaProps {
  columnName1: any;
  columnName2: any;
  columnName3: any;
  columnName4: any;
  type: any;
  elem: any;
  arrow?: boolean;
}
const MaximaMinima = ({
  columnName1,
  columnName2,
  columnName3,
  columnName4,
  type,
  elem,
  arrow = false,
}: MaximaMinimaProps) => {
  return (
    <div className='container-fluid h-100 mb-2 p-2 maximaMinimaDivContainer'>
      <div className='col p-4 w-100 insight-table-column-header-left'>
        <b>{mls(columnName1)}</b>
      </div>
      <div className='col-3 p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName2)}</b>
      </div>
      <div className='col-2 p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName3)}</b>
      </div>
      <div className='col-2 p-4 w-100 insight-table-column-header-right'>
        <b>{mls(columnName4)}</b>
      </div>

      {Array.isArray(elem) &&
        elem.map((data: [any, any, any, any], i: number) => (
          <>
            <div className='col p-4 g-0 w-100 insight-table-data-cell-left'>{data[0]}</div>
            <div className='col-3 p-4 g-0 w-100 insight-table-data-cell-right'>{data[1]}</div>
            <div className='col-2 p-4 g-0 w-100 insight-table-data-cell-right'>
              <ValueNumber number={data[2]} arrow={arrow} />
            </div>
            <div className='col-2 p-4 g-0 w-100 insight-table-data-cell-right'>{data[3]}%</div>
          </>
        ))}
    </div>
  );
};
