import React, { useImperativeHandle, useMemo, useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';
import styles from '../../constants/styles.module.css';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AnalysisPopUp from 'lib/visx-lib/insights/analysisPopUp/AnalysisPopUp';
// import { useLocation } from 'react-router-dom';
import { MdAddChart } from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import { supistaApiPost } from '../../network/connections';
import { RiLightbulbFill } from 'react-icons/ri';
import { allChartKeys } from 'lib/reusable-components/reusableData/chartTypesSchema';
import { defaultChartWidthTypesObj } from 'lib/reusable-components/reusableData/defaultChartSchema';
import { checkInsightsCapableChart } from 'lib/reusable-components/reusableFunction/chartBasicFuntions';
import { mls } from 'lib/multilanguagesupport';

// async function fetchInsights(chartID, sheetID, appID) {
//   const res = await fetch(`https://analytics-api.supista.com/${appID}/insights`, {
//     method: 'POST',
//     body: JSON.stringify({
//       appID,
//       chartID,
//       sheetID,
//     }),
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//     credentials: 'include',
//   });
//   const data = await res.json();
//   return data;
// }

const Layout = ({
  title,
  chartData,
  isInsightChartSchema,
  isProd,
  chartSettingHandler,
  children,
  style,
  desc,
  isInsight = false,
  chartID,
  sheetID,
  appID,
  isPin,
  handlePin,
  isTopFilter,
  handleTopFilter,
  filterProps,
  insightRef,
  type,
  isInsightShow,
  cardWidth,
  tableID,
  isReport,
}) => {
  return (
    <>
      {!isReport ? (
        <CardHeader
          isInsight={isInsight}
          chartData={chartData}
          isInsightChartSchema={isInsightChartSchema}
          isProd={isProd}
          title={title}
          chartSettingHandler={chartSettingHandler}
          desc={desc}
          chartID={chartID}
          sheetID={sheetID}
          appID={appID}
          isPin={isPin}
          handlePin={handlePin}
          isTopFilter={isTopFilter}
          handleTopFilter={handleTopFilter}
          filterProps={filterProps}
          insightRef={insightRef}
          type={type}
          isInsightShow={isInsightShow}
          cardWidth={cardWidth}
          tableID={tableID}
        />
      ) : null}
      <div style={style}>{children}</div>
    </>
  );
};

const CardHeader = ({
  isInsight,
  isInsightChartSchema,
  isProd,
  chartData,
  title,
  chartSettingHandler,
  desc,
  chartID,
  sheetID,
  appID,
  isPin,
  handlePin,
  filterProps,
  insightRef,
  type,
  isInsightShow,
  cardWidth,
  tableID,
}) => {
  const checkForInsight = useMemo(() => {
    const isInsightCapable = checkInsightsCapableChart({ chartKey: chartData.chartType });
    if (isInsightCapable) {
      return isInsightShow;
    }
    return false;
  }, [isInsightShow, chartData.chartType]);
  const blankFunction = () => {};
  const isTopFilter = filterProps?.isTopFilter ?? false;
  const handleTopFilter = filterProps?.handleTopFilter ?? blankFunction;
  const filterCount = filterProps?.filterCount ?? 0;

  const [isClicked, setClicked] = useState(false);
  const handleMainPopUp = (action) => {
    setClicked(action);
  };
  const [insightData, setData] = useState({});
  const handleClick = async (e) => {
    console.log(tableID);
    e?.preventDefault();
    const normalInsightsData = {
      chartID,
      sheetID,
      appID,
      tableID,
    };

    const insightsDataWithChartSchema = {
      chartID,
      sheetID,
      appID,
      tableID,
      chartSchema: { [chartData?.chartID]: chartData },
    };
    const body = isInsightChartSchema ? insightsDataWithChartSchema : normalInsightsData;
    const data = await supistaApiPost('insights', body, appID);
    if (!data.__d3__error) {
      setData(data.response);
    }
    setClicked(true);
  };

  useImperativeHandle(insightRef, () => ({
    openInsight() {
      handleClick();
    },
  }));
  const isCardStyle = () => {
    if (type === allChartKeys.CARD) {
      return true;
    }
    if (type === allChartKeys.KPIMETER) {
      if (cardWidth === defaultChartWidthTypesObj.ONEFOURTH.key) {
        return true;
      }
    }
    return false;
  };
  return (
    <div className='card-header border-0 p-0 pb-3'>
      <div className={`${styles.titleDiv} w-100 d-flex justify-content-between align-items-center`}>
        {/* Append Title of the chart */}
        <h2
          className='mb-0'
          style={
            isCardStyle()
              ? {
                  width: '100%',
                  display: '-webkit-box',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : { wordWrap: 'break-word', width: '100%' }
          }
          title={mls(title)}
        >
          {mls(title)}
        </h2>
        <div>
          {checkForInsight && isInsight && (
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                onClick={handleClick}
              >
                {/* <TipsAndUpdatesIcon /> */}
                <RiLightbulbFill style={{ fontSize: '1.5rem' }} />
              </button>
              {/* end::Menu */}
            </div>
          )}
          {isTopFilter && (
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                onClick={handleTopFilter}
                style={{ position: 'relative' }}
              >
                <FiFilter style={{ fontSize: '1.5rem' }} />
                <div
                  className='displayFilter'
                  style={{
                    position: 'absolute',
                    top: '4px',
                    right: '2px',
                    height: '1rem',
                    width: '1rem',
                    background: '#009df6',
                    fontSize: '.6rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    borderRadius: '6px',
                    fontWeight: 500,
                    color: 'white',
                  }}
                >
                  {filterCount}
                </div>
              </button>
            </div>
          )}
          {isPin && (
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                onClick={handlePin}
              >
                <MdAddChart style={{ fontSize: '1.5rem' }} />
              </button>
            </div>
          )}

          {isProd === 'sheet' && (
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                onClick={chartSettingHandler}
              >
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
              </button>
              {/* end::Menu */}
            </div>
          )}
        </div>
      </div>
      {isCardStyle() ? (
        <p
          className='mb-0'
          style={{
            width: '100%',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#6E7191',
            minHeight: '1.5rem',
          }}
          title={desc}
        >
          {desc}
        </p>
      ) : (
        desc && (
          <p className='mb-0' style={{ color: '#6E7191', width: '100%' }} title={desc}>
            {desc}
          </p>
        )
      )}
      {/* {desc && (
        <p
          className='mb-0'
          style={
            type === 'CARD'
              ? {
                  width: '100%',
                  display: '-webkit-box',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#6E7191',
                  height: '3rem',
                }
              : { color: '#6E7191', width: '100%' }
          }
        >
          {desc}
        </p>
      )} */}
      {isClicked && (
        <AnalysisPopUp
          chartData={chartData}
          mainData={insightData}
          handleMainPopUp={handleMainPopUp}
          mainPopUp={isClicked}
        />
      )}
    </div>
  );
};

export default Layout;
