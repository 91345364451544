import React, { useEffect, useRef, useState } from 'react';
import './exportReport.scss';
import './scroll.scss';
import { useLocation } from 'react-router-dom';
import { _supistaAnalyticsApi } from 'lib/server-connection/connections';
import ReportElems from './Components/ReportElems';
import { fetchDataFromNodeServer } from './utils/fetchReportDatas';
import heroChart from '../assets/hero-charts.png';
import { getReportDatesByPeriod } from './utils/getReportDatesByPeriod';
import { defaultReportTimeFrame } from '../data/defaultSchema';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ExportReport = () => {
  let query = useQuery();
  const [isReportDataFetched, setisReportDataFetched] = useState(false);
  const [reportData, setReportData] = useState<{ [key: string]: any }>({});
  const [dateData, setDateData] = useState<{ [key: string]: any }>({});
  const [isTempMail, setIsTempMail] = useState(false);
  const key = query.get('key');
  const appID = query.get('appID');
  const reportID = query.get('reportID');

  useEffect(() => {
    // fetchDataFromAnalytics({
    //   appID,
    //   reportID,
    //   setReportData,
    //   setisReportDataFetched,
    // });
    fetchDataFromNodeServer({
      appID,
      reportID,
      setReportData,
      setDateData,
      setIsTempMail,
      setisReportDataFetched,
    });
  }, []);
  if (!isReportDataFetched) {
    return <></>;
  }

  return (
    <div className='ExportReportComp'>
      <main>
        <section>
          <ReportFrontPage
            name={reportData?.name}
            desc={reportData?.desc}
            dateData={dateData}
            isTempMail={isTempMail}
          />
          {React.Children.toArray(
            reportData?.reports.map((reportElem: any) => {
              return (
                <div className='newPageauto'>
                  <ReportElems reportElem={reportElem} />
                </div>
              );
            })
          )}
        </section>
      </main>
    </div>
  );
};

export default ExportReport;

const ReportFrontPage = ({
  name,
  desc,
  dateData,
  isTempMail,
}: {
  name: string;
  desc: string;
  isTempMail: boolean;
  dateData: { [key: string]: any };
}) => {
  const dateDataNew: any = getReportDatesByPeriod({ dateData, isTempMail: isTempMail });
  return (
    <div className='reportFrontPage'>
      <img src={heroChart} alt='' className='heroChartImg' />
      <div className='reportNameContainer'>
        <div className='reportNameBackGround'></div>
        <div className='reportName'>{name || 'Report'}</div>
      </div>
      <div className='reportDiscriptionContainer'>
        <div className='reportDiscriptionBackGround'></div>
        <div className='reportDiscription'>{desc}</div>
      </div>
      <div className='reportTimeframeContainer'>
        <div className='reportTimeframeTitle'>
          Report Time :{' '}
          {isTempMail
            ? 'CUSTOM'
            : defaultReportTimeFrame[dateData.period as keyof typeof defaultReportTimeFrame] ??
              'CUSTOM'}
        </div>

        <div className='reportTimeframe'>
          {dateDataNew?.startDate}
          {dateDataNew?.endDate ? <> to {dateDataNew?.endDate}</> : null}
        </div>
      </div>
    </div>
  );
};
const name = 'Expense Report';
const desc = `An expense report is a detailed record submitted by individuals for 
business-related spending. It includes date, expense categories, 
details, amounts, and receipts. The report aids reimbursement, policy 
compliance, and financial transparency.`;
