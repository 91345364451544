import { mls } from '../../multilanguagesupport/analytics';
import React, { useMemo } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import WordCloud from './WordCloud';

const WordCloudType = ({
  data: wordCloudData,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  chartHeight,
  desc,
}) => {
  const data = useMemo(() => {
    const convertedData = [];
    const { text, value } = wordCloudData;
    if (!value || !text) return convertedData;
    for (let i = 0; i < text.length; i++) {
      convertedData.push({ text: text[i], value: value[i] });
    }
    return convertedData;
  }, [wordCloudData]);
  return (
    <>
      {Array.isArray(data) && data.length > 0 ? (
        <div style={{ height: chartHeight }}>
          <ParentSize>
            {({ width, height }) => <WordCloud data={data} width={width} height={height} />}
          </ParentSize>
        </div>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

export default WordCloudType;
