import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import { Group } from '@visx/group';
import { Arc, Line } from '@visx/shape';
import { defaultToolTipStyling } from '../../constants/constants.js';
import { Text } from '@visx/text';
import { GlyphTriangle } from '@visx/glyph';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { compareBrightness, createColorArray, getStyling } from '../../helpers/colorHelper';
import { useThrottle } from 'lib/visx-lib/helpers/commenHelperFunction';
import { mls } from 'lib/multilanguagesupport';
import { numberTickFormat } from 'lib/visx-lib/helpers/tickFormatHelpers';
import { defaultChartWidthTypesObj } from 'lib/reusable-components/reusableData/defaultChartSchema';
import { KpiMeterToolTip } from '../ChartToolTip/ChartToolTip';
import { numberFormatter } from 'lib/reusable-components/reusableFunction/formatter';
import './partyPopper.scss';
const timeFrameKeys = {
  THISWEEK: 'THISWEEK',
  THISMONTH: 'THISMONTH',
  THISQUARTER: 'THISQUARTER',
  THISYEAR: 'THISYEAR',
};

const timeFrameCheckLimit = {
  [timeFrameKeys.THISWEEK]: 2,
  [timeFrameKeys.THISMONTH]: 3,
  [timeFrameKeys.THISQUARTER]: 10,
  [timeFrameKeys.THISYEAR]: 15,
};
const defaultData = {
  label: 'default',
  targetPoint: 1000,
  opacity: 100,
  color: '#d1d5db',
};
const darker = '#333';
const ligher = '#ddd';
function targetColor(
  targetPoint: {
    goal: number;
    isTargetPositive: boolean;
    color: {
      safe: string;
      danger: string;
    };
  },
  value: number
) {
  return targetPoint.goal <= value
    ? targetPoint.isTargetPositive
      ? targetPoint.color.safe
      : targetPoint.color.danger
    : targetPoint.isTargetPositive
    ? targetPoint.color.danger
    : targetPoint.color.safe;
}

interface KPIMeterType {
  chartKey: any;
  chartData: any;
  dataToPlot?: any;
  cardWidth: any;
  useChartSettingsButton: any;
  isProd: any;
  updateFilter: any;
  handleChartUpdates: any;
  isUpdateLoading: any;
  cardRef: any;
  filterSchema: any;
  chartHeight: any;
  desc: any;
}
interface divDimensions {
  width: any;
}
const getStartPoint = (startPoint: number, targets: any[], percentage: number) => {
  if (!targets[1]) {
    return startPoint + (percentage / 100) * startPoint;
  }
  return startPoint;
};
const KPIMeterType = ({
  chartKey,
  chartData,
  dataToPlot: plotData,
  cardWidth,
  useChartSettingsButton,
  isProd,
  updateFilter,
  handleChartUpdates,
  isUpdateLoading,
  cardRef,
  filterSchema,
  chartHeight,
  desc,
}: KPIMeterType) => {
  const percent = chartData?.targetPoints?.maxGoalAchievePercentage;
  const targets = chartData?.targetPoints?.targets;
  const startPoint = getStartPoint(Number(chartData.targetPoints.startPoint), targets, 9);
  const isOnFourth = cardWidth === defaultChartWidthTypesObj.ONEFOURTH.key ? true : false;

  const title = chartData?.targetPoints?.title;
  const targetFontStyle = chartData?.targetPoints?.targetFontStyle;
  const kpiFormatter = chartData?.targetPoints?.formatter;

  const divDimensions = useDivDimensions(cardRef);
  const width: number = isOnFourth
    ? (divDimensions.width || 0) <= 250
      ? divDimensions.width || 0
      : 250
    : (divDimensions.width || 0) <= 700
    ? divDimensions.width || 0
    : 700;

  const dataToPlot: any[] = useMemo(() => plotData?.plotData || [], [plotData]);
  const meter = targets[targets.length - 1] ?? {};

  const newGoal = meter?.targetPoint;

  const valueData: any = Object.values(dataToPlot?.[0]?.data[0].y[0] || {})[0];
  const value = valueData?.data;

  const maxValue = () => {
    if (meter?.targetPoint === undefined || meter?.targetPoint === null) {
      return value * (1 + percent / 100);
    }
    // return Number(meter?.targetPoint) * (1 + percent / 100);
    return (Number(meter?.targetPoint) - startPoint) * (1 + percent / 100);
  };
  const total = maxValue();
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const [showPartyPopper, setshowPartyPopper] = useState(false);

  const [toolTipTargetPoint, setToolTipTargetPoint] = useState({ label: '', targetPoint: '' });
  const toolTipTargetPointThrottle = useThrottle(setToolTipTargetPoint, 500);

  const needleAngleCheck = () => {
    const max = 175;
    if (value >= total) {
      return max;
    }
    if ((180 * (value - startPoint)) / total >= max) {
      return max;
    }
    // Math.max((180 * (targetPoint - startPoint)) / total, 0);
    return (180 * (value - startPoint)) / total;
  };
  const needleAngle = needleAngleCheck();

  const valueAngle = (180 * (Number(newGoal) - startPoint)) / total;

  const outerRadius = width / 3;
  const arcWidth = Math.max(outerRadius / 5, 20);
  const innerRadius = outerRadius - arcWidth;
  const bottomTitleWidth = isOnFourth ? width - 150 : width - 50;

  // const bottomTitleWidth = outerRadius >= 100 ? (outerRadius * 3) / 4 : (outerRadius * 5) / 4;
  const bottomTitleFontSizeMulti = bottomTitleWidth >= 124 ? 2 : 1.5;

  const handleMouseOver = useCallback(
    (event, targetPoint) => {
      // const { x, y }: any = localPoint(event);
      const x = 5;
      const y = 5;
      if (targetPoint) {
        toolTipTargetPointThrottle(targetPoint);
      }
      showTooltip({
        tooltipData: true,
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip]
  );

  // const arcs = useMemo(
  //   () =>
  //     KPIArcs({
  //       value,
  //       targetPoints,
  //       total,
  //       outerRadius,
  //       innerRadius,
  //       handleMouseOver,
  //       hideTooltip,
  //     }),
  //   [value, targetPoints, total, outerRadius, innerRadius, handleMouseOver, hideTooltip]
  // );
  const defaultColor = meter?.color ?? defaultData.color;
  const extraSemiArcColor = createColorArray(5, defaultColor)[4];
  const isDarker = compareBrightness(extraSemiArcColor);

  const getInnerCircleColor = useMemo(() => {
    let finalTarget = targets[0] ?? {};

    targets?.map((targetElem: any, index: number) => {
      if (Number(targetElem?.targetPoint) >= value) {
      } else {
        finalTarget = targets[index + 1] ?? {
          ...defaultData,
          color: extraSemiArcColor,
          opacity: meter.opacity ?? 100,
        };
      }
    });
    return finalTarget;
  }, [targets]);
  const timeframeColumn = chartData?.timeframeColumn;

  const kpiProbability = useMemo(() => {
    return kpiProbabilityFunction({
      type: chartData?.targetPoints?.kpiTimeframe,
      goal: Number(newGoal),
      currentValue: Number(value),
      timeframeColumn,
    });
  }, [chartData?.targetPoints?.kpiTimeframe, newGoal, value]);
  useEffect(() => {
    if (kpiProbability?.probability?.precentageData?.goalAchieved) {
      setshowPartyPopper(true);
    }
    const timer = setTimeout(() => {
      setshowPartyPopper(false);
    }, 4700);
    return () => clearTimeout(timer);
  }, [kpiProbability?.probability?.precentageData?.goalAchieved]);
  if (targets.length === 0) {
    return (
      <div
        className='fw-bolder my-1 fs-4 d-flex justify-content-center'
        style={{ color: '#7e8299' }}
      >
        {mls('No data to show with the current settings. Please use another settings!')}
      </div>
    );
  }

  const kpiChartheight = isOnFourth ? 40 + width / 3 : 60 + width / 3;

  //  if (cardWidth === defaultChartWidthTypesObj.ONEFOURTH.key) {
  //    return true;
  //  }
  const styless: any = {
    textAlign: 'center',
    width: width,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <svg
          ref={containerRef}
          width={width}
          height={kpiChartheight}
          transform={isOnFourth ? ' scale(1.25)' : ''}
          onMouseMove={(e) => handleMouseOver(e, { label: '', targetPoint: '' })}
          onMouseLeave={hideTooltip}
        >
          <Group
            transform={`translate(${width / 2},${
              isOnFourth ? outerRadius + 15 : outerRadius + 30
            })`}
          >
            {/* ------------- ------------- Sub Arcs ------------- ------------- */}
            {targets?.map((targetElem: any, index: number) => {
              const targetPoint = Number(targetElem.targetPoint);
              // const goalAngle = (180 * targetPoint) / total;
              const goalAngle = Math.max((180 * (targetPoint - startPoint)) / total, 0);
              const startingAngle = () => {
                if (startPoint >= targetPoint) {
                  return { startAngle: -90 * (Math.PI / 180), previousGoalAngle: 0 };
                }
                if (index === 0) {
                  return { startAngle: -90 * (Math.PI / 180), previousGoalAngle: 0 };
                }

                const previousGoal = targets[index - 1];
                if (previousGoal?.targetPoint === undefined || previousGoal?.targetPoint === null) {
                  return { startAngle: -90 * (Math.PI / 180), previousGoalAngle: 0 };
                }
                if (startPoint >= Number(previousGoal.targetPoint)) {
                  return { startAngle: -90 * (Math.PI / 180), previousGoalAngle: 0 };
                }
                const previousGoalAngle =
                  (180 * (Number(previousGoal.targetPoint) - startPoint)) / total;

                const startAngle = -90 * (Math.PI / 180) + previousGoalAngle * (Math.PI / 180);
                return { startAngle, previousGoalAngle: previousGoalAngle };
              };

              const previousAngle = startingAngle();
              const finalStartingAngle = previousAngle.startAngle;
              const previousGoalAngle = previousAngle.previousGoalAngle;
              const isDarker = compareBrightness(targetElem.color);
              const endAngleFunction = () => {
                if (startPoint >= targetPoint) {
                  return -90 * (Math.PI / 180) + 0 * (Math.PI / 180);
                }
                return -90 * (Math.PI / 180) + goalAngle * (Math.PI / 180);
              };
              const endAngle = endAngleFunction();

              return (
                <React.Fragment key={index}>
                  <Arc
                    startAngle={finalStartingAngle}
                    endAngle={endAngle}
                    // style={{ zIndex: `${5 -index}` }}
                    outerRadius={outerRadius}
                    innerRadius={innerRadius}
                    cornerRadius={0}
                    opacity={targetElem.opacity / 100}
                    fill={targetElem.color}
                    // onMouseMove={(e) => handleMouseOver(e, targetElem)}
                    // onMouseLeave={hideTooltip}
                    strokeWidth={2}
                    stroke={'#d1d5db'}
                  />

                  {[...Array(30)].map((x, i) => {
                    const angle = i * 6;
                    const lineLength = 4;

                    if (previousGoalAngle >= angle) {
                      return <></>;
                    }
                    if (goalAngle <= angle) {
                      return <></>;
                    }

                    return (
                      <>
                        <Line
                          from={{
                            x:
                              -Math.cos(angle * (Math.PI / 180)) *
                              (outerRadius - 5 - (outerRadius / 100) * lineLength + 4),
                            y:
                              -Math.sin(angle * (Math.PI / 180)) *
                              (outerRadius - 5 - (outerRadius / 100) * lineLength + 4),
                          }}
                          to={{
                            x:
                              -Math.cos(angle * (Math.PI / 180)) *
                              (outerRadius - (outerRadius / 100) * 0),
                            y:
                              -Math.sin(angle * (Math.PI / 180)) *
                              (outerRadius - (outerRadius / 100) * 0),
                          }}
                          // stroke={defaultData.color}
                          // stroke={targetElem.color}
                          stroke={isDarker ? darker : ligher}
                          opacity={0.5}
                          strokeWidth={2}
                          pointerEvents='none'
                          // strokeDasharray='5,2'
                        />
                      </>
                    );
                  })}
                  <Text
                    x={
                      -Math.cos(goalAngle * (Math.PI / 180)) *
                      (outerRadius - (outerRadius / 100) * -3)
                    }
                    y={
                      -Math.sin(goalAngle * (Math.PI / 180)) *
                      (outerRadius - (outerRadius / 100) * -3)
                    }
                    textAnchor={goalAngle >= 90 ? 'start' : 'end'}
                    fill='#9ca3af'
                    // fontSize={true ? `${60}rem` : `${(1 * targetElem.fontHeight) / 10}rem`}
                    // fontSize={true ? `${60}rem` : `${(1 * targetElem.fontHeight) / 10}rem`}
                    style={{
                      ...getStyling({
                        ...targetFontStyle,
                      }),
                      fontSize: isOnFourth ? `${0.7}rem` : ``,
                    }}
                    verticalAnchor='end'
                  >
                    {targetElem.label}
                  </Text>
                </React.Fragment>
              );
            })}

            <Arc
              endAngle={90 * (Math.PI / 180)}
              startAngle={-90 * (Math.PI / 180) + valueAngle * (Math.PI / 180)}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              cornerRadius={0}
              fill={extraSemiArcColor}
              opacity={(meter?.opacity ?? 100) / 100}
              // onMouseMove={(e) => handleMouseOver(e, { label: '', targetPoint: '' })}
              // onMouseLeave={hideTooltip}
              strokeWidth={2}
              stroke={'#d1d5db'}
            />
            <Line
              from={{
                x:
                  -Math.cos(valueAngle * (Math.PI / 180)) *
                  (outerRadius - arcWidth - outerRadius / 100),
                y:
                  -Math.sin(valueAngle * (Math.PI / 180)) *
                  (outerRadius - arcWidth - outerRadius / 100),
              }}
              to={{
                x:
                  -Math.cos(valueAngle * (Math.PI / 180)) * (outerRadius - (outerRadius / 100) * 0),
                y:
                  -Math.sin(valueAngle * (Math.PI / 180)) * (outerRadius - (outerRadius / 100) * 0),
              }}
              stroke={'#000000'}
              // stroke={'#000000'}
              strokeWidth={3}
              pointerEvents='none'
              // strokeDasharray='5,2'
            />
            {[...Array(30)].map((x, i) => {
              const angle = i * 6;
              const lineLength = 4;
              if (valueAngle >= angle) {
                return <></>;
              }
              if (180 <= angle) {
                return <></>;
              }

              return (
                <>
                  <Line
                    from={{
                      x:
                        -Math.cos(angle * (Math.PI / 180)) *
                        (outerRadius - 5 - (outerRadius / 100) * lineLength + 4),
                      y:
                        -Math.sin(angle * (Math.PI / 180)) *
                        (outerRadius - 5 - (outerRadius / 100) * lineLength + 4),
                    }}
                    to={{
                      x:
                        -Math.cos(angle * (Math.PI / 180)) *
                        (outerRadius - (outerRadius / 100) * 0),
                      y:
                        -Math.sin(angle * (Math.PI / 180)) *
                        (outerRadius - (outerRadius / 100) * 0),
                    }}
                    stroke={isDarker ? darker : ligher}
                    opacity={0.5}
                    strokeWidth={2}
                    pointerEvents='none'
                    // strokeDasharray='5,2'
                  />
                </>
              );
            })}

            {/* ------------- ------------- Needle ------------- ------------- */}

            <GlyphTriangle
              // style={{ transform: 'scale(5)' }}
              left={
                -Math.cos(needleAngle * (Math.PI / 180)) *
                (outerRadius - 5 - (outerRadius / 100) * 70)
              }
              top={
                -Math.sin(needleAngle * (Math.PI / 180)) *
                (outerRadius - 5 - (outerRadius / 100) * 70)
              }
              stroke={'#000000'}
              fill={'#000000'}
              transform={`rotate(${needleAngle - 90}) scale(${outerRadius / 150}, ${
                outerRadius / 12
              })`}
            />

            {/* ------------- ------------- Center Circle ------------- ------------- */}

            <Arc
              // startAngle={-90 * (Math.PI / 180)}
              startAngle={0}
              // endAngle={90 * (Math.PI / 180)}
              endAngle={360}
              style={{ transform: 'translate(0, 0)' }}
              outerRadius={arcWidth / 2.5}
              innerRadius={0}
              cornerRadius={0}
              fill={'#000000'}
            />

            <Arc
              startAngle={0}
              endAngle={360}
              style={{ transform: 'translate(0, 0)' }}
              outerRadius={arcWidth / 3}
              innerRadius={0}
              cornerRadius={0}
              fill={'#ffffff'}
            />
            <Arc
              startAngle={0}
              endAngle={360}
              style={{ transform: 'translate(0, 0)' }}
              outerRadius={arcWidth / 3}
              innerRadius={0}
              cornerRadius={0}
              fill={getInnerCircleColor.color}
              opacity={getInnerCircleColor.opacity / 100}
            />
            {startPoint >= 0 ? (
              <Text
                x={outerRadius >= 115 ? -outerRadius + 20 : -outerRadius + 10}
                textAnchor='middle'
                y={10 * bottomTitleFontSizeMulti - 6}
                fill='#9ca3af'
                // fontSize={10 * bottomTitleFontSizeMulti}
                style={{
                  ...getStyling(targetFontStyle),
                  fontSize: isOnFourth ? `${0.7}rem` : ``,
                  color: '#9ca3af',
                }}
              >
                {startPoint}
              </Text>
            ) : null}
          </Group>

          {/* {tooltipOpen && tooltipData && (
          <KpiMeterToolTip
            defaultToolTipStyling={defaultToolTipStyling}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            TooltipInPortal={TooltipInPortal}
            value={value}
            toolTipTargetPoint={toolTipTargetPoint}
            meter={meter}
            total={total}
            backgroundColor={defaultToolTipStyling.backgroundColor}
          />
        )} */}
        </svg>
        {tooltipOpen && tooltipData && timeframeColumn && isOnFourth && (
          <KpiMeterToolTip
            defaultToolTipStyling={defaultToolTipStyling}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            TooltipInPortal={TooltipInPortal}
            tooltipData={tooltipData}
            kpiProbability={kpiProbability}
            backgroundColor={defaultToolTipStyling.backgroundColor}
          />
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            ...getStyling({
              ...title,
              fontHeight: Number(title.fontHeight) * bottomTitleFontSizeMulti,
            }),
            ...styless,
          }}
        >
          {/* <b>Target Achieved</b> */}
          {`${title.valuePrefix} ${numberFormatter({ type: kpiFormatter, value: value })} ${
            title.valuePostfix
          } `}
        </div>
      </div>
      {timeframeColumn && !isOnFourth ? (
        <>
          {kpiProbability?.daysLeft ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ textAlign: 'center', width: width }}>
                <b>
                  {kpiProbability?.daysLeft} {mls('Days')}
                </b>{' '}
                {mls('to go')}
              </div>
            </div>
          ) : null}
          {kpiProbability?.probability?.enoughDays ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {kpiProbability?.probability?.precentageData?.goalAchieved ? (
                <div style={{ textAlign: 'center', width: width, color: '#05e105' }}>
                  <b>{mls('Target Achieved')}</b>
                </div>
              ) : (
                <div style={{ textAlign: 'center', width: width }}>
                  {mls('Target Achieving Probability')}{' '}
                  <b>
                    ({numberTickFormat(kpiProbability?.probability?.precentageData?.precentage)}%)
                  </b>
                </div>
              )}
            </div>
          ) : null}
        </>
      ) : null}

      <div
        className='partyPopperDiv'
        style={{ display: 'flex', justifyContent: 'space-evenly', position: 'relative' }}
      >
        <PartyPoppper showPartyPopper={showPartyPopper} />
        <PartyPoppper showPartyPopper={showPartyPopper} />
      </div>
    </>
  );
};

export default KPIMeterType;

const PartyPoppper = ({ showPartyPopper }: { showPartyPopper: boolean }) => {
  return (
    <div className={showPartyPopper ? 'PartyPopperContainer' : 'PartyPopperContainer hide'}>
      <div className='PartyPoppers'>
        {React.Children.toArray(
          [...Array(100)].map((elm) => {
            return (
              <>
                <i />
              </>
            );
          })
        )}
      </div>
    </div>
  );
};
interface kpiProbabilityFunctionProps {
  type: string;
  startValue?: number;
  goal: number;
  currentValue: number;
  timeframeColumn: string;
}
const kpiProbabilityFunction = ({
  type,
  startValue = 0,
  goal,
  currentValue,
  timeframeColumn,
}: kpiProbabilityFunctionProps) => {
  if (!timeframeColumn) {
    return;
  }
  const daysData = daysLeftInWeekMonthQuarterYear();
  const checkProbablity = ({ goal, currentValue }: calculateProbabilityAsPercentageProps) => {
    if (timeFrameCheckLimit[type] <= daysData[type].daysPassed) {
      const probability: {
        goalAchieved: boolean;
        precentage: number;
      } = calculateProbabilityAsPercentage({
        startValue,
        goal,
        currentValue,
        daysLeft: daysData[type].daysLeft,
        daysPassed: daysData[type].daysPassed,
      });
      return { enoughDays: true, precentageData: probability };
    }
    return { enoughDays: false };
  };
  const finalProbability = checkProbablity({
    goal,
    currentValue,
    daysLeft: daysData[type].daysLeft,
    daysPassed: daysData[type].daysPassed,
    startValue,
  });
  return { daysLeft: daysData[type].daysLeft, probability: finalProbability };
};

const daysLeftInWeekMonthQuarterYear = () => {
  const today = new Date();

  // add a day for test
  // today.setDate(today.getDate() + 100);

  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  // Calculate the last day of the current month
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  // Calculate the last day of the current quarter
  const currentQuarter = Math.floor(currentMonth / 3);
  const firstMonthOfQuarter = currentQuarter * 3;
  const lastMonthOfQuarter = (currentQuarter + 1) * 3 - 1;
  const lastDayOfQuarter = new Date(currentYear, lastMonthOfQuarter + 1, 0);

  // Calculate the first day of the current week (resetting to Monday)
  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)); // Reset to Monday

  // Calculate the number of days passed in the current week
  const daysPassedInWeek = today.getDay() - 1; // Days from Sunday to today

  // Calculate the number of days left in the current week
  const daysLeftInWeek = 7 - ((today.getDay() + 6) % 7); // Reset to Monday

  // Calculate the number of days passed in the current month
  const daysPassedInMonth = today.getDate() - 1; // Days from 1st to yesterday

  // Calculate the number of days left in the month
  const daysLeftInMonth = lastDayOfMonth.getDate() - today.getDate() + 1;

  // Calculate the number of days passed in the current quarter
  const daysPassedInQuarter = today.getDate() - 1 - (firstMonthOfQuarter - currentMonth) * 30; // Approximation of days in a quarter

  // Calculate the number of days left in the quarter including the current day
  const daysLeftInQuarter = Math.ceil((+lastDayOfQuarter - +today) / (1000 * 60 * 60 * 24)) + 1;

  // Calculate the days passed in the year, considering the current date
  const firstDayOfYear = new Date(currentYear, 0, 1);
  const daysPassedInYear = Math.floor((+today - +firstDayOfYear) / (1000 * 60 * 60 * 24));

  // Calculate the days left in the year, considering the current date
  const endOfYear = new Date(currentYear, 11, 31, 23, 59, 59, 999); // Set to the last millisecond of the year
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const daysLeftInYear = Math.ceil((endOfYear.getTime() - today.getTime()) / millisecondsInADay);

  return {
    [timeFrameKeys.THISWEEK]: {
      daysLeft: daysLeftInWeek,
      daysPassed: daysPassedInWeek,
    },
    [timeFrameKeys.THISMONTH]: {
      daysLeft: daysLeftInMonth,
      daysPassed: daysPassedInMonth,
    },
    [timeFrameKeys.THISQUARTER]: {
      daysLeft: daysLeftInQuarter,
      daysPassed: daysPassedInQuarter,
    },
    [timeFrameKeys.THISYEAR]: {
      daysLeft: daysLeftInYear,
      daysPassed: daysPassedInYear,
    },
  };
};
interface calculateProbabilityAsPercentageProps {
  startValue: number;
  goal: number;
  currentValue: number;
  daysLeft: number;
  daysPassed: number;
}
const calculateProbabilityAsPercentage = ({
  startValue,
  goal,
  currentValue,
  daysLeft,
  daysPassed,
}: calculateProbabilityAsPercentageProps) => {
  // Ensure that currentValue is not greater than the goal
  if (currentValue >= goal) {
    return { precentage: 100, goalAchieved: true }; // Goal already reached
  }

  // Calculate the rate of progress per day
  const progressPerDay: number = currentValue / daysPassed;

  const progressInLeftDays = daysLeft * progressPerDay + currentValue;

  const probability: number = calculatePercentage(startValue, goal, progressInLeftDays);
  return { precentage: probability, goalAchieved: false };
};

function calculatePercentage(startValue: number, maxValue: number, currentValue: number): number {
  if (currentValue <= startValue) {
    return 0; // Below or equal to the start value
  } else if (currentValue >= maxValue) {
    return 95; // Equal to or above the max value
  } else {
    const percentage: number = ((currentValue - startValue) / (maxValue - startValue)) * 95;
    return percentage;
  }
}
