import React, { useMemo, useRef } from 'react';
import useDivDimensions from '../../helpers/divDimensionHelper';
import { scaleBand, scaleLinear } from '@visx/scale';
import { getDefaultColorByIndex, getStyling, hexWithOpacity } from '../../helpers/colorHelper';
import { mls } from '../../multilanguagesupport/analytics';

import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { defaultToolTipStyling, variantColors } from '../../constants/constants.js';
import { Text } from '@visx/text';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import useOrdinalLegend from '../../helpers/hooks/userOrdinalLegend';

import { Box } from '@mui/material';
import { FunnelTypeToolTip } from '../ChartToolTip/ChartToolTip';
import { allChartTypesObj } from 'lib/reusable-components/reusableData/chartTypesSchema';

// const data = [
//   { x: 'Invitation', y: 500000 },
//   { x: 'Create account', y: 350138 },
//   { x: 'Complete profile', y: 213067 },
//   { x: 'Start trial', y: 177635 },
//   { x: 'Finish trial', y: 140071 },
//   { x: 'Subscribe', y: 93081 },
// ];

const capitalizeOnlyFirst = (s) => {
  s = s.toLowerCase();
  return s.charAt(0).toUpperCase() + s.slice(1);
};
const accessors = {
  x: (d) => d.x,
  y: (d) => d.y,
};
const margin = { top: 20, right: 20, bottom: 20, left: 20 };

const FunnelType = ({
  data: funnelData,
  xAxisLabel,
  yAxisLabel,
  type,
  yCategory,
  xCategory,
  title,
  xGrid,
  yGrid,
  // margin,
  cardRef,
  chartID,
  useChartSettingsButton,
  isProd,
  chartColor,
  cardWidth,
  colorType,
  handleClick,
  labels,
  toggle,
  handleToggle,
  isToggleClicked,
  chartHeight,
  desc,
  theme,
  chartData,
  isReport,
}) => {
  const { width } = useDivDimensions(cardRef);
  const automaticOpacity = (chartData?.tooltip?.automaticOpacity ?? 100) / 100;

  const data = useMemo(() => {
    const convertedData = [];
    const { x, y } = funnelData;

    for (let i = 0; i < x.length; i++) {
      convertedData.push({ x: x[i], y: y[i] });
    }
    return convertedData;
  }, [funnelData]);
  data.sort((a, b) => b.y - a.y);
  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, Math.max(...data.map(accessors.y))],
        range: [margin.left, width - margin.right],
      }),
    [width, data]
  );
  const yScale = useMemo(
    () =>
      scaleBand({
        domain: data.map(accessors.x),
        range: [margin.top, chartHeight - margin.bottom],
        // padding: 0.2,
      }),
    [chartHeight, data]
  );
  const valueStyle = useMemo(() => {
    const filterdValueStyle = (theme?.tooltip?.valueStyle || []).filter((styleData) => {
      return styleData.axis === allChartTypesObj?.[type]?.toolTip?.target;
    });
    return filterdValueStyle;
  }, [theme?.tooltip?.valueStyle]);
  const legendColorScale = {};
  valueStyle.forEach((vs) => {
    const color = vs?.color;
    const opacity = (vs?.opacity ?? 100) / 100;
    legendColorScale[vs.label] = hexWithOpacity(color, opacity);
  });
  // legendColorScale['Others'] = { color: '#374151', opacity: 70 };
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();
  const { colorScale, Legend } = useOrdinalLegend({
    colorScale: legendColorScale,
    isReport,
  });
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const handleMouseOver = (event, datum) => {
    const { x, y } = localPoint(event);
    const index = Math.floor((y - margin.top) / yScale.bandwidth());

    showTooltip({
      tooltipData: { ...data[index], arcFill: datum?.arcFill },
      tooltipLeft: x,
      tooltipTop: y,
    });
  };

  const tooltipColorsDict = useMemo(() => {
    const tooltipColors = {};
    theme.tooltip.style.forEach((t) => {
      const operations = capitalizeOnlyFirst(t.operations?.type || '');
      const label = operations === '' ? t.label : `${operations}[ ${t.label} ]`;
      tooltipColors[label] = {
        color: t.color,
        show: t.show,
        opacity: t.opacity,
        textStyle: theme.tooltip.textStyle,
        font: theme.tooltip.font,
        fontHeight: theme.tooltip.fontHeight,
      };
    });
    return tooltipColors;
  }, [theme.tooltip]);
  const tooltipTimer = useRef(null);

  const SimpleTooltip = (
    <>
      {/* <div
        style={{
          ...getStyling(tooltipColorsDict[xAxisLabel]),
        }}
      >
        {mls(xAxisLabel)}:{'\t'}
        <strong>{tooltipData?.x === '' ? 'NULL' : tooltipData?.x}</strong>
      </div>
      <div
        style={{
          ...getStyling(tooltipColorsDict[yAxisLabel]),
        }}
      >
        {mls(yAxisLabel)}:{'\t'}
        <strong>{tooltipData?.y}</strong>
      </div> */}
    </>
  );
  // Other ToolTip component
  const OthersToolTip = (
    <>
      <div style={{ textAlign: 'center' }}>
        <strong>Others</strong>:{'\t'}
        {/* <strong>{otherSum}</strong> */}
      </div>
      {/* <br /> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '15px 0',
        }}
      >
        {/* {others.map((e) => (
          <div
            key={`tooltip-${e.x}-${e.y}`}
            style={{
              paddingTop: '5px',
            }}
          >
            {e.x}:{'\t'}
            <strong>{e.y}</strong>
          </div>
        ))} */}
      </div>
    </>
  );
  return (
    <>
      {data.length !== 0 ? (
        <>
          <svg ref={containerRef} width={width} height={chartHeight}>
            <Group>
              {data.map((d, i) => {
                return (
                  <FunnelChart
                    d={d}
                    i={i}
                    colorScale={colorScale}
                    xScale={xScale}
                    yScale={yScale}
                    handleMouseOver={handleMouseOver}
                    hideTooltip={hideTooltip}
                    width={width}
                    handleClick={handleClick}
                    automaticOpacity={automaticOpacity}
                  />
                );
              })}
              {tooltipData?.x === 'Others' ? OthersToolTip : SimpleTooltip}
            </Group>
            {tooltipOpen && tooltipData && (
              <FunnelTypeToolTip
                defaultToolTipStyling={defaultToolTipStyling}
                tooltipTop={tooltipTop}
                tooltipLeft={tooltipLeft}
                TooltipInPortal={TooltipInPortal}
                tooltipData={tooltipData}
                colorScale={colorScale}
                theme={theme}
                backgroundColor={defaultToolTipStyling.backgroundColor}
              />
            )}
          </svg>
          <Legend />
        </>
      ) : (
        <div
          className='fw-bolder my-1 fs-4 d-flex justify-content-center'
          style={{ color: '#7e8299' }}
        >
          {mls('No data to show with the current settings. Please use another settings!')}
        </div>
      )}
    </>
  );
};

export default FunnelType;

const FunnelChart = ({
  d,
  i,
  colorScale,
  xScale,
  yScale,
  handleMouseOver,
  hideTooltip,
  width,
  handleClick,
  automaticOpacity,
}) => {
  let arcFill;
  if (colorScale.domain().includes(d?.x)) arcFill = colorScale(d?.x);
  else arcFill = hexWithOpacity(getDefaultColorByIndex(i), automaticOpacity);

  const checkFontForOverFlow = ({ mainContainerWidth, fontSize, text }) => {
    const textLength = text.length;
    const textWidth = (textLength * fontSize) / 1.5;
    if (textWidth >= mainContainerWidth) {
      return 0;
    }
    return fontSize;
  };
  const fontSize = checkFontForOverFlow({
    mainContainerWidth: xScale(accessors.y(d)),
    fontSize: yScale.bandwidth() / 3,
    text: d.x,
  });
  return (
    <Group
      key={i}
      onMouseMove={(e) => handleMouseOver(e, { arcFill })}
      onMouseLeave={() => {
        hideTooltip();
      }}
    >
      <Bar
        x={(width - xScale(d.y)) / 2}
        y={yScale(d.x)}
        width={xScale(accessors.y(d))}
        height={yScale.bandwidth()}
        fill={arcFill}
        opacity={1}
        style={{
          // ...getStyling(tooltipColorsDict[yAxisLabel]),
          cursor: 'pointer',
        }}
        onClick={() => handleClick(d.x)}
      />
      <Text
        x={width / 2}
        y={yScale(d.x) + yScale.bandwidth() / 1.75}
        fontSize={fontSize}
        textAnchor='middle'
        fill='white'
        scaleToFit={true}
        // fontSize={Math.min(
        //   16,
        //   (Math.max(nodeWidth) / (d.x.length / 1.25) < 5
        //     ? 0
        //     : Math.max(nodeWidth) / (d.x.length / 1.25)) || 0
        // )}
      >
        {d.x}
      </Text>
    </Group>
  );
};
