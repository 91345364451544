import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import SingleAxisCharts from './SingleAxisCharts';
import useWindowDimensions from '../helpers/windowDimensionHelper';
import CardChart from './CardType/CardChart';
import VisxChart from './VisxChart';
import XYMultiAxisType from './XYMultiAxisType/XYMultiAxisType';
import KPIMeterType from './KPIMeterType/KPIMeterType';
import { variantColors } from '../constants/constants';
import TableChart from './TableChart/TableChart';
import Layout from '../helpers/components/Layout';
import { ToolTipInitiator } from '../helpers/components/ToolTip/CustomTooltip';
import { hexWithOpacity } from '../helpers/colorHelper';
import { mls } from 'lib/multilanguagesupport';

const chartComponentMap = {
  PIE: SingleAxisCharts,
  DONUT: SingleAxisCharts,
  MULTIAXISBAR: XYMultiAxisType,
  MULTIAXISBARSTACK: XYMultiAxisType,
  MULTIAXISMULTILINE: XYMultiAxisType,
  MULTIAXISAREA: XYMultiAxisType,
  MULTIAXISAREASTACK: XYMultiAxisType,
  MULTIAXISLINE: XYMultiAxisType,
  CARD: CardChart,
  TABLE: TableChart,
  MULTILINE: VisxChart,
  BARSTACK: VisxChart,
  AREASTACK: VisxChart,
  GEOINDIA: VisxChart,
  GEOUSA: VisxChart,
  GEOWORLD: VisxChart,
  WORDCLOUD: VisxChart,
  SCATTERPLOT: VisxChart,
  BUBBLEPLOT: VisxChart,
  GROUPBUBBLEPLOT: VisxChart,
  GROUPSCATTERPLOT: VisxChart,
  TREEMAP: VisxChart,
  BARSTACKHORIZONTAL: VisxChart,
  BAR: VisxChart,
  RADAR: VisxChart,
  RADIALLINE: VisxChart,
  BARGROUP: VisxChart,
  LINE: VisxChart,
  AREA: VisxChart,
  LINEHORIZONTAL: VisxChart,
  AREAHORIZONTAL: VisxChart,
  MULTILINEHORIZONTAL: VisxChart,
  AREASTACKHORIZONTAL: VisxChart,
  BARHORIZONTAL: VisxChart,
  BARGROUPHORIZONTAL: VisxChart,
  FUNNEL: VisxChart,
  BARSTACKLINE: VisxChart,
  GEOCANADA: VisxChart,
  KPIMETER: KPIMeterType,
  PIVOTTABLE: VisxChart,
  HEATMAP: VisxChart,
  BARCOLOR: VisxChart,
  LINECOLOR: VisxChart,
  AREACOLOR: VisxChart,
  BARHORIZONTALCOLOR: VisxChart,
};

// const noDataDisplay = (colClass, chartID, cardRef) => {
//   return (
//     <div className={`${colClass()} mb-4`} key={chartID}>
//       <div
//         className='card p-9 pt-5 d-flex justify-content-center'
//         ref={cardRef}
//         style={{ height: '100%', backgroundColor: '#dcdcdc' }}
//       >
//         <div className='text-dark fw-bolder my-1 fs-3 d-flex justify-content-center'>
//           No data to display!
//         </div>
//       </div>
//     </div>
//   );
// };
export const defaultTextColor = '#181823';

const defaultStyling = {
  xAxisStyle: {
    label: 'X-Axis',
    isLabelEdited: false,
    show: true,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: 'Poppins',
      fontHeight: '12',
      opacity: 100,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: 'Poppins',
      fontHeight: '12',
      opacity: 100,
    },
  },
  yAxisStyle: {
    label: 'Y-Axis',
    isLabelEdited: false,
    show: true,
    style: {
      color: defaultTextColor,
      textStyle: [],
      font: 'Poppins',
      fontHeight: '12',
      opacity: 100,
    },
    valueStyle: {
      color: defaultTextColor,
      textStyle: [],
      font: 'Poppins',
      fontHeight: '12',
      opacity: 100,
    },
  },
};

const colClass = (cardWidth) => {
  switch (cardWidth) {
    case 'ONETHIRD':
      return 'col-12 col-md-6 col-xxl-4';

    case 'HALF':
      return 'col-12 col-lg-6 col-xxl-6';

    case 'TWOTHIRD':
      return 'col-12 col-xxl-8';

    case 'ONEFOURTH':
      return 'col-12 col-md-6 col-xxl-3';
    default:
      return 'col-12';
  }
};
const isMultipleValuesCharts = ['PIE', 'DONUT', 'RADAR'];
const Chart = ({
  chartKey,
  chartData,
  dataToPlot,
  cardWidth,
  useChartSettingsButton = () => {
    return () => {};
  },
  isProd = false,
  updateFilter = () => {},
  handleChartUpdates = (updatedValue) => {},
  isUpdateLoading = false,
  filterSchema = [],
  chartHeight = 300,
  isPin = false,
  handlePin = () => {},
  isTopFilter = false,
  handleTopFilter = () => {},
  filterProps = {},
  isInsightChartSchema = false,
  insightRef = () => {},
  isInsightShow = true,
  isReport = false,
  tableID = undefined,
}) => {
  // Type of Chart
  const type = chartData.chartType;
  const isMulti = type === 'MULTIBAR';

  // Determine height of the card
  const { height: windowHeight } = useWindowDimensions();
  let height = '100%';
  if (isMulti) {
    height = windowHeight;
  }
  const chartSettingsButton = useChartSettingsButton();
  const isInsight = chartData?.isInsight || false;
  const sheetID = chartData.sheetID;
  const appID = chartData.appID;
  const chartID = chartData.chartID;

  // Ref to the parent card div
  const cardRef = useRef(null);

  // state to make sure that chart is rendered only after ref is not null
  const [chartReady, setChartReady] = useState(false);

  // This is to make sure that ref is not null
  useEffect(() => {
    setChartReady(true);
  }, []);

  // Determine the class of container based on the chartWidth

  // if (dataToPlot.length === 0) {
  //   return noDataDisplay(colClass, chartID, cardRef);
  // }
  const xAxisLabel = useMemo(() => {
    return chartData.xAxis.map((elem) => {
      return elem.label;
    });
  }, [chartData]);
  const yAxisLabel = useMemo(() => {
    return chartData.yAxis.map((elem) => {
      return elem.label;
    });
  }, [chartData]);
  // defaultStyling.xAxisStyle.label = xAxisLabel.join(',');
  // defaultStyling.yAxisStyle.label = yAxisLabel.join(',');
  const defaultXAxisStyle = {
    ...defaultStyling.xAxisStyle,
    label: xAxisLabel.join(','),
  };
  const defaultYAxisStyle = {
    ...defaultStyling.yAxisStyle,
    label: yAxisLabel.join(','),
  };
  const isHorizontal = type?.includes('HORIZONTAL');
  const defaultTooltip = useMemo(
    () => ({
      textStyle: [],
      font: 'Poppins',
      fontHeight: '12',
      style: [
        ...chartData.xAxis.map((xa, i) => ({
          column: xa.column,
          uniqueColumnName: xa.uniqueColumnName,
          color: isHorizontal ? variantColors[i] : '#000',
          opacity: 100,
          label: xa.label,
          editedLabel: xa.label,
          show: true,
        })),
        ...chartData.yAxis.map((ya, i) => ({
          column: ya.column,
          uniqueColumnName: ya.uniqueColumnName,
          color: isHorizontal ? '#000' : variantColors[i],
          opacity: 100,
          label: ya.label,
          editedLabel: ya.label,
          show: true,
        })),
      ],
      valueStyle: isMultipleValuesCharts?.includes(type)
        ? dataToPlot?.plotData?.[0]?.data[0].x.map((label, i) => ({
            column: label,
            label,
            show: true,
            color: variantColors[i],
            opacity: 100,
          }))
        : [],
    }),
    [chartData, isHorizontal, dataToPlot.plotData, type]
  );
  const labelColors = useMemo(() => {
    const colors = {};
    if (!isMultipleValuesCharts.includes(type)) {
      (type.includes('HORIZONTAL') ? chartData.yAxis : chartData.xAxis)?.forEach((axisElem) => {
        if (axisElem) {
        }

        // if (xa.uniqueColumnName && d.uniqueColumnName) {
        //   return xa.uniqueColumnName === d.uniqueColumnName;
        // }
        // return xa.column === d.column;
      });
    }
    [...(chartData.tooltip || defaultTooltip).style]?.forEach((d) => {
      if (
        !isMultipleValuesCharts.includes(type) &&
        (type.includes('HORIZONTAL') ? chartData.yAxis : chartData.xAxis)?.find((xa) => {
          if (xa.uniqueColumnName && d.uniqueColumnName) {
            return xa.uniqueColumnName === d.uniqueColumnName;
          }
          return xa.column === d.column;
        })
      )
        return;
      // colors[d.uniqueColumnName || d.column] = {
      //   color: d?.color,
      //   opacity: (d?.opacity ?? 100) / 100,
      // };

      const color = d?.color;
      const opacity = (d?.opacity ?? 100) / 100;
      const checkForAutomaticOpacity =
        chartData?.tooltip?.type === 'automatic'
          ? (chartData?.tooltip?.automaticOpacity ?? 100) / 100
          : opacity;
      colors[d.uniqueColumnName || d.column] = hexWithOpacity(color, checkForAutomaticOpacity);
    });

    [...((chartData.tooltip || defaultTooltip).valueStyle || [])].forEach((d) => {
      if (
        !isMultipleValuesCharts.includes(type) &&
        (type.includes('HORIZONTAL') ? chartData.yAxis : chartData.xAxis)?.find((xa) => {
          if (xa.uniqueColumnName && d.uniqueColumnName) {
            return xa.uniqueColumnName === d.uniqueColumnName;
          }
          return xa.column === d.column;
        })
      )
        return;
      const color = d?.color;
      const opacity = (d?.opacity ?? 100) / 100;
      // colors[d.label] = d.color;
      colors[d.label] = hexWithOpacity(color, opacity);
    });
    return colors;
  }, [chartData, type, defaultTooltip]);
  // const labelColors = useMemo(() => {
  //   const colors = {};
  //   [...(chartData.tooltip || defaultTooltip).style]?.forEach((d) => {
  //     if (
  //       !isMultipleValuesCharts.includes(type) &&
  //       (type.includes('HORIZONTAL') ? chartData.yAxis : chartData.xAxis)?.find((xa) => {
  //         if (xa.uniqueColumnName && d.uniqueColumnName) {
  //           return xa.uniqueColumnName === d.uniqueColumnName;
  //         }
  //         return xa.column === d.column;
  //       })
  //     )
  //       return;
  //     // colors[d.uniqueColumnName || d.column] = {
  //     //   color: d?.color,
  //     //   opacity: (d?.opacity ?? 100) / 100,
  //     // };

  //     const color = d?.color;
  //     const opacity = (d?.opacity ?? 100) / 100;
  //     const checkForAutomaticOpacity =
  //       chartData?.tooltip?.type === 'automatic'
  //         ? (chartData?.tooltip?.automaticOpacity ?? 100) / 100
  //         : opacity;
  //     colors[d.uniqueColumnName || d.column] = hexWithOpacity(color, checkForAutomaticOpacity);
  //   });

  //   [...((chartData.tooltip || defaultTooltip).valueStyle || [])].forEach((d) => {
  //     if (
  //       !isMultipleValuesCharts.includes(type) &&
  //       (type.includes('HORIZONTAL') ? chartData.yAxis : chartData.xAxis)?.find((xa) => {
  //         if (xa.uniqueColumnName && d.uniqueColumnName) {
  //           return xa.uniqueColumnName === d.uniqueColumnName;
  //         }
  //         return xa.column === d.column;
  //       })
  //     )
  //       return;
  //     const color = d?.color;
  //     const opacity = (d?.opacity ?? 100) / 100;
  //     // colors[d.label] = d.color;
  //     colors[d.label] = hexWithOpacity(color, opacity);
  //   });
  //   return colors;
  // }, [chartData, type, defaultTooltip]);

  const theme = useMemo(
    () => ({
      xAxisStyle: chartData?.xAxisStyle ?? defaultXAxisStyle,
      yAxisStyle: chartData?.yAxisStyle ?? defaultYAxisStyle,
      tooltip: chartData?.tooltip ?? defaultTooltip,
      colors: labelColors,
    }),
    [chartData, labelColors, defaultTooltip]
  );
  const columnLabelMap = useMemo(() => {
    const columnLabel = {};
    chartData.yAxis.forEach((data) => {
      if (data.uniqueColumnName) {
        columnLabel[data.uniqueColumnName] = data.label;
      } else {
        columnLabel[data.column] = data.label;
      }
    });
    return columnLabel;
  }, [chartData.yAxis]);

  const ChartComponent = chartComponentMap[type];
  if (!ChartComponent) {
    console.log(type);
    return <></>;
  }
  return (
    <div className={`${colClass(cardWidth)}  mb-6`} key={chartKey}>
      <ToolTipInitiator />
      <div
        className='card p-3 pt-5 pb-5  p-md-9 pt-md-5'
        ref={cardRef}
        style={{
          // height: height,
          // maxHeight: `${type === 'CARD' ? '220px' : '100%'}`,
          // height: height,
          height: `${type === 'CARD' ? 'auto' : height}`,
          minHeight: `${type === 'CARD' ? (isReport ? '100%' : '210px') : '100%'}`,
          // maxHeight: '100%',
          backgroundColor: chartData.plotAreaBackground?.color || '#fff',
        }}
      >
        <Layout
          title={chartData.name}
          chartData={chartData}
          tableID={tableID}
          cardWidth={cardWidth}
          isInsightChartSchema={isInsightChartSchema}
          isProd={isProd}
          chartSettingHandler={() => chartSettingsButton(chartID)}
          desc={chartData.desc}
          isInsight={isInsight}
          chartID={chartID}
          sheetID={sheetID}
          appID={appID}
          isPin={isPin}
          type={type}
          handlePin={handlePin}
          isTopFilter={isTopFilter}
          handleTopFilter={handleTopFilter}
          filterProps={filterProps}
          insightRef={insightRef}
          isInsightShow={isInsightShow}
          isReport={isReport}
        >
          {chartReady ? (
            <ChartComponent
              chartKey={chartKey}
              chartData={chartData}
              dataToPlot={dataToPlot}
              targetData={dataToPlot?.targetData || []}
              predictionData={chartData?.isForecast ? dataToPlot?.forecastData : []}
              cardWidth={cardWidth}
              useChartSettingsButton={useChartSettingsButton}
              isProd={isProd}
              updateFilter={updateFilter}
              handleChartUpdates={handleChartUpdates}
              isUpdateLoading={isUpdateLoading}
              cardRef={cardRef}
              filterSchema={filterSchema}
              chartHeight={chartHeight || 400}
              isForecast={chartData?.isForecast ? true : false}
              theme={theme}
              group={dataToPlot?.plotData?.[0]?.group || []}
              isHorizontal={isHorizontal}
              optionData={dataToPlot?.options || {}}
              columnLabelMap={columnLabelMap}
              isReport={isReport}
            />
          ) : null}
        </Layout>
      </div>
    </div>
  );

  // return <>{chartData}</>;
};

Chart.propTypes = {
  dataToPlot: PropTypes.object,
  halfWidth: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  chartData: PropTypes.object.isRequired,
  useChartSettingsButton: PropTypes.func.isRequired,
  isProd: PropTypes.string.isRequired,
  tableID: PropTypes.string,
};

Chart.defaultProps = {
  halfWidth: false,
};

export default Chart;
